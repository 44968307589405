import React, { ReactElement, useState } from 'react';
import TabTitle from './TabTitle';

export type TabsProps = {
  variant: 'link' | 'button';
  children: ReactElement[];
  selectedTabIndex?: number;
};

const Tabs: React.FC<TabsProps> = ({ variant, children, selectedTabIndex = 0 }) => {
  const [selectedTab, setSelectedTab] = useState(selectedTabIndex);

  return (
    <div>
      <ul className="flex w-full pb-6 space-x-4 pl-7  scrollbar-hidden tabs">
        {children.map((item, index) => (
          <TabTitle
            key={index}
            variant={variant}
            title={item.props.title}
            index={index}
            link={item.props.link}
            isActive={selectedTab === index}
            setSelectedTab={setSelectedTab}
          />
        ))}
      </ul>
      {children[selectedTab]}
    </div>
  );
};

export default Tabs;
