import { ReactElement } from 'react';

type IProps = {
  children: ReactElement[] | ReactElement;
  divId?: string;
  otherClasses?: string;
};

const ClassicCard: React.FC<IProps> = ({ otherClasses, children, divId }) => {
  return <div id={divId ? divId : ''} className={`bg-white dark:bg-dark-200 rounded-20 ${otherClasses ? otherClasses : ''}`}>{children}</div>;
};
export default ClassicCard;
