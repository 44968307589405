/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useEffect } from 'react'

export type ToogleProps = {
  onChange: (val: boolean) => void
  value: boolean
  isDisable?: boolean
}

export const Toggle: FC<ToogleProps> = ({ onChange, value, isDisable }: ToogleProps) => {
  const [isToggle, setIsToggle] = React.useState(value)
  useEffect(() => {
    if (!isDisable) onChange(isToggle)
  }, [isToggle])
  return (
    <div className="flex items-center justify-center ">
      <input type="checkbox" name="toggle" className="hidden" />
      <button
        type="button"
        onClick={() => {
          if (!isDisable) setIsToggle(!isToggle)
        }}
        className="relative flex cursor-pointer select-none w-14 h-7"
      >
        <span
          className={`absolute top-0 left-0 w-full h-full ${isToggle ? 'bg-primary-500' : 'bg-gray-200 bg-opacity-90 '} rounded-full `}
        />
        <span
          className={
            isToggle
              ? 'h-7 w-7  text-primary-master border-2 absolute z-10 rounded-full bg-white   transition-transform duration-300 ease-in-out flex justify-center items-center border-gray-100 right-0'
              : 'h-7 w-7 text-tertiary-master border-2 absolute z-10 rounded-full bg-white transition-transform duration-300 ease-in-out flex justify-center items-center border-gray-100'
          }
        ></span>
      </button>
    </div>
  )
}
