import React, { useEffect } from 'react';
import { ILocationFull } from 'interface/ILocation';
import { FormattedMessage } from 'react-intl';
import ReactDOMServer from 'react-dom/server';
import { DateTime } from 'luxon';
import Campaigns from 'api/campaigns/campaign';

type IProps = {
  location: ILocationFull;
};

const endPeriod = DateTime.local().toLocaleString();
const startPeriod = DateTime.local().minus({ days: 7 }).toLocaleString();

const renderTooltip = (props: any) => {
  const formatHour = (hour: string) => {
    const hourInt = parseInt(hour, 10);
    if (hourInt === 0) {
      return '12 AM';
    }
    if (hourInt === 12) {
      return '12 PM';
    }
    if (hourInt > 12) {
      return `${hourInt - 12} PM`;
    } else {
      return `${hourInt} AM`;
    }
    return hour;
  };
  return (
    <div className="px-2 py-2 space-y-2">
      <h2 className="text-white">Average traffic</h2>
      <h4 className="flex items-center text-white justify-self-start">
        <span className="block w-4 h-4 mr-1 rounded-full bg-primary-500" /> {props[0].seriesName}:
        <strong>{props[0].value}</strong>
      </h4>
      {props[1] && (
        <h4 className="text-white">
          <span className="block w-4 h-4 mr-1 bg-white rounded-full" /> {props[1].seriesName}:
          <strong>{props[1].value}</strong>
        </h4>
      )}
      <p className="text-sm text-white">
        Based on compiled data between {startPeriod} and {endPeriod}
      </p>
    </div>
  );
};
type IToolTipAnalytics = {
  MONDAY: number[];
  TUESDAY: number[];
  WEDNESDAY: number[];
  THURSDAY: number[];
  FRIDAY: number[];
  SATURDAY: number[];
  SUNDAY: number[];
};

const MapTooltipStats: React.FC<IProps> = ({ location }: IProps) => {
  const [analytics, setAnalytcs] = React.useState<IToolTipAnalytics | null>(null);
  const getLocationStats = async () => {
    const data = await Campaigns.getLocationAnalytics(location.uuid);
    setAnalytcs(data.analytics);
  };
  useEffect(() => {
    if (!location.eId) {
      getLocationStats();
    }
  }, []);

  const getSeries = () => {
    const traffic =
      analytics && analytics.MONDAY
        ? {
            MONDAY: analytics?.MONDAY.reduce((acc, el) => acc + el, 0),
            TUESDAY: analytics?.TUESDAY.reduce((acc, el) => acc + el, 0),
            WEDNESDAY: analytics?.WEDNESDAY.reduce((acc, el) => acc + el, 0),
            THURSDAY: analytics?.THURSDAY.reduce((acc, el) => acc + el, 0),
            FRIDAY: analytics?.FRIDAY.reduce((acc, el) => acc + el, 0),
            SATURDAY: analytics?.SATURDAY.reduce((acc, el) => acc + el, 0),
            SUNDAY: analytics?.SUNDAY.reduce((acc, el) => acc + el, 0),
          }
        : {
            MONDAY: 0,
            TUESDAY: 0,
            WEDNESDAY: 0,
            THURSDAY: 0,
            FRIDAY: 0,
            SATURDAY: 0,
            SUNDAY: 0,
          };

    const series = [
      {
        name: 'Audience',
        type: 'bar',
        barGap: 0,
        data: [
          traffic?.MONDAY || 0,
          traffic?.TUESDAY || 0,
          traffic?.WEDNESDAY || 0,
          traffic?.THURSDAY || 0,
          traffic?.FRIDAY || 0,
          traffic?.SATURDAY || 0,
          traffic?.SUNDAY || 0,
        ],
        animationDelay: (idx: any) => {
          return idx * 10;
        },
      },
    ];
    return series;
  };

  const getOption = () => {
    const options = {
      tooltip: {
        trigger: 'axis',
        position: ['50%', '0%'],
        padding: 0,
        extraCssText: 'transform:translate3d(-50%, -110%, 0);',
        backgroundColor: 'rgba(44,62,80,0.8)',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params: any, ticket: any, callback: any) => {
          const tooltipString = ReactDOMServer.renderToString(renderTooltip(params));
          return tooltipString;
        },
      },
      yAxis: {
        show: false,
      },
      grid: {
        left: '0',
        right: '0',
        top: '0',
        bottom: 20,
      },
      color: ['#24C281', '#24C281'],
      xAxis: {
        type: 'category',
        axisLabel: {
          formatter: (value: any) => {
            return value[0];
          },
        },
        data: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      },
      series: getSeries(),
      animationEasing: 'elasticOut',
      animationDelayUpdate: (idx: any) => {
        return idx * 5;
      },
    };

    return options;
  };

  return (
    <>
      {location.eId ? (
        <div className="pt-5 text-xs font-medium  text-body dark:text-dark-300">
          <FormattedMessage id="Text.NoChartPopup" />
        </div>
      ) : (
        <div className="pt-5 dark:text-dark-400 ">
          <p>Popular times</p>
          {/* <div>
            {analytics && (
              <ReactEcharts
                option={getOption()}
                style={{
                  height: '60px',
                  width: '100%',
                  padding: '0 20px'
                }}
              />
            )}
          </div> */}
        </div>
      )}
    </>
  );
};
export default MapTooltipStats;
