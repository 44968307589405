import { oneLine } from 'common-tags';
import React, { ReactElement } from 'react';
import { Button } from '../atoms/index';
import { CloseSquare } from 'react-iconly';

type PopupProps = {
  title?: string;
  children: ReactElement[] | ReactElement;
  closeAction: () => void;
};

const Popup: React.FC<PopupProps> = ({ title, children, closeAction }: PopupProps) => {
  return (
    <div className={oneLine`fixed z-100 inset-0 overflow-y-auto`}>
      <div className={oneLine`flex items-center justify-center min-h-screen   bg-dark-default bg-opacity-20`}>
        <div
          className={oneLine`inline-block bg-white dark:bg-dark-200 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all p-7 `}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className={oneLine` pb-3 flex align-center justify-between`}>
            {title && (
              <h3 className={oneLine`text-lg  font-bold text-body dark:text-dark-400`} id="modal-headline">
                {title}
              </h3>
            )}
            <span
              className={oneLine`text-lg  font-bold text-body cursor-pointer ease-in-out transition duration-300 hover:rotate-180`}
              aria-hidden="true"
              onClick={closeAction}
            >
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.98959 9.01041L21.0104 21.0312M21.0104 9.01041L8.98959 21.0312"
                  stroke="#A7ACB3"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
              </svg>
            </span>
          </div>
          <div className={oneLine`h-80vh w-80vw rounded-3xl overflow-hidden`}>{children}</div>
        </div>
      </div>
    </div>
  );
};

Popup.defaultProps = {};

export default Popup;
