import React, { useEffect, useRef, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { oneLine } from 'common-tags';
import { Stack } from 'components/layout';
import { Button, Input } from '.';
import { JWT, TPSAPI } from '@api/users';
import { useApp } from 'components/app';
import { ILoginResponse } from 'interface';
import { completedSurveyForm } from '@api/gtm/gtm';

type IProps = {
  onSubmit: () => void;
};

const SurveyModal = ({onSubmit}: IProps) => {
  const [vatSwitch, setVatSwitch] = useState<number | null>();
  const [errorSelection, setErrorSelection] = useState<boolean>(false);
  const [errorOtherRequired, setErrorOtherRequired] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const { notify, setReloadJwt, reloadJwt } = useApp();
  const bottomRef = useRef<HTMLDivElement>(null);

  const [vatTypes, setVatTypes] = useState<any[]>([
    {
      id: 1,
      title: 'Instagram or Facebook',
    },
    {
      id: 2,
      title: "LinkedIn",
    },
    {
      id: 3,
      title: 'Twitter / X ',
    },
    {
      id: 4,
      title: "Search Engine (Google, Bing etc.)",
    },
    {
      id: 5,
      title: 'Saw us on a billlboard',
    },
    {
      id: 6,
      title: "Email or newsletter",
    },
    {
      id: 7,
      title: "Word of mouth",
    },
    {
      id: 8,
      title: 'YouTube',
    },
    {
      id: 9,
      title: 'Tiktok',
    },
    {
      id: 10,
      title: "Other",
    },
  ]);

  const submitSurvey = async () => {
    if(vatSwitch !== null && vatSwitch !== undefined) {
      setErrorSelection(false);
      if(vatSwitch === 10 && inputValue === "") {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        setErrorOtherRequired(true);
        return;
      }
      if(vatSwitch === 10) {
        try {
          const obj = {
            q1: inputValue
          }
          const response = await TPSAPI.addSurveyAnswer(obj);
          if(response.message === "Survey post") {
            if (response.token) {
              JWT.setJwt(response.token as unknown as ILoginResponse);
              setReloadJwt(reloadJwt + 1);
            }
            completedSurveyForm(inputValue);
            onSubmit();
          } else {
             notify('Oops, something went wrong!', 'error', 'top-right');
             bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
          }
        } catch (error) { 
           notify('Oops, something went wrong!', 'error', 'top-right');
           bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
        onSubmit();
      } else {
        try {
          const obj = {
            q1: vatTypes[vatSwitch - 1].title
          }
          const response = await TPSAPI.addSurveyAnswer(obj);
          if(response.message === "Survey post") {
            if (response.token) {
              JWT.setJwt(response.token as unknown as ILoginResponse);
              setReloadJwt(reloadJwt + 1);
            }
            completedSurveyForm(vatTypes[vatSwitch - 1].title);
            onSubmit();
          } else {
             notify('Oops, something went wrong!', 'error', 'top-right');
             bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
          }
        } catch (error) { 
           notify('Oops, something went wrong!', 'error', 'top-right');
           bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } else {
      setErrorSelection(true);
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    setErrorSelection(false);
    setErrorOtherRequired(false);
  }, [vatSwitch, inputValue]);
  
  return (
    <div
      className={oneLine`fixed flex justify-center overflow-hidden items-center z-110 inset-0 bg-gray-800 bg-opacity-70 overflow-y-auto w-full h-full light-box-container`}
    >
      <div className={oneLine`mx-4 modal-max-h flex flex-col bg-white dark:bg-dark-200 items-center rounded-2xl`}>
        <div className="h-full p-6 overflow-y-auto pb-0 w-full">
          <Stack align='center' gravity='center' spacing="xl" >
            <div className="relative z-50 w-full ">
              <div className='flex flex-col mb-4'>
                <h3 className="text-md font-bold  dark:text-white">Help Us Improve! 🌟 </h3>
                <p className="text-sm font-semibold text-dark-500 dark:text-dark-300 mt-2 text-left">Where did you hear about us? Your answer means a lot!</p>
              </div>
              <div className="grid survey-modal-grid items-start justify-start h-full w-full flex-wrap gap-6">
                {vatTypes.slice(0, 10).map((vatType: any) => {
                  return (
                    <div
                      key={vatType.id}
                      className={`flex flex-row w-full dark:bg-dark-300 dark:bg-opacity-20 bg-[#D9D9D9] dark:text-white text-dark-200 rounded-2xl whitespace-nowrap py-2 px-6  cursor-pointer
                      border
                      ${
                        vatSwitch === vatType.id ? 'border-[#EB3F3F]' : 'border-dark-300 border-opacity-20'
                      }
                      `}
                      onClick={() => {
                        setVatSwitch(vatType.id);
                      }}
                    >
                      <div
                        className={`w-5 h-5 border ${
                          vatSwitch === vatType.id ? 'border-[#EB3F3F]' : 'border-dark-300'
                        } bg-white rounded-2xl flex items-center justify-center mr-4`}
                      >
                        <div
                          className={`w-2 h-2 ${
                            vatSwitch === vatType.id ? 'bg-[#EB3F3F]' : 'bg-white'
                          } rounded-2xl`}
                        ></div>
                      </div>
                      {vatType.title}
                    </div>
                  );
                })}
              </div>
              <div className="grid grid-cols-1 mt-6 items-start justify-start h-full w-full flex-wrap">
                    {/* <div
                      key={vatTypes[9].id}
                      className={`flex flex-row w-full dark:bg-dark-300 dark:bg-opacity-20 bg-[#D9D9D9] dark:text-white text-dark-200 rounded-2xl whitespace-nowrap py-2 px-6  cursor-pointer
                      border
                      ${
                        vatSwitch === vatTypes[9].id ? 'border-[#EB3F3F]' : 'border-dark-300 border-opacity-20'
                      }
                      `}
                      onClick={() => {
                        setVatSwitch(vatTypes[9].id);
                      }}
                    >
                      <div
                        className={`w-5 h-5 border ${
                          vatSwitch === vatTypes[9].id ? 'border-[#EB3F3F]' : 'border-dark-300'
                        } bg-white rounded-2xl flex items-center justify-center mr-4`}
                      >
                        <div
                          className={`w-2 h-2 ${
                            vatSwitch === vatTypes[9].id ? 'bg-[#EB3F3F]' : 'bg-white'
                          } rounded-2xl`}
                        ></div>
                      </div>
                      {vatTypes[8].title}
                    </div> */}
                    {
                      <div className={`${vatSwitch === 10 ? 'opacity-1' : 'opacity-0'}`}>
                        <Input
                          name="voucher"
                          // innerRef={register}
                          type="text"
                          label={""}
                          placeholder='Leave a comment'
                          fullWidth={true}
                          defaultValue={""}
                          autoFocus={true}
                          onChange={(e)=> {
                            if(e && e.target) {
                              // @ts-ignore
                              setInputValue(e.target.value)
                            }
                          }}
                        />
                      </div>
                    }
              </div>
            </div>
          </Stack>
          {
            <div className={`text-primary-400 dark:text-primary-400 font-semibold w-fit pt-2 space-x-1 mt-4 ${errorOtherRequired ? 'opacity-1' : 'opacity-0'}`}>
              <p className="inline">
                *Please leave a comment or select another option
              </p>
            </div>
          }
          {
            <div className={`text-primary-400 dark:text-primary-400 font-semibold w-fit pt-2 space-x-1 mt-4 ${errorSelection ? 'opacity-1' : 'opacity-0'}`}>
              <p className="inline">
                *Please select an option before submitting.
              </p>
            </div>
          }
          <div ref={bottomRef}></div>
        </div>
        <div className="w-full p-6">
          <Button type="submit" color="primary" onClick={() => submitSurvey()} fullWidth={true}>
            Submit
          </Button>
        </div>
        
      </div>
    </div>
  );
};

export default SurveyModal;
