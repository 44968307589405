import React from 'react'
import { oneLine } from 'common-tags'

interface IProgressBar {
  title: string
  subtitle?: string
  image?: any
}

const Header: React.FC<IProgressBar> = ({ title, subtitle, image }: IProgressBar) => {
  return (
    <div className={oneLine` pb-8 text-center w-full`}>
      {image ? <img width="100px" height="100" className="block m-auto" src={`/images/${image}`} /> : <h2 className={`text-black dark:text-white text-2xl font-bold blindspot-title ${subtitle ? 'pb-5' : ''}`}>{title}</h2>}
      {subtitle && <div className="text-base dark:text-dark-400 min-tablet:max-w-3/4 m-auto text-body">{subtitle}</div>}
    </div>
  )
}

Header.defaultProps = {
  title: ''
}
export default Header
