import { oneLine } from 'common-tags'
import React, { HTMLAttributes } from 'react'
import { IDirection, IStackGravity, Align, IStackSize } from '../../interface/IStack'

export interface IStackProps extends HTMLAttributes<HTMLDivElement> {
  tag?: any
  children: React.ReactNode
  debug?: boolean
  direction?: IDirection
  gravity?: IStackGravity
  align?: Align
  spacing?: IStackSize
  wrap?: boolean
  fit?: boolean
  fitX?: boolean
  fitY?: boolean
  zIndex?: string
  padding?: boolean
  otherClass?: string
}

const getGravity = (gravity: IStackGravity): string => {
  switch (gravity) {
    case 'start':
      return 'justify-start'
    case 'center':
      return 'justify-center'

    case 'end':
      return 'justify-end'

    default:
      return 'justify-start'
  }
}

const getAlign = (align: string | undefined): string => {
  switch (align) {
    case 'start':
      return 'items-start'
    case 'end':
      return 'items-start'
    case 'center':
      return 'items-center'
    case 'baseline':
      return 'justify-baseline'
    case 'stretch':
      return 'justify-stretch'
    default:
      return ''
  }
}

const getFit = (fitX: boolean | undefined, fitY: boolean | undefined, fit: boolean | undefined): string => {
  let returnClass = ''
  if (fitX) returnClass += 'w-full'
  if (fitY) returnClass += 'h-full'
  if (fit) returnClass = 'w-full h-full'
  return returnClass
}
const getSpacing = (spacing: string, direction: string, wrap: boolean | undefined): string => {
  let d = direction === 'vertical' ? 'x' : 'y'
  switch (spacing) {
    case 'none':
      return ``
    case 'xxs':
      return `space-y-1 md:space-y-0  md:space-${d}-1`
    case 'xs':
      return `space-y-2 md:space-y-0  md:space-${d}-2`
    case 'sm':
      return `space-y-3  md:space-y-0 md:space-${d}-3`
    case 'lg':
      return `space-y-5  md:space-y-0 md:space-${d}-5`
    case 'xl':
      return `space-y-7 md:space-y-0 md:space-${d}-7`
    case 'xxl':
      return `space-y-8 md:space-y-0 md:space-${d}-8`
    case '3xl':
      return `space-y-12 md:space-y-0 md:space-${d}-12`
    default:
      return `space-y-0 md:space-y-0 md:space-${d}-0`
  }
}
const Stack = (props: IStackProps) => {
  const {
    debug = false,
    direction = 'vertical',
    gravity = 'start',
    spacing = 'zero',
    tag = 'div',
    fit,
    fitX,
    fitY,
    wrap,
    padding,
    align,
    zIndex = 'z-10',
    otherClass,
    ...otherProps
  } = props

  return (
    <div
      className={oneLine`${zIndex} md:flex ${padding ? 'px-3' : ''} ${getGravity(gravity)} ${wrap ? 'flex-wrap' : ''} ${getFit(
        fitX,
        fitY,
        fit
      )} ${getAlign(align)} ${getSpacing(spacing, direction, wrap)} ${otherClass}`}
    >
      {props.children}
    </div>
  )
}
Stack.defaultProps = {
  otherClass: ''
}
export default Stack
