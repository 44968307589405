import React from 'react';
import { useTable } from 'react-table';
import { StatusTag, CAMPAIGN_STATUS } from 'components/common/atoms';
import ClassicCard from '../atoms/ClassicCard';
import Popover from '../atoms/popover/popover';

export type TableProps = {
  header: any;
  tableData: any;
};

export type StatusPillType = {
  value: CAMPAIGN_STATUS;
};
export function StatusPill({ value }: StatusPillType) {
  return <StatusTag status={value} text={value} />;
}

export const TableMobile = ({ header, tableData }: TableProps) => {
  const options = {
    columns: header,
    data: tableData,
  };

  const { rows, prepareRow } = useTable(options);

  return (
    <>
      <div className="md:hidden">
        <ClassicCard otherClasses="p-5">
          {rows.map((elem: any, i: any) => {
            prepareRow(elem);
            return (
              <div className="table-mob" key={i}>
                <div className="table-mob-header pb-5 flex justify-between items-center">
                  <div className="text-black dark:text-white text-lg font-semibold ">{elem.cells[0].value}</div>
                  {(elem.cells[elem.cells.length - 1].column.Header === 'Actions' && (
                    <div className="table-mob-actions">
                      <Popover
                        className="rightbottom"
                        handler={
                          <>
                            <div className="action border border-primary-500 rounded-2xl p-2 transition-all duration-300 ease-in-out">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z"
                                  fill="#F53D3F"
                                  stroke="#F53D3F"
                                />
                                <path
                                  d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z"
                                  fill="#F53D3F"
                                  stroke="#F53D3F"
                                />
                                <path
                                  d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                                  fill="#F53D3F"
                                  stroke="#F53D3F"
                                />
                              </svg>
                            </div>
                          </>
                        }
                        content={elem.cells[elem.cells.length - 1].render('Cell')}
                      />
                    </div>
                  )) ||
                    null}
                </div>
                <div className="table-mob-body">
                  <div className="">
                    {elem.cells.map((cell: any, indexCell: any) => {
                      return (
                        (indexCell !== 0 && cell.column.Header !== 'Actions' && (
                          <div key={indexCell}>
                            <div className="table-mob-elem pb-3 flex justify-between items-center">
                              <div className="table-mob-header text-lightGrey dark:text-dark-400 font-medium">{cell.column.Header.length ? cell.column.Header : cell.column.name}</div>
                              <div className="table-mob-value text-dark-default dark:text-white font-semibold text-lg">
                                {cell.render('Cell')}
                              </div>
                            </div>
                          </div>
                        )) ||
                        null
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </ClassicCard>
      </div>
    </>
  );
};
