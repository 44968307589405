import Link from 'next/link';
import React from 'react';
import { Button } from 'components/common/atoms';
import { useRouter } from 'next/router';

type IProps = {
  text: string;
};
const CommingSoon = ({ text }: IProps) => {
  const router = useRouter();

  const onClickRedirect = () => {
    router.push('/publish/basic');
  };

  return (
    <div className="w-full text-center flex items-center justify-center flex-1">
      <div className="space-y-5">
        <h2 className="text-dark-default dark:text-white text-3xl font-bold">Coming soon</h2>
        <p className="pb-5 dark:text-dark-400">{text}</p>
        <Button onClick={onClickRedirect} className="m-auto">Publish a campaign</Button>
      </div>
    </div>
  );
};
export default CommingSoon;
