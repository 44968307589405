import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';

import Slider from 'react-slick';
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
import { Header, Input, Button, SelectInput, AddCardDialogStripe, AddCardDialog } from 'components/common/atoms';
import { TPSAPI } from 'api/users';

import { Stack } from 'components/layout';
import { IUserCard, IUserInvoice, IUserOrganization } from 'interface';
import { DateTime } from 'luxon';
import { useApp } from 'components/app';
import { Toggle } from '../atoms/Toggle';
import { Delete } from 'react-iconly';

type ProfileProps = {
  cards: IUserCard[];
  getCards: () => void;
};

const getCardType = (card: IUserCard) => {
  switch (card.firstDigit) {
    case '4':
      return { image: '/images/icons/visa.svg', name: 'Visa' };
    case '5':
      return { image: '/images/icons/mastercard.svg', name: 'Mastercard' };
    default:
      return { image: '/images/icons/credit-card.svg', name: 'Other' };
  }
};

const ListCards: React.FC<ProfileProps> = ({ cards, getCards }: ProfileProps) => {
  const [viewCards, setCards] = React.useState<IUserCard[]>([]);
  const [themeMode, setThemeMode] = React.useState<string | null>(null);

  const { notify } = useApp();
  const onCardSubmitSuccess = async () => {
    let newCards = await TPSAPI.getUserPayment();
    setCards(newCards);
  };

  useEffect(() => {
    setThemeMode(localStorage.getItem('mode'));
    setCards(cards);
  }, []);
  const removePayment = async (card: string) => {
    await TPSAPI.removePayment(card);
    await getCards();
    notify('Payment method has been removed', 'success');
  };
  const setDefault = async (card: string) => {
    await TPSAPI.setDefaultPayment(card);
    await getCards();
    notify('Payment set to default', 'success');
  };
  return (
    <Slider {...settings} className={`cards arrows center`}>
      {[...viewCards].map(card => {
        return (
          <div key={card.uid} className="space-y-3">
            <div className="relative mx-auto w-fit group">
              <div className="absolute z-50 hidden px-2 py-2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full cursor-pointer group-hover:block top-1/2 left-1/2">
                <a
                  onClick={() => {
                    removePayment(card.uid);
                  }}
                >
                  <Delete set="bold" />
                </a>
              </div>
              <img src={card.processor === 'mobilpay' ? "/images/card-invalid.svg" : "/images/card.svg"} className="mx-auto rounded-3xl" />
              <div className={`absolute top-0 bottom-0 left-0 right-0 flex flex-col justify-between  p-7 
                ${card.processor === 'mobilpay' && themeMode !== 'darkMode' ? 'text-dark' : 'text-white'}`}>
                <div className="flex items-center justify-between">
                  <div>
                    Credit/Debit Card
                    {card.processor === 'mobilpay' &&
                      <div className="bg-lightGrey rounded-2xl text-center mt-2 p-1 font-bold text:white">Invalid Card</div>
                    }
                  </div>
                  <div>
                    <img src={getCardType(card).image} className="max-w-2-5" />
                  </div>
                </div>
                <div className="font-semibold">XXXX - XXXX - XXXX - {card.lastDigits}</div>
              </div>
            </div>
            {card.processor === 'mobilpay' &&
              <div>
                <div className="text-primary-500 mx-auto w-fit">We have updated our payment processing partner.</div>
                <div className="text-primary-500 mx-auto w-fit">Please resubmit this card in order to start your campaigns.</div>
              </div>
            }
            <div className="flex items-center mx-auto space-x-4 font-medium w-fit">
              <p className="dark:text-dark-300">Default card</p>
              <Toggle
                isDisable={card.default}
                value={card.default}
                onChange={(val: boolean) => {
                  if (val)
                    if (val && !card.default) {
                      setDefault(card.uid);
                    }
                }}
              />
            </div>
          </div>
        );
      })}
    </Slider >
  );
};
export default ListCards;
