import React, { useState } from 'react';

import stripeApi from 'api/payment/stripe';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import { IUserOrganization } from 'interface';
type IProps = {
  user: IUserOrganization;
  onCardSubmitSuccess: () => void;
};
const CARD_OPTIONS = {
  iconStyle: 'solid' as const,
  style: {
    base: {
      iconColor: '#6772e5',
      color: '#6772e5',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#6772e5',
      },
    },
    invalid: {
      iconColor: '#ef2961',
      color: '#ef2961',
    },
  },
};

const InjectedCheckoutForm = ({ user, onCardSubmitSuccess }: IProps) => {
  return (
    <>
      <div className={'border-3 dark:bg-transparent border-black border-solid text-black p-1 py-4 my-4 font-bold  dark:border-white dark:text-white'}>
        <strong>IMPORTANT:</strong> DON'T CLOSE THIS WINDOW WHILE PROCESSING
      </div>

      <CardSection user={user} onCardSubmitSuccess={onCardSubmitSuccess} />
    </>
  );
};

export default InjectedCheckoutForm;
