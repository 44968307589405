import React from 'react'
import ReactTooltip from 'react-tooltip'
import Pin from '../atoms/Pin'

type IProps = {
  isSelected: boolean
  type: string
  tooltipText?: string
}
const MapPin = ({ isSelected = false, type, tooltipText }: IProps) => {
  return (
    <>
      {(tooltipText && <div className="absolute pin-tooltip-top rounded-xl bg-dark-default text-white text-center w-64 p-2 arrow-down" dangerouslySetInnerHTML={{ __html: tooltipText }}></div>) || <></>}
      <Pin isSelected={isSelected} type={type} />
    </>
  )
}

export default MapPin
