//TO DO -> ADD ERROR MESSAGE CODES
const messages: any = {
  AccountUpdated: 'The Account has been updated',
  EmailAlreadyInDatabase: 'Email taken',
  EmailNotificationSent: 'The Account has been updated. An email was send to validate the request',
  EmailUpdated: 'The email has been changed',
  PasswordDifferent: 'The old password you have entered is incorrect',
  PasswordChanged: 'Your password has been changed'

};


export const getErrorMessage = (e: any) => {
  let message: any = 'try again later'
  try {


    if (e && e.response && e.response.data) {
      const errorData = e.response.data
      console.log('errorData', errorData)
      // for db validation errors
      if (
        Array.isArray(errorData) &&
        errorData[0] &&
        errorData[0].error &&
        Object.values(errorData[0].error)[0]
      ) {
        message = Object.values(errorData[0].error)[0]
      } else {

        message =
          errorData.error ||
          errorData.message ||
          errorData.msg ||
          errorData.errMessage ||
          errorData
      }
    }
    // eslint-disable-next-line no-console
    return (messages[message]) || message;

  } catch (error) {
    console.log(error)
  }
}


export const getResponseMessage = (e: string) => {
  let message: any = 'try again later'
  try {


    // eslint-disable-next-line no-console
    return (messages[e]) || message;

  } catch (error) {
    console.log(error)
  }
}