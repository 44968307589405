import { oneLine } from 'common-tags';
import { Stack } from 'components/layout';
import { INetflix } from 'interface/ILocation';
import Link from 'next/link';
import React from 'react';
import Slider from 'react-slick';
import { Button } from '../atoms';
import { gtmSelectItem } from 'api/gtm/gtm';

export type ClassicSliderType = {
  sliderItems: INetflix[];
  fullCenter?: boolean;
  category?: string;
};

export const ClassicSlider = ({ sliderItems, fullCenter, category }: ClassicSliderType) => {
  const sliderContent = oneLine`
  absolute z-50
  top-0 left-0
  w-full h-full
  flex flex-col justify-between
  px-4 py-6 
  ease-in-out transition-opacity duration-300
`;

  const detailsItemLabel = oneLine`
  max-lg:text-xs text-xxs
  text-body
  dark:text-dark-400
  pb-1
`;

  const detailsItemValue = oneLine`
    text-black 
    dark:text-white
    font-extrabold
  `;

  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '20px',
        },
      },
    ],
  };
  return (
    <Slider {...settings} className={`classic ${!fullCenter ? 'left-empty' : 'full-center'}`}>
      {sliderItems.map((el, index) => {
        return (
          <div key={el.uuid} className="img-container relative h-50 h-56 max-w-2xl w-100 outline-none">
            <img alt="" src={el.photo} className="block object-cover h-full w-full rounded-2xl " />
            <div className={`content-container ${sliderContent}`}>
              <div className="">
                <div className="text-white text-lg font-bold">{el.name}</div>
                <div className="text-white text-base font-bold">{el.address}</div>
              </div>
            </div>
            <div className={`details-container`}>
              <div className="">
                <div className="text-dark-default dark:text-white pb-2 font-semibold max-lg:text-sm text-xs">
                  Details about <span className="font-extrabold">this screen</span>
                </div>
                <div className={`${detailsItemLabel}`}>
                  Average price: <span className={`${detailsItemValue}`}>{el.avg}</span>
                </div>
                <div className={`${detailsItemLabel}`}>
                  Bookable: <span className={`${detailsItemValue}`}>{el.network}</span>
                </div>
                <div className={`${detailsItemLabel}`}>
                  Media type:{' '}
                  <span className={`${detailsItemValue}`}>
                    {el.contentType === 'video' ? 'full motion video / static images' : 'static images'}
                  </span>
                </div>
                <div className={`${detailsItemLabel}`}>
                  Accepted resolution: <span className={`${detailsItemValue}`}>{el.resolution}</span>
                </div>
              </div>
              <div className="flex">
                {/* <a href={`/publish/basic?screen=${el.uuid}`}>
                  <Button fill="outline" buttonSize="sm">
                    Book
                  </Button>
                </a>
                <a href={`/location/${el.uuid}`}>
                  <Button fill="none" buttonSize="sm">
                    View Screen
                  </Button>
                </a> */}
                <Link href={`/location/${el.uuid}`} prefetch={false}>
                  <a>
                    <Button onClick={() => { gtmSelectItem(el, index, category) }} fill="outline" buttonSize="sm">
                      View Screen
                    </Button>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};
