import React, { useEffect, useState } from 'react';

const LayerdColumnsPlays = dynamic(() => import('../atoms/LayerdColumnsPlays'), { ssr: false });
import { Button } from '../atoms';
import { CAMPAIGNS } from 'api/';
import dynamic from 'next/dynamic';
import { IPerformance } from 'interface';
import numeral from 'numeral';

type IProps = {
  chartTitle: string;
  screen: string;
  uuid: string;
  tz: string;
  filterType: string;
  type?: string;
};
const AmChart = ({ chartTitle, screen, uuid, tz, filterType, type }: IProps) => {
  // const [filterType, setFilterType] = useState('day')
  const [chartData, setChartData] = React.useState<IPerformance[]>([]);
  const [total, setTotal] = React.useState<number>(0);
  useEffect(() => {
    fetchData();
    return () => {};
  }, [filterType, screen]);

  const fetchData = async () => {
    if (uuid) {
      setChartData([]);
      console.log('type', type);
      const result =
        type && type === 'group'
          ? await CAMPAIGNS.getPerformanceGroup(uuid, tz, filterType, screen)
          : await CAMPAIGNS.getPerformance(uuid, tz, filterType, screen);

      if (result.performance) {
        let h = result.performance.reduce((acc: number, el) => {
          return acc + el.plays;
        }, 0);
        setTotal(h);
        setChartData(result.performance);
      }
    }
  };

  return (
    <div className="w-full space-y-5 bg-chartBgGrey dark:bg-dark-200 dark:border-2 dark:border-bordercolordark dark:border-opacity-40 rounded-2xl p-6">
      <div className={`flex flex-col ${total ? '' : 'chart-no-data'}`}>
        <h3 className=" font-semibold dark:text-dark-400 text-base md:text-sm tracking-tighter text-chartTitleGrey">
          {chartTitle}
        </h3>
        <div className="font-bold dark:text-white text-dark-default text-lg md:text-2xl pt-2">
          {numeral(total).format('0,0')}
        </div>
      </div>

      {total > 0 ? (
        <div className="">
          {chartData && chartData.length > 0 ? (
            <LayerdColumnsPlays
              chartData={chartData}
              isHourlyDisplay={filterType === 'hour' ? true : false}
            ></LayerdColumnsPlays>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <img src="/images/plays.png" className="max-w-sm" loading="lazy" />
          {/* <img src="/images/plays-dark.png" className="max-w-sm hidden dark:block" loading="lazy" /> */}
          <h3 className="py-5 text-chartTitleGrey dark:text-white text-sm font-semibold">
            Data will be displayed after the campaign starts.
          </h3>
        </div>
      )}
    </div>
  );
};
export default AmChart;
