import MondayUtils from '@utils/monday';
import React, { useEffect, useRef } from 'react';

const OptionSelect = ({
  options,
  onSelect,
  onHover,
  closePopup,
  showDelete,
}: {
  showDelete?: boolean;
  options: string[];
  onSelect: (option: string) => void;
  onHover: (option: string) => void;
  closePopup: () => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const dropdown = ref.current;
    const windowHeight = window.innerHeight;
    const triggerRect = dropdown?.getBoundingClientRect();

    if (dropdown && triggerRect) {
      const spaceBelow = windowHeight - triggerRect.top;

      // Calculăm înălțimea disponibilă pentru dropdown
      const maxDropdownHeight = Math.min(600, spaceBelow - 30); // 300px sau spațiul disponibil cu o margine de 20px

      // Setăm înălțimea dropdown-ului și activăm scroll-ul dacă este necesar
      dropdown.style.maxHeight = `${maxDropdownHeight}px`;
      dropdown.style.overflowY = 'auto'; // Scroll activ indiferent de înălțime
    }
  }, []);

  const getDescription = (el: any) => {
    switch (el) {
      case 'in':
        return 'Choose cities, countries, or zip codes';
      case 'near':
        return 'Set nearby points of interest';
      case 'on':
        return 'Select the venue type of screens';
      case 'with a budget of':
        return 'Define the budget allocated';
      case 'for a duration of':
        return 'Set the campaign duration in days';
      case 'using':
        return 'Choose the types of screen';
      case 'targeting the income group':
        return 'Select the income target';
      case 'targeting the age group':
        return 'Choose the age group targeted';
      case 'targeting':
        return 'Select the demographic target';
      default:
        return '';
    }
  };

  return (
    <div
      ref={ref}
      className="absolute z-110 top-[40px] left-0 w-[300px] dark:monday-box-shadow min-h-[200px] dark:bg-dark-200 bg-white p-2 rounded"
    >
      {showDelete && (
        <div
          key="delete"
          className="px-2 gap-2 py-1 hover:bg-dark-300 hover:bg-opacity-20 cursor-pointer hover:rounded flex flex-row border-b border-opacity-20"
          onClick={() => onSelect('delete')}
        >
          <div className="flex items-center justify-center bg-dark-300 bg-opacity-20 px-2 min-w-[34px] rounded">
            <img className='block dark:hidden' src={MondayUtils.getIcon('delete-dark')} />
            <img className='dark:block hidden' src={MondayUtils.getIcon('delete')} />
          </div>
          <div className="flex flex-col text-[#F64747]">
            <span className="">Delete</span>
            <span className="text-[12px] text-[#F64747]">Remove current option</span>
          </div>
        </div>
      )}
      {options.map(el => (
        <div
          key={el}
          className="px-2 gap-2 py-1 hover:bg-dark-300 hover:bg-opacity-20 cursor-pointer hover:rounded flex flex-row border-b border-opacity-20"
          onClick={() => onSelect(el)}
        >
          <div className="flex items-center justify-center bg-dark-300 bg-opacity-20 px-2 rounded">
            <img className='block dark:hidden' src={MondayUtils.getIcon(`${el}-dark`)} />
            <img className='dark:block hidden' src={MondayUtils.getIcon(el)} />
          </div>
          <div className="flex flex-col">
            <span className="">{el}..</span>
            <span className="text-[12px] dark:text-dark-300">{getDescription(el)}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OptionSelect;
