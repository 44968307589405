import { useEffect, useRef, useState } from 'react';
import OptionSelect from 'components/common/organisms/Monday/components/OptionSelect';
import { useMondayContext } from '../../../../../context/monday/monday.reducer';
import useOnClickOutside from 'components/common/atoms/popover/useOnClickOutSide';

// eslint-disable-next-line no-shadow
export enum ComponentVariant {
  IN = 'in',
  NEAR = 'near',
  ON = 'on',
  SPENDING = 'with a budget of',
  DURATION = 'for a duration of',
  TYPE = 'using',
  WITH = 'targeting the income group',
  AGE = 'targeting the age group',
  TARGETING = 'targeting',
}
const EmptySelection = ({
  defaultOption,
  onSelect,
  isDisable,
  used,
  allowDelete,
  otherText,
}: {
  allowDelete?: boolean;
  isDisable?: boolean;
  used: ComponentVariant[];
  defaultOption?: string;
  otherText?: string;
  onSelect: (value: string) => void;
}) => {
  const {
    state: { sentence, usedComponent },
  } = useMondayContext();
  const IN_options = Object.entries(sentence).filter(([key]) => key.toUpperCase().includes('IN'));
  const ref = useRef<HTMLDivElement>(null);
  const emptyOption = [
    'on',
    'with a budget of',
    'near',
    'for a duration of',
    'using',
    'targeting the income group',
    'targeting the age group',
    'targeting',
  ].filter(el => {
    if (usedComponent.includes(ComponentVariant.SPENDING) && el === ComponentVariant.SPENDING) {
      return false;
    }
    if (usedComponent.includes(ComponentVariant.TARGETING) && el === 'targeting') {
      return false;
    }
    if (usedComponent.includes(ComponentVariant.ON) && el === ComponentVariant.ON) {
      return false;
    }
    if (usedComponent.includes(ComponentVariant.IN) && el === 'in') {
      return false;
    }
    if (usedComponent.includes(ComponentVariant.AGE) && el === 'targeting the age group') {
      return false;
    }
    if (usedComponent.includes(ComponentVariant.TYPE) && el === 'using') {
      return false;
    }
    if (usedComponent.includes(ComponentVariant.DURATION) && el === 'for a duration of') {
      return false;
    }
    if (el === 'near' && IN_options?.length === 0) {
      return false;
    }
    if (usedComponent.includes(ComponentVariant.SPENDING) && el === ComponentVariant.SPENDING) {
      return false;
    }
    if (usedComponent.includes(ComponentVariant.WITH) && el === ComponentVariant.WITH) {
      return false;
    }
    return true;
  });

  const [showOptions, setShowOptions] = useState(false);
  const [hoverText, setHoverText] = useState('');
  useOnClickOutside(ref, () => setShowOptions(false));

  return (
    <div ref={ref} className={`relative ${showOptions ? 'z-20' : ''}`}>
      {showOptions && (
        <OptionSelect
          showDelete={allowDelete}
          options={emptyOption}
          closePopup={() => {
            setShowOptions(false);
          }}
          onSelect={(value: string) => {
            onSelect(value);
            setShowOptions(false);
          }}
          onHover={(value: string) => {
            setHoverText(value);
          }}
        />
      )}
      <button
        className={`border-b ${
          defaultOption
            ? showOptions
              ? 'text-primary-default border-primary-default'
              : 'border-dark-100 dark:border-dark-400'
            : !showOptions
            ? 'dark:text-dark-300 text-dark-200 text-opacity-70 dark:border-dark-300 border-dark-200 border-opacity-70'
            : 'text-primary-default border-primary-default'
        }`}
        type="button"
        onClick={() => {
          if (isDisable) {
            return;
          }
          setShowOptions(true);
        }}
      >
        {hoverText !== '' ? hoverText : defaultOption ? `${defaultOption} ${otherText || ''}` : 'select option ...'}
      </button>
    </div>
  );
};

export default EmptySelection;
