import React, { useState } from 'react';
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import { Button } from '..';
import StripeApi from 'api/payment/stripe';
import { IUserOrganization } from 'interface';
type IProps = {
  user: IUserOrganization;
  onCardSubmitSuccess: () => void;
};
const CardSection = ({ user, onCardSubmitSuccess }: IProps) => {
  const [disabled, setDisabled] = useState(true);
  const [themeMode, setThemeMode] = React.useState<string | null>(null);

  const elements = useElements();
  const stripe = useStripe();

  const [payment, setPayment] = useState({ status: 'initial' });
  const [errorMessage, setErrorMessage] = useState('');

  React.useEffect(() => {
    setThemeMode(localStorage.getItem('mode'));
  }, []);

  const isSubmitDisabled = () => {
    let error = true;
    const div = document.getElementsByClassName('stripe-wrapper')[0];
    const children = Array.from(div.getElementsByClassName(`StripeElement`));

    children.forEach(field => {
      const isEmpty = field.classList.contains('StripeElement--empty');
      const isInvalid = field.classList.contains('StripeElement--invalid');
      if (isEmpty || isInvalid) error = true;
      else error = false;
    });

    setDisabled(error);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    // Abort if form isn't valid
    if (!e.currentTarget.reportValidity()) return;
    setPayment({ status: 'processing' });

    const result: any = await StripeApi.getIntent();
    const response = result.data.data;
    setPayment(response);

    if (response.statusCode === 500) {
      setPayment({ status: 'error' });
      setErrorMessage(response.message);
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements!.getElement(CardNumberElement);

    // Use your card Element with other Stripe.js APIs
    const confirmation = await stripe!.confirmCardSetup(response.clientSecret, {
      payment_method: {
        card: cardElement!,
        billing_details: {
          name: user.name,
          address: {
            country: user.countryIso,
            line1: user.address,
            state: user.state,
            postal_code: 'none',
          },
          email: user.email,
          phone: user.phone,
        },
        metadata: {
          sub: user.uuid,
          companyUuid: user.companyUuid,
          companyReg: user.regNumber,
        },
      },
    });

    if (confirmation.error) {
      setPayment({ status: 'error' });
      setErrorMessage(confirmation.error.message ?? 'An unknown error occured');
    } else if (confirmation.setupIntent) {
      setPayment({ status: 'processing' });
      await StripeApi.paymentAdd({ paymentMethod: confirmation.setupIntent.payment_method });
      setPayment({ status: 'succeeded' });
      onCardSubmitSuccess();
    }
  };

  const PaymentStatus = ({ status }: { status: string }) => {
    switch (status) {
      case 'processing':
      case 'requires_payment_method':
      case 'requires_confirmation':
        return (
          <div className="w-full dark:text-white text-center border border-gray-400 dark:border-grey-default dark:border-opacity-80">
            <p>Processing...</p>
          </div>
        );

      case 'requires_action':
        return (
          <div className="w-full dark:text-white text-center border border-gray-400 dark:border-grey-default dark:border-opacity-80">
            <p>Authenticating...</p>
          </div>
        );

      case 'succeeded':
        return (
          <div className="w-full dark:text-white text-center border border-gray-400  dark:border-grey-default dark:border-opacity-80">
            <p>Payment Succeeded</p>
          </div>
        );
      case 'error':
        return (
          <>
            <div className="w-full dark:text-white text-center border border-red-400 text-red dark:border-grey-default dark:border-opacity-80">
              <p className="">{errorMessage}</p>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className={'stripe-wrapper space-y-4'}>
      <form onSubmit={handleSubmit}>
        {errorMessage && <div className={'stripe-error-message dark:text-white pt-2'}>{errorMessage}</div>}
        <div className={'card-stripe space-y-4 pt-4 pb-4'}>
          <div className={'space-y-4'} id={'card'}>
            <label className={'text-black dark:text-white w-full text-left cardNumberInput space-y-4'}>
              <span className="block text-left">Card details</span>
              <CardNumberElement
                onFocus={() => {
                  isSubmitDisabled();
                }}
                onBlur={() => {
                  isSubmitDisabled();
                }}
                options={{
                  style: {
                    base: {
                      color: themeMode === 'darkMode' ? '#FFF' : '#000',
                    },
                  },
                }}
                onChange={isSubmitDisabled}
                className={
                  'w-full dark:text-white border-b border-gray-900 dark:border-grey-default dark:border-opacity-80 '
                }
              />
            </label>
            <div className="flex w-full">
              <label
                className={'text-black dark:text-white w-full text-left cardExpiryInput space-y-4'}
              >
                <span className="block text-left">Expiration date</span>
                <CardExpiryElement
                  onFocus={() => {
                    isSubmitDisabled();
                  }}
                  onBlur={() => {
                    isSubmitDisabled();
                  }}
                  onChange={isSubmitDisabled}
                  options={{
                    style: {
                      base: {
                        color: themeMode === 'darkMode' ? '#FFF' : '#000',
                      },
                    },
                  }}
                  className={'w-full border-b border-gray-900 dark:border-grey-default dark:border-opacity-80 '}
                />
              </label>
              <label className={'text-black dark:text-white w-full text-left cardCvcInput space-y-4'}>
                <span className="block text-left">CVC</span>
                <CardCvcElement
                  options={{
                    style: {
                      base: {
                        color: themeMode === 'darkMode' ? '#FFF' : '#000',
                      },
                    },
                  }}
                  onChange={isSubmitDisabled}
                  className={
                    'w-full dark:text-white border-b border-gray-900 dark:border-grey-default dark:border-opacity-80 '
                  }
                />
              </label>
            </div>
          </div>
        </div>
        <div
          style={{
            justifyContent: 'center',
            alignContent: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <Button type="submit">Save Payment</Button>
        </div>
      </form>
      <PaymentStatus status={payment.status} />
    </div>
  );
};

export default CardSection;
