import { oneLine } from 'common-tags';
import { ICampaigFormats, IScreens } from 'interface';
import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import ReactMapboxGl, { Marker, ZoomControl, RotationControl } from 'react-mapbox-gl';
import MapPin from './MapPin';
import Popup from 'components/common/molecules/Popup';
import { Icon } from '@iconify/react-with-api';
import ReactTooltip from 'react-tooltip';
import { LightBox } from '../atoms';
import { useVenues } from '@utils/venues';
import { Carousel } from 'react-responsive-carousel';
import { formatImpressionsToReadable } from '@utils/formatImpressions';

const MAPBOX_TOKEN = 'pk.eyJ1IjoidHBzZW5nYWdlIiwiYSI6ImNqZGluMGcyazEybGcycW4wdnhwanR3cXcifQ.XpvUi6PLguDLfYrksmcDxw';
const Mapbox = ReactMapboxGl({ accessToken: MAPBOX_TOKEN });
type IProps = {
  screens: IScreens[];
  shareableInfo: boolean;
  visibleColumns: string[];
};

const styles = {
  map: {
    width: '100%',
    marginLeft: '0',
    height: '100%',
  },
  marker: {
    width: 60,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const CampaignLocations: React.FC<IProps> = ({ screens, shareableInfo, visibleColumns }: IProps) => {
  const [data, setData] = React.useState<any[]>([]);
  const [openedLocation, setOpenedLocation] = React.useState<IScreens>();
  const [themeMode, setThemeMode] = React.useState<string | null>(null);
  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const [focusedLocation, setFocusedLocation] = React.useState<any | null>(null);
  const [photos, setLocationPhotos] = React.useState<string[]>([]);

  let mapRef = React.useRef<any>(null);

  useEffect(() => {
    const items = localStorage.getItem('mode');
    if (items) {
      setThemeMode(items);
    }

    let tableData = screens.map((el: IScreens, index: any) => {
      let photosLoc = [];
      let thumbnailsLoc = [];
      if (el.locationImages) {
        for (let i = 0; i < el.locationImages; i++) {
          photosLoc.push('https://i.seeblindspot.com/' + el.uuid + '/0' + i);
          thumbnailsLoc.push('https://i.seeblindspot.com/thumb/' + el.uuid + '/0' + i);
        }
      } else {
        if (el.networkImages === 0) {
          photosLoc.push(`https://i.seeblindspot.com/${el.uuid}/00`);
          thumbnailsLoc.push(`https://i.seeblindspot.com/${el.uuid}/00`);
        } else {
          for (let i = 0; i < el.networkImages; i++) {
            if (el.networkId) {
              photosLoc.push(`https://i.seeblindspot.com/${el.networkId}/0${i}`);
              thumbnailsLoc.push(`https://i.seeblindspot.com/thumb/${el.networkId}/0${i}`);
            }
          }
        }
      }
      const hasTemplate = el.template && el.template.length > 0;

      return {
        index: index,
        name: el.name,
        pph: 3600 / el.frequency.frequency,
        hasTemplate: hasTemplate,
        template: el.template,
        resolution: `${el.horRez}x${el.vertRez}`,
        circuit: el.circuit,
        city: el.city.name,
        contentType: el.contentType,
        country: el.country.name,
        latitude: el.latitude || 0,
        longitude: el.longitude || 0,
        slots: el.totalSlots || 0,
        plays: el.plays || 0,
        reach: el.reach ? formatImpressionsToReadable(el.reach) : 0,
        price: el.price || '',
        locationType: el.locationType,
        venueType: el.venyueTypeId ? useVenues().getVenuById(Number(el.venyueTypeId))?.title : '',
        network: el.networkName,
        photosLocation: photosLoc,
        thumbnailsLoc: thumbnailsLoc,
      };
    });
    setData(tableData);
  }, [screens]);

  const lineContainerClasses = oneLine`
  flex flex-col justify-start
  `;

  const headerClasses = oneLine`
  text-xs
  text-body
  dark:text-dark-400
  font-medium
  tracking-tighter
  pb-1
  `;

  const valueClasses = oneLine`
  text-sm
  text-dark-default
  dark:text-white
  font-semibold md:font-bold
  `;
  const columns = React.useMemo(
    () => [
      {
        name: '',
        selector: 'index',
        sortable: false,
        accessor: 'index',
        center: true,
        width: '4rem',
        omit: !visibleColumns.includes('no'),
        style: {
          padding: 0,
        },
        cell: (props: any) => {
          return (
            <>
              <ReactTooltip
                id={'options3'}
                getContent={() => {
                  return null;
                }}
                place="top"
                className="custom-tooltip"
              >
                <div className="bg-white dark:text-white text-black dark:bg-[#5c5b5c] p-2 rounded w-fit">
                  Show location image
                </div>
              </ReactTooltip>
              {props.thumbnailsLoc.length === 1 ? (
                <img
                  src={props.thumbnailsLoc[0]}
                  className="rounded cursor-pointer"
                  onClick={() => {
                    setLocationPhotos(props.photosLocation);
                    setFocusedLocation(screens[props.index]);
                    setOpenPreview(true);
                  }}
                />
              ) : (
                <Carousel
                  onClickItem={() => {
                    setLocationPhotos(props.photosLocation);
                    setFocusedLocation(screens[props.index]);
                    setOpenPreview(true);
                  }}
                  className="cursor-pointer"
                  infiniteLoop
                  autoPlay={true}
                  dynamicHeight={false}
                  showStatus={false}
                  showArrows={false}
                  showThumbs={false}
                  showIndicators={false}
                >
                  {props.thumbnailsLoc.map((el: any, index: any) => (
                    <img src={el} key={index} className="rounded cursor-pointer" />
                  ))}
                </Carousel>
              )}
            </>
          );
        },
      },
      {
        name: 'Name',
        selector: 'name',
        sortable: false,
        accessor: 'name',
        omit: !visibleColumns.includes('name'),
        grow: 3,
        cell: (props: any) => (
          <div className={lineContainerClasses}>
            {/* <div className={headerClasses}>Name</div> */}
            <div
              className={`cursor-pointer underline ${valueClasses}`}
              onClick={() => {
                setOpenedLocation(screens[props.index]);
              }}
            >
              {props.name}
            </div>
          </div>
        ),
      },
      {
        name: 'Network',
        selector: 'network',
        omit: !visibleColumns.includes('network'),
        sortable: true,
        grow: '2',
        cell: (props: any) => (
          <div className={lineContainerClasses}>
            {/* <div className={headerClasses}>Plays per hour</div> */}
            <div className={valueClasses}>{props.network}</div>
          </div>
        ),
      },
      {
        name: 'Type',
        selector: 'locationType',
        omit: !visibleColumns.includes('type'),
        sortable: true,
        grow: '2',
        cell: (props: any) => (
          <div className={lineContainerClasses}>
            {/* <div className={headerClasses}>Plays per hour</div> */}
            <div
              className={`${valueClasses} 
            ${props.locationType === 'indoor' ? '!bg-[#f1cb69]' : ''} 
            ${props.locationType === 'outdoor' ? '!bg-[#cc9cf2]' : ''} 
            ${props.locationType === 'mobile' ? '!bg-[#b6ee9b]' : ''} 
            !bg-opacity-70 px-2 rounded-20 whitespace-nowrap`}
            >
              {props.locationType}
            </div>
          </div>
        ),
      },
      {
        name: 'Venue Type',
        selector: 'venueType',
        omit: !visibleColumns.includes('venue'),
        sortable: true,
        grow: '2',
        cell: (props: any) => (
          <div className={lineContainerClasses}>
            {/* <div className={headerClasses}>Plays per hour</div> */}
            <div className={valueClasses}>{props.venueType}</div>
          </div>
        ),
      },
      {
        name: 'Plays/ hour',
        selector: 'pph',
        sortable: false,
        width: '4rem',
        omit: !visibleColumns.includes('plays/hour'),
        cell: (props: any) => (
          <div className={lineContainerClasses}>
            {/* <div className={headerClasses}>Plays per hour</div> */}
            <div className={valueClasses}>{props.pph}</div>
          </div>
        ),
      },
      {
        name: 'Resolution',
        selector: 'resolution',
        omit: !visibleColumns.includes('resolution'),
        sortable: true,
        cell: (props: any) => (
          <div className={lineContainerClasses}>
            {/* <div className={headerClasses}>Resolution</div> */}
            {!props.hasTemplate ? (
              <div className={`${valueClasses} whitespace-nowrap`}>{props.resolution}</div>
            ) : (
              <a href={props.template} target="_blank" className={`${valueClasses} whitespace-nowrap`} rel="noreferrer">
                View <br />
                Template
              </a>
            )}
          </div>
        ),
      },
      {
        name: 'ContentType',
        selector: 'contentType',
        omit: !visibleColumns.includes('contentType'),
        sortable: true,
        cell: (props: any) => (
          <div className={lineContainerClasses}>
            {/* <div className={headerClasses}>Resolution</div> */}
            <div className={`${valueClasses} whitespace-nowrap`}>
              {props.contentType?.indexOf('video') > -1 ? 'video' : 'static'}
            </div>
          </div>
        ),
      },
      {
        name: 'Screens',
        selector: 'circuit',
        omit: !visibleColumns.includes('screens'),
        sortable: false,
        width: '4rem',
        cell: (props: any) => (
          <div className={lineContainerClasses}>
            {/* <div className={headerClasses}>Screens</div> */}
            <div className={valueClasses}>{props.circuit}</div>
          </div>
        ),
      },
      {
        name: 'Country, City',
        omit: !visibleColumns.includes('country/city'),
        selector: 'city',
        sortable: false,
        grow: '3',
        cell: (props: any) => (
          <div className={lineContainerClasses}>
            {/* <div className={headerClasses}>City</div> */}
            <div className={valueClasses}>
              {props.country}, {props.city}
            </div>
          </div>
        ),
      },
    ],
    [visibleColumns]
  );

  const columnsShareablePage = React.useMemo(
    () => [
      {
        name: 'Slots',
        selector: 'slots',
        sortable: true,
        omit: !visibleColumns.includes('slots'),
        cell: (props: any) => (
          <div className={lineContainerClasses}>
            {/* <div className={headerClasses}>Slots</div> */}
            <div className={valueClasses}>{props.slots}</div>
          </div>
        ),
      },
      {
        name: 'Est. Plays',
        selector: 'plays',
        omit: !visibleColumns.includes('plays'),
        sortable: true,
        cell: (props: any) => (
          <div className={lineContainerClasses}>
            {/* <div className={headerClasses}>Est. Plays</div> */}
            <div className={valueClasses}>{props.plays}</div>
          </div>
        ),
      },
      {
        name: 'Est. Impressions',
        selector: 'reach',
        omit: !visibleColumns.includes('reach'),
        sortable: true,
        cell: (props: any) => (
          <div className={lineContainerClasses}>
            {/* <div className={headerClasses}>Est. Reach</div> */}
            <div className={valueClasses}>{props.reach}</div>
          </div>
        ),
      },
      {
        name: 'Cost',
        omit: !visibleColumns.includes('cost'),
        selector: 'price',
        sortable: true,
        cell: (props: any) => (
          <div className={lineContainerClasses}>
            {/* <div className={headerClasses}>Cost</div> */}
            <div className={`${valueClasses} whitespace-nowrap`}>{props.price}</div>
          </div>
        ),
      },
    ],
    [visibleColumns]
  );

  const actionsColumns = [
    {
      name: 'Options',
      selector: 'options',
      sortable: false,
      right: true,
      cell: (props: any) => (
        <div className={lineContainerClasses}>
          {/* <div className={headerClasses}>Options</div> */}
          <div className={'flex flex-row flex-nowrap items-center gap-2'}>
            <ReactTooltip
              id={'options1'}
              getContent={() => {
                return null;
              }}
              place="top"
              className="custom-tooltip"
            >
              <div className="bg-white dark:text-white text-black dark:bg-[#5c5b5c] p-2 rounded w-fit">
                Show location images
              </div>
            </ReactTooltip>
            <ReactTooltip
              id={'options2'}
              getContent={() => {
                return null;
              }}
              place="top"
              className="custom-tooltip"
            >
              <div className="bg-white dark:text-white text-black dark:bg-[#5c5b5c] p-2 rounded w-fit">
                Show location on map
              </div>
            </ReactTooltip>
            <ReactTooltip
              id={'googleMapsTooltip'}
              getContent={() => {
                return null;
              }}
              place="top"
              className="custom-tooltip"
            >
              <div className="bg-white dark:text-white text-black dark:bg-[#5c5b5c] p-2 rounded w-fit">
                Show location on Google Maps
              </div>
            </ReactTooltip>
            <Icon
              onClick={() => {
                setLocationPhotos(props.photosLocation);
                setFocusedLocation(screens[props.index]);
                setOpenPreview(true);
              }}
              data-tip
              data-for="options1"
              className="cursor-pointer"
              height="24px"
              width="24px"
              icon="fa-solid:images"
            />
            <Icon
              onClick={() => {
                setOpenedLocation(screens[props.index]);
              }}
              data-tip
              data-for="options2"
              className="cursor-pointer"
              height="24px"
              width="24px"
              icon="lucide:map-pin"
            />
            <a
              href={`https://maps.google.com/?q=${screens[props.index].latitude},${screens[props.index].longitude}`}
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                data-tip
                data-for="googleMapsTooltip"
                className="cursor-pointer"
                height="24px"
                width="24px"
                icon="logos:google-maps"
              />
            </a>
          </div>
        </div>
      ),
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: any) => row.locationType === 'indoor',
      style: {
        borderRight: '3px solid rgb(241, 203, 105) !important',
      },
    },
    {
      when: (row: any) => row.locationType === 'outdoor',
      style: {
        backgroundColor: 'rgba(248, 148, 6, 0.9)',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    {
      when: (row: any) => row.locationType === 'mobile',
      style: {
        backgroundColor: 'rgba(242, 38, 19, 0.9)',
        color: 'white',
        '&:hover': {
          cursor: 'not-allowed',
        },
      },
    },
  ];

  return (
    <>
      {openPreview && (
        <LightBox
          closeDialog={() => {
            setOpenPreview(false);
          }}
          photos={photos}
          locationName={focusedLocation ? focusedLocation.n : 'location'}
        />
      )}
      <DataTable
        noHeader={true}
        pagination={true}
        paginationPerPage={15}
        columns={
          shareableInfo ? [...columns, ...columnsShareablePage, ...actionsColumns] : [...columns, ...actionsColumns]
        }
        data={data}
        // conditionalRowStyles={conditionalRowStyles}
        className="table-simulate !rounded-none"
      />
      {(openedLocation && (
        <Popup
          title="Location"
          closeAction={() => {
            setOpenedLocation(undefined);
          }}
        >
          <Mapbox
            style={
              themeMode === 'darkMode'
                ? 'mapbox://styles/tpsengage/cl4xtn4nr000914nvbn65ipuv'
                : 'mapbox://styles/tpsengage/ckxynelz3es3d15qzp2vx7a33'
            }
            movingMethod="easeTo"
            center={[openedLocation.longitude, openedLocation.latitude]}
            zoom={[15]}
            containerStyle={styles.map}
            onStyleLoad={map => {
              mapRef.current = map;
            }}
          >
            <Marker
              style={styles.marker}
              onClick={() => {}}
              coordinates={[openedLocation.longitude, openedLocation.latitude]}
            >
              <>
                <MapPin type="" isSelected={false} tooltipText={`<strong>${openedLocation.name}</strong>`} />
              </>
            </Marker>

            <div className="map-controls" style={{ top: '80%', position: 'relative' }}>
              <ZoomControl />
              <RotationControl />
            </div>
          </Mapbox>
        </Popup>
      )) ||
        null}
    </>
  );
};
export default CampaignLocations;
