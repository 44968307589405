import { useTimeout } from '@utils/useTimeout';
import { Time } from 'components/common/atoms/CampaignCard';
import { INotificationPopup } from 'interface/INotifications';
import { DateTime } from 'luxon';
import React from 'react';

type IProps = {
    children: INotificationPopup
    close: () => void;
};

export const NotificationPopup = ({ children, close }: IProps) => {

  const { icon, title, content, createdAt, type} = children;

  const getLabelTitleByValue = (input:string): string => {
    const words = input.split('-');
  
    const transformedWords = words.map(word => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1);
      return firstLetter + restOfWord;
    });
  
    const transformedString = transformedWords.join(' ');
  
    return transformedString;
  }

  //show time for custom notification Popup
  useTimeout(close, 9000);
  return (
    <div className={`notification custom-notification-width ${type.slice(type.indexOf('-') + 1)}_class animateOpen`}>
           <div  className={`flex flex-row w-full`}>
            <div className='w-2/12'>
                <img src={`/noti-icons/${getLabelTitleByValue(type).replace(/\s/g, "")}.svg`}/>
            </div>
            <div className='flex flex-col items-start mb-2 w-10/12 pl-2 md:pl-0'>
                <div className='w-full flex flex-row justify-between items-center pb-2 flex-wrap'>
                    <h3 className='text-md text-dark-500 font-semibold dark:text-dark-400'>{title}</h3>
                    <p className='text-xs mr-4'><Time label={false} time={DateTime.fromISO(createdAt).toMillis()}/></p>
                </div>
                <p className='text-sm dark:text-dark-400 text-left'>{content}</p>
            </div>
        </div>

        <div>
            <button onClick={close} className="notification__close-btn dark:text-dark-300 pt-0">
                x
            </button>
        </div>
    </div>
  );
};
