import React, { useState, useEffect } from 'react';
import { Checkbox } from '../atoms';
import Popover from '../atoms/popover/popover';
import { ChevronDown, Filter2 } from 'react-iconly';

const checkboxes = [
  { name: 'Completed', value: 'completed' },
  { name: 'Pending', value: 'pending' },
  { name: 'Processing', value: 'processing' },
  { name: 'Running', value: 'running' },
  { name: 'Paused', value: 'paused' },
  { name: 'Rejected', value: 'rejected' },
  { name: 'Draft', value: 'draft' },
];

const campaignTypeOptions = [
  { name: 'Bulk Buying', value: 'Bulk Buying' },
  { name: 'Hyper Local', value: 'Hyper Local' },
];

type IPropsCategoryMenu = {
  filters: string[];
  setFilters: (filter: string[]) => void;
};

const CategoryMenu = ({ filters, setFilters }: IPropsCategoryMenu) => {
  const isChecked = (value: string): boolean => filters.includes(value);

  return (
    <ul className="popover-cart-menu-items">
      {checkboxes.map((el, index) => (
        <li key={index}>
          <Checkbox
            name="statusFilter"
            defaultChecked={isChecked(el.value)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const updatedFilters = event.target.checked
                ? [...filters, el.value]
                : filters.filter((filter) => filter !== el.value);
              setFilters(updatedFilters);
            }}
            labelColor="dark"
            label={el.name}
          />
        </li>
      ))}
    </ul>
  );
};

const CampaignTypeMenu = ({ filters, setFilters }: IPropsCategoryMenu) => {
  const isChecked = (value: string): boolean => filters.includes(value);

  return (
    <ul className="popover-cart-menu-items">
      {campaignTypeOptions.map((el, index) => (
        <li key={index}>
          <Checkbox
            name="campaignTypeFilter"
            defaultChecked={isChecked(el.value)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const updatedFilters = event.target.checked
                ? [...filters, el.value]
                : filters.filter((filter) => filter !== el.value);
              setFilters(updatedFilters);
            }}
            labelColor="dark"
            label={el.name}
          />
        </li>
      ))}
    </ul>
  );
};

type IProps = {
  onSearch: (filters: string[], campaignTypes: string[]) => void;
  initialStatuses?: string[];
  initialCampaignTypes?: string[];
};

const FilterCampaignList: React.FC<IProps> = ({
  onSearch,
  initialStatuses = [],
  initialCampaignTypes = [],
}) => {
  const [filters, setFilters] = useState<string[]>(initialStatuses);
  const [campaignTypeFilters, setCampaignTypeFilters] = useState<string[]>(initialCampaignTypes);

  useEffect(() => {
    onSearch(filters, campaignTypeFilters);
  }, [filters, campaignTypeFilters]);

  return (
    <div className="flex flex-row items-center gap-8">
      {/* Status Filter */}
      <Popover
        className="rightbottom w-6/12"
        handler={
          <>
            <div className="input-simulate hidden md:flex items-center justify-between text-body dark:text-dark-300 flex-1">
              <label className="cursor-pointer dark:text-dark-400">
                Statuses:
                <span className="mr-2 text-primary-500 text-bold capitalize-fl">
                  &nbsp;
                  {filters.length <= 1 ? filters[0] || 'All Statuses' : `${filters.length} Statuses`}
                </span>
              </label>
              <ChevronDown set="light" size="small" />
            </div>
            <div className="box-content px-4 py-3 border dark:border-dark-400 md:hidden text-body dark:text-dark-400 rounded-3xl">
              <Filter2 set="light" />
            </div>
          </>
        }
        content={
          <CategoryMenu
            filters={filters}
            setFilters={(updatedFilters) => setFilters(updatedFilters)}
          />
        }
      />

      {/* Campaign Type Filter */}
      <Popover
        className="rightbottom w-6/12"
        handler={
          <>
            <div className="input-simulate hidden md:flex items-center justify-between text-body dark:text-dark-300 flex-1">
              <label className="cursor-pointer dark:text-dark-400">
                Campaign Type:
                <span className="mr-2 text-primary-500 text-bold capitalize-fl">
                  &nbsp;
                  {campaignTypeFilters.length <= 1
                    ? campaignTypeFilters[0] || 'All Types'
                    : `${campaignTypeFilters.length} Types`}
                </span>
              </label>
              <ChevronDown set="light" size="small" />
            </div>
            <div className="box-content px-4 py-3 border dark:border-dark-400 md:hidden text-body dark:text-dark-400 rounded-3xl">
              <Filter2 set="light" />
            </div>
          </>
        }
        content={
          <CampaignTypeMenu
            filters={campaignTypeFilters}
            setFilters={(updatedFilters) => setCampaignTypeFilters(updatedFilters)}
          />
        }
      />
    </div>
  );
};

export default FilterCampaignList;
