import React from 'react'

type Props = {
  title: string
  link?: string
  children?: any
  variant?: 'link' | 'button'
  isActive?: boolean
}

const Tab: React.FC<Props> = ({ children }) => {
  return <div>{children}</div>
}

export default Tab
