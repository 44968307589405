// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import LogRocket from 'logrocket';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://751687850b664a54863c3d5223aa5724@o251022.ingest.sentry.io/6133579',
  // Adjust this value in production, or use tracesSampler for greater control
  beforeSend(event) {
    const logRocketSession = LogRocket.sessionURL;
    if (logRocketSession !== null) {
      if (event && event.extra) event.extra['LogRocket'] = logRocketSession;
      return event;
    } else {
      return event;
    }
  },
  enableTracing: process.env.NODE_ENV !== 'development',
  tracesSampleRate: 0.5,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
