import { useEffect, useState } from 'react';
import { CAMPAIGNS } from 'api';
import ClassicCard from '../atoms/ClassicCard';

const CampaignSpend = ({
  campaignId,
  budget,
  campaignType,
}: {
  campaignId: string;
  budget: string;
  campaignType?: string;
}) => {
  const [percentage, setPercentage] = useState(-1);

  const getPercentage = async () => {
    const p =
      campaignType && campaignType === 'group'
        ? await CAMPAIGNS.getSpendByGroup(`${campaignId}`)
        : await CAMPAIGNS.getSpend(`${campaignId}`);
    if (!isNaN(p)) {
      setPercentage(p > 100 ? 100 : p);
    }
  };
  useEffect(() => {
    getPercentage().catch(err => console.log(err));
  }, []);

  if (percentage === -1) {
    return <></>;
  }
  return (
    <ClassicCard otherClasses="px-7  py-6  shadow-card">
      <div>
        <div className="">
          <h3 className="pb-4 text-lg font-bold tracking-tighter dark:text-dark-400">Budget usage</h3>
          <p className="pb-4 tracking-tighter dark:text-dark-400">
            In this section, we provide a transparent and concise overview of our campaign's budget allocation on the
            digital billboard. Unveil the strategic distribution of funds, showcasing how each investment was optimally
            utilized to maximize reach, engagement, and overall impact.
          </p>
        </div>
        <div className="text-center w-full leading-tight space-y-4">
          <h2 className=" text-3xl font-bold tracking-tighter dark:text-[#FDA53F]">{percentage}% usage</h2>
          <p className="pb-4 tracking-tighter dark:text-dark-400">from total budget of {budget}</p>
        </div>

        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded dark:bg-white/30 bg-black/10">
          <div
            style={{ width: `${percentage}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap rounded text-white justify-center bg-[#fda53f]"
          ></div>
        </div>
        {/* <div className="flex items-center space-x-4 rounded px-4 py-6 bg-white/5">
          <div className="flex items-center justify-center w-10 h-10 bg-yellow-500 bg-opacity-25 rounded-full flex-shrink-0">
            <svg width="4" height="17" viewBox="0 0 4 17" fill="nozne" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.44987 9.55156C3.44987 10.3549 2.79487 11.0099 1.99154 11.0099C1.1882 11.0099 0.533203 10.3549 0.533203 9.55156V2.1849C0.533203 1.38156 1.1882 0.726562 1.99154 0.726562C2.79487 0.726562 3.44987 1.38156 3.44987 2.1849V9.55156ZM0.541536 14.8396C0.541536 14.0362 1.1932 13.3812 1.99154 13.3812C2.8132 13.3812 3.46654 14.0362 3.46654 14.8396C3.46654 15.6429 2.8132 16.2979 2.0082 16.2979C1.19987 16.2979 0.541536 15.6429 0.541536 14.8396Z"
                fill="#FDA53F"
              />
            </svg>
          </div>
          <div>
            <h3 className="text-base font-bold text-dark-500 dark:text-dark-400">Budget Oversight Ahead</h3>
            <div className="pt-1 text-sm font-medium dark:text-dark-300">
              Attention! Proceed with care as you delve into the intricacies of our campaign's budget usage on the
              digital billboard. This section unravels the strategic choices made, but also reveals potential pitfalls
              and lessons learned.
            </div>
          </div>
        </div> */}
      </div>
    </ClassicCard>
  );
};
export default CampaignSpend;
