import React, { useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { IMediaUpload } from 'interface/IMedia';

const DropContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const DropContent = styled.div`
  width: 100%;
  height: 100%;
`;

const DropOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  pointer-events: none;
`;

const Dropzone = (props: any) => {
  const [isOver, setIsOver] = useState<boolean>(false);
  const { dropRef, onMediaDrop, disabled, contentType } = props;

  const acceptedFormatsVideo = ['video/mp4', 'video/webm', 'image/jpeg', 'image/png'];

  const acceptedFormatsImage = ['image/jpeg', 'image/png'];
  const onDragOver = (ev: any) => {
    ev.preventDefault();
    if (disabled) return;

    setIsOver(true);
  };

  const onDragLeave = () => {
    if (disabled) return;

    setIsOver(false);
  };

  const onDrop = (ev: any) => {
    ev.preventDefault();
    if (disabled) return;
    setIsOver(false);
    const files = ev.dataTransfer.files;
    processFiles(files);
  };

  const onMediaChange = (ev: any) => {
    const files = ev.target.files as FileList;
    setIsOver(false);
    processFiles(files);
  };

  const processFiles = (files: FileList) => {
    const rejected: string[] = [];
    const accepted: File[] = [];

    for (let i = 0; i < files.length; i++) {
      const acceptedFormats = contentType === 'video' ? acceptedFormatsVideo : acceptedFormatsImage;
      if (acceptedFormats.includes(files[i].type)) {
        accepted.push(files[i]);
      } else {
        rejected.push('a');
      }
    }

    const acceptedFinal = accepted.map((el: File) => {
      const type = el.name.split('.');
      const obj: IMediaUpload = {
        uid: uuidv4(),
        type: type[type.length - 1],
        oldName: el.name,
        oldType: el.type,
        hash: `${el.name}_${el.lastModified}_${el.size}`,
        rules: [],
        file: el,
        location: '',
        action: 'toupload',
      };
      obj.type = 'toupload';
      obj.file = el;

      return obj;
    });
    onMediaDrop(acceptedFinal, rejected);
    dropRef.current.value = '';
  };

  return (
    <div
      className={`drop-container  ${isOver ? 'is-over' : ''}`}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <input
        style={{ display: 'none' }}
        ref={dropRef}
        type="file"
        accept="image/jpeg, image/png, video/mp4"
        multiple
        onChange={onMediaChange}
      />
      {props.children}
    </div>
  );
};
export default Dropzone;
