import React from 'react';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import { Icon } from '@iconify/react-with-api';
import { Button } from '../atoms';

type IProps = {
  mainPage: string;
  links: string[];
  hasNext?: boolean;
  back?: string;
  next?: string;
  backFn?: () => void;
  nextFn?: () => void;
  invalidConditions?: () => void;
  continueBtnLabel?: string;
};

const FooterMobile: React.FC<IProps> = ({
  mainPage,
  links = [],
  back,
  next,
  backFn,
  nextFn,
  hasNext,
  invalidConditions,
  continueBtnLabel,
}: IProps) => {
  return (
    <div className="z-50 fixed bottom-0 right-2 p-5 pb-10 md:px-5 md:py-4 rounded-tl-xl rounded-bl-xl bg-white dark:bg-dark-200 bg-opacity-50 shadow-card backdrop-blur-xl">
      <div className="flex space-x-7">
        {back && (
          <Button color="secondary" onClick={backFn}>
            <FormattedMessage id="Button.Back" />
          </Button>
        )}
        {next && (
          <div data-tip data-for="footer-tooltip" id="footer">
            {!hasNext && <div className="absolute excl-mark-footer cursor-pointer pulse-disclaimer">?</div>}
            {hasNext && (
              <div className="absolute valid-mark-footer cursor-pointer pulse-valid-icon">
                <Icon className="inline font-weight-bold" height="1rem" width="1rem" icon="bi:check-lg" />
              </div>
            )}
            <Button color="primary" onClick={hasNext ? nextFn : invalidConditions} disabled={!hasNext}>
              {continueBtnLabel ? continueBtnLabel : <FormattedMessage id="Button.Next" />}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
FooterMobile.defaultProps = {
  mainPage: 'Dashboard',
  links: [],
};
export default FooterMobile;
