export type ActionTypes = 'toggle-mobile-menu' | 'show-toast'

interface IToggleMobileMenu {
  type: 'toggle-mobile-menu'
}

interface IShowToast {
  type: 'show-toast'
  variant: string
  message: string
}

export type AppAction = IToggleMobileMenu | IShowToast

export const toggleMobileMenu = (): AppAction => ({
  type: 'toggle-mobile-menu'
})

export const ShowToast = (message: string, variant = 'success'): AppAction => ({
  type: 'show-toast',
  message,
  variant
})

export type AppState = {
  isMobileMenuOpen: boolean
}

export const appInitialState: AppState = {
  isMobileMenuOpen: false
}

const AppReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case 'toggle-mobile-menu':
      return {
        ...state,
        isMobileMenuOpen: !state.isMobileMenuOpen
      }
    default:
      return state
  }
}

export default AppReducer
