import React from 'react'
import { Icon } from '@iconify/react-with-api'
import { oneLine } from 'common-tags'

type LabelColor = 'white' | 'dark'

interface ICheckboxProps extends React.HTMLProps<HTMLInputElement> {
  innerRef?: any
  label?: string
  disabled?: boolean
  hint?: string
  color?: LabelColor
  id: string
  name: string
  isLabelBold?: boolean
  validation?: any
}
const Checkbox: React.FC<ICheckboxProps> = ({
  innerRef,
  label,
  disabled,
  validation,
  hint,
  id,
  name,
  isLabelBold,
  color,
  ...otherProps
}: ICheckboxProps) => {
  const [innerError, setInnerError] = React.useState('')
  const { error } = validation
  React.useEffect(() => {
    if (error) {
      setInnerError(error.message)
    } else {
      setTimeout(() => {
        setInnerError('')
      }, 300)
    }
  }, [error])

  const inputErrorClasses = oneLine`
  text-danger-500
`
  const inputClasses = oneLine`
  inline
  ${innerError ? inputErrorClasses : ''}
  ${disabled ? 'bg-gray-200' : ''}
`

  return (
    <div className="w-fit">
      <div className="flex items-center">
        {label && <div className="toggle-label">{label}</div>}
        <div className={oneLine`toggle-checkbox ${color === 'white' ? 'is-white' : 'is-dark'}`}>
          <input ref={innerRef} type="checkbox" name="toggle" id={id} />
          <label htmlFor={id} className={oneLine``}></label>
        </div>
      </div>
      {error && (
        <div className={oneLine`text-danger-500 w-fit text-xs pt-2 space-x-1 collapsible-error ${error ? 'expanded' : ''}`}>
          {/* <Icon className="inline icon-vertical-fix" height="1rem" width="1rem" icon="ant-design:exclamation-circle-outlined" /> */}
          <p className="inline">{innerError}</p>
        </div>
      )}
    </div>
  )
}

Checkbox.defaultProps = {
  disabled: false,
  validation: {},
  readOnly: false
}
export default Checkbox
