import React from 'react'

interface IContentProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode
}
const Content: React.FC<IContentProps> = ({ children }: IContentProps) => {
  return (
    <>
      {/* <div> */}
      {children}
      {/* </div> */}
    </>
  )
}

export default Content
