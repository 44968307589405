import React, { useContext } from 'react';
interface IColor {
  light: string;
  dark: string;
}

const getColor = (type: string): IColor => {
  switch (type) {
    case 'indoor':
      return {
        light: '#00BCD4',
        dark: '#00767F',
      };

    case 'outdoor':
      return {
        light: '#8BC34A',
        dark: '#4B6628',
      };
    case 'mobile':
      return {
        light: '#FF9800',
        dark: '#B26500',
      };
  }
  return {
    light: '#f64747',
    dark: '#f64747',
  };
};

const Pin = (props: any) => {
  const { type = 'mobile', isSelected = false } = props;
  const color = getColor(type);
  return (
    <svg
      height={props.height ? `${props.height}px` : '45px'}
      width={props.width ? `${props.width}px` : '63px'}
      viewBox="0 0 45 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6613 3.17741C18.489 -0.789767 26.8813 -0.720428 33.6449 3.35905C40.3421 7.52161 44.4124 14.9506 44.3745 22.942C44.2186 30.8811 39.854 38.3438 34.3983 44.1128C31.2495 47.4575 27.7269 50.4151 23.9026 52.9251C23.5088 53.1529 23.0773 53.3053 22.6296 53.375C22.1987 53.3567 21.7791 53.2293 21.4086 53.0045C15.5701 49.233 10.4479 44.4189 6.28853 38.7936C2.80809 34.098 0.830733 28.425 0.625004 22.5451C0.620482 14.5383 4.83364 7.14459 11.6613 3.17741ZM15.6068 25.8581C16.7553 28.6895 19.4662 30.5364 22.4738 30.5364C24.4441 30.5506 26.3381 29.7613 27.7338 28.3446C29.1295 26.9279 29.9109 25.0014 29.9039 22.9943C29.9144 19.9307 28.1108 17.1629 25.3352 15.983C22.5596 14.8032 19.3595 15.4442 17.2291 17.6067C15.0987 19.7692 14.4583 23.0266 15.6068 25.8581Z"
        style={{ fill: color.light, stroke: color.dark }}
      />
      <ellipse
        opacity="0.4"
        cx="22.5"
        cy="59.625"
        rx="15.625"
        ry="3.125"
        style={{ fill: color.light, stroke: color.dark }}
      />
    </svg>
  );
};
export default Pin;
