import ClassicCard from './ClassicCard';
/* @ts-ignore */
import { Iconly } from 'react-iconly';

type IProps = {
  title: string;
  value: any;
  before?: string;
  after?: string;
  fill?: boolean;
  icon?: string;
  ltr?: boolean;
  otherClass?: string;
};

const LocationWidget = ({ title, value, before, after, fill, icon, ltr, otherClass }: IProps) => {
  return (
    <ClassicCard
      otherClasses={`px-5 py-4 shadow-card ${fill ? 'bg-gradient-red dark:bg-gradient-red-dark dark:border-2 dark:border-primarydark-600' : ''}  ${otherClass ? otherClass : ''}`}
    >
      <div className={`flex items-center  ${ltr ? 'flex-row-reverse justify-end' : 'justify-between'}`}>
        <div className="flex flex-col justify-center">
          <p className={`text-lg md:text-sm ${fill ? 'text-bordercolor' : 'text-lightGrey dark:text-dark-400'}`}>{title}</p>
          <h4 className={`flex items-center text-xl ${fill ? 'text-white' : 'text-dark-default dark:text-white'}`}>
            {before && <div className="font-thin">{before}</div>}
            <strong className=" font-semibold md:font-bold">{value}</strong>
            {after && <span className="text-lg md:text-sm ml-1">{after}</span>}
          </h4>
        </div>
        {(icon && (
          <div
            className={`hidden md:flex items-center px-5 h-16 ${fill ? 'text-white dark:text-dark-400 dark:bg-grey-200 dark:bg-opacity-20 dark:rounded-3xl' : 'bg-grey-200 text-yellow-default dark:text-dark-400 bg-opacity-20 rounded-3xl py-5'
              } ${ltr ? 'mr-3' : ''}`}
          >
            <Iconly
              name={icon}
              set="curved"
              size={fill ? 'large' : 'medium'}
            />
          </div>
        )) ||
          ''}
      </div>
    </ClassicCard>
  );
};
export default LocationWidget;
