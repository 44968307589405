import nookies, { parseCookies, setCookie, destroyCookie } from 'nookies';
import { NextPage, NextPageContext } from 'next';
import { ILoginResponse, IUser } from 'interface';
import jwt_decode from 'jwt-decode';
import { DateTime } from 'luxon';
import axios from 'axios';
const maxAge = 30 * 24 * 60 * 60;

const basePath = process.env.NEXT_PUBLIC_API_BASEPATH;

export const checkUser = async (ctx: NextPageContext): Promise<IUser | null> => {
  const cookies = nookies.get(ctx);
  let url = '';
  if (ctx?.req?.url) {
    url = ctx.req.url;
  }

  if (!cookies.user) {
    if (!url.includes('auth')) {
      ctx?.res?.setHeader('Location', '/auth/login');
      if (ctx?.res?.statusCode) ctx.res.statusCode = 302;
      ctx?.res?.end();
    }
    return null;
  }
  if (cookies.jwt) {
    const jwtDecode: any = jwt_decode(cookies.jwt);

    if (jwtDecode.emailValidation === false) {
      if (url !== '/auth/validate-email' && !url.includes('/auth/validate-email?id=') && !url.includes('auth')) {
        ctx?.res?.setHeader('Location', '/auth/validate-email');
        if (ctx?.res?.statusCode) ctx.res.statusCode = 302;
        ctx?.res?.end();
      }
      return null;
    }
    let l: IUser = {
      id: jwtDecode.sub,
      hasCompany: '1',
      exp: jwtDecode.exp,
      firstName: jwtDecode.firstName,
      lastName: jwtDecode.lastName,
      emailValidation: jwtDecode.emailValidation,
      avatar: jwtDecode.avatar,
      role: jwtDecode.role || 'user',
      email: jwtDecode.email || '-',
      campaign_prefix: jwtDecode.campaign_prefix || null,
      firstLogin: jwtDecode?.firstLogin || null,
      extra: jwtDecode?.extra || null
    };
    return l;
  } else return null;
};

export const refreshAuth = async (
  email: string,
  refreshToken: string,
  ctx: NextPageContext | null
): Promise<string> => {
  //const basePath = process.env.NEXT_PUBLIC_API_BASEPATH ?? 'http://localhost:8000/v1'

  try {
    const response = await axios.post(`${basePath}/auth/refresh-token?v=v5`, {
      email,
      refreshToken,
    });
    nookies.set(null, 'jwt', response.data.data.token.accessToken, {
      maxAge,
      path: '/',
    });
    nookies.set(null, 'refreshToken', response.data.data.token.refreshToken, {
      maxAge,
      path: '/',
    });
    return response.data.data.token.accessToken;
  } catch (error) {
    nookies.destroy(undefined, 'token');
    nookies.destroy(undefined, 'user');
    ctx?.res?.setHeader('Location', '/auth/login');
    if (ctx?.res?.statusCode) ctx.res.statusCode = 302;
    ctx?.res?.end();
    return '';
  }
};

export const getUser = async (ctx: NextPageContext | null): Promise<string | null> => {
  const cookies = ctx ? nookies.get(ctx) : parseCookies(null, '/');
  if (cookies.jwt) {
    let decodedToken: any = jwt_decode(cookies.jwt);
    const now = DateTime.local();
    const expirationDate = DateTime.fromSeconds(decodedToken.exp);
    const diff = expirationDate.diff(now, ['seconds']);
    if (diff.seconds < 30) {
      return await refreshAuth(decodedToken.email, cookies.refreshToken, ctx);
    } else {
      return cookies.jwt;
    }
  } else return null;
};

export const googleLoginUser = async (
  users: { email: string; name: string; ip: string; refid?: string },
  ctx: NextPageContext | null
): Promise<ILoginResponse | null> => {
  try {
    const response = await axios.post(`${basePath}/auth/googlelogin`, users);

    const r: ILoginResponse = {
      hasError: false,
      errorMessage: '',
      accessToken: response.data.data.token.accessToken,
      expiresIn: response.data.data.token.accessToken,
      refreshToken: response.data.data.token.refreshToken,
    };
    return response.data.data.token;
  } catch (error) {
    return null;
  }
};

export const logout = async (ctx?: NextPageContext): Promise<void> => {
  const cookies = nookies.get(ctx);
  let url = '';
  if (ctx?.req?.url) {
    url = ctx.req.url;
  }

  nookies.destroy(ctx, 'user', {
    path: '/',
  });
  nookies.destroy(ctx, 'next-auth.session-token', {
    path: '/',
  });

  nookies.destroy(ctx, 'refreshToken', {
    path: '/',
  });
  nookies.destroy(ctx, 'jwt', {
    path: '/',
  });
};
