import { oneLine } from 'common-tags';
import { useRef, useEffect, useState, ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: ReactNode;
  otherClass?: string;
}

export const Portal = ({ children, otherClass }: PortalProps) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>('#portal');
    setMounted(true);
  }, []);

  return mounted && ref.current
    ? createPortal(
        <>
          <div
            className={oneLine`fixed z-[999]  flex justify-center items-center  inset-0 bg-gray-800 bg-opacity-70 w-full h-full light-box-container`}
          >
            <div
              className={oneLine`preview-modal ${
                otherClass || 'w-11/12'
              } flex flex-col bg-white dark:bg-dark-200 overflow-y-scroll lg:overflow-x-hidden items-center rounded-2xl`}
            >
              {children}
            </div>
          </div>
        </>,
        ref.current
      )
    : null;
};
