import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { useApp } from 'components/app';
import { TPSAPI } from 'api';
import { Button, Input } from '../atoms';
import { Dialog } from '../molecules/index';

type IProps = {
  labelName: string;
  isPercentage: boolean;
  campaignValue?: number;
  onComplete: (percent: number, voucherCode: string, voucherType?: string) => void;
  clearErrorsTrigger?: number;
};
const AddVoucher: React.FC<IProps> = ({ isPercentage, labelName, onComplete, campaignValue, clearErrorsTrigger }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const intl = useIntl();
  const { notify } = useApp();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogDescription, setDialogDescription] = useState<string>('');
  const [voucherConvert, setVoucherConvert] = useState<string>('');
  const [voucherCode, setVoucherCode] = useState<string>('');

  useEffect(() => {
    // @ts-ignore
    clearErrors(['voucher']);
    return () => {};
  }, [clearErrorsTrigger]);

  const validationSchema = Yup.object().shape({
    voucher: Yup.string()
      .min(
        6,
        intl.formatMessage(
          {
            defaultMessage: 'The field needs to be at least {min} characters long',
            id: 'Error.minimumLength',
          },
          { min: '6' }
        )
      )
      .required(
        intl.formatMessage({
          defaultMessage: `${isPercentage ? 'Code is mandatory if you want to apply discount' : 'Code is mandatory if you want to apply voucher'}`,
          id: 'Error.mandatoryFieldd',
        })
      ),
  });

  const useYupValidationResolver = (schema: any) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await schema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner?.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [schema]
    );

  const resolver = useYupValidationResolver(validationSchema);
  const { register, handleSubmit,  formState , control, clearErrors } = useForm({ resolver });

  const onSubmit = async (data: any) => {
    setLoading(true);
    let response;
    if (isPercentage) {
      response = await TPSAPI.addPercentageVoucher(data.voucher, campaignValue);
    } else {
      response = await TPSAPI.addVoucher(data.voucher);
    }

    if (response.price && response.priceOld) {
      setVoucherConvert(response.voucher);
      setDialogTitle('Currency');
      setDialogDescription(
        `Your ${response.priceOld} voucher will be converted to ${response.price}.`
      );
      setDialogOpen(true);
    }

    notify(response.message, response.code);
    setVoucherCode(response.voucherCode);
    onComplete(response.value ? response.value : 0, response.voucherCode, response.type);
    setLoading(false);
  };

  const { dirtyFields, errors } = formState;
  const getValidation = (inputName: any) => {
    const val = {
      isDirty: (dirtyFields as any)[inputName],
      error: (errors as any)[inputName],
    };
    return val;
  };

  const convertVoucherValue = async () => {
    setLoading(true);
    let response = await TPSAPI.confirmConvertVoucherCurrency(voucherConvert);
    notify(response.message, response.code);
    onComplete(0, voucherConvert);
    setDialogOpen(false);
    setLoading(false);
  }

  const primaryButton = {
    buttonText: 'Convert',
    action: () => {
      convertVoucherValue();
    },
  };

  const secondaryButton = {
    buttonText: 'Cancel',
    action: () => {
      setDialogOpen(false);
    },
  };

  const onRemoveVoucher = () => {
    onComplete(0, '');
    setVoucherCode('');
    notify('Discount voucher removed!', 'success');
  }

  return (
    <div className="w-full">
      {(dialogOpen && (
        <Dialog
          description={dialogDescription}
          title={dialogTitle}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
        />
      )) ??
        null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full">
          <div className="flex flex-wrap">
            <div className={`flex md:pr-7 ${isPercentage ? 'w-full md:w-6/12' : 'w-7/12'}`}>
              <Input
                name="voucher"
                innerRef={register}
                validation={getValidation('voucher')}
                type="text"
                label={labelName}
                fullWidth={true}
                defaultValue={voucherCode}
                // disabled={isPercentage && voucherCode ? true : false}
              />
            </div>
            <div className={`${isPercentage ? 'w-full md:w-6/12' : 'w-5/12'}`}>
              <div className={`mt-2 mb-7 flex row items-center ${isPercentage ? 'w-full justify-center' : 'justify-end'}`}>
                {
                  isPercentage && voucherCode && (
                    <Button
                      onClick={() => { onRemoveVoucher() }}
                      disabled={voucherCode ? false : true}
                      className='mr-5 w-6/12' loading={loading} color="primary" fill="outline"
                    >
                      {intl.formatMessage({
                        defaultMessage: 'Remove',
                        id: 'Text.Remove',
                      })}
                    </Button>
                  )
                }
                <Button type="submit" className={`${isPercentage && voucherCode ? 'w-6/12' : 'w-10/12'}`} loading={loading} color="secondary" fill="outline">
                  {intl.formatMessage({
                    defaultMessage: 'Validate',
                    id: 'Button.Validate',
                  })}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddVoucher;
