import React from 'react';
import { Icon } from '@iconify/react-with-api';
// @ts-ignore
import { Iconly } from 'react-iconly';
import { oneLine } from 'common-tags';
import zxcvbn from 'zxcvbn';

interface IInputProps extends React.HTMLProps<HTMLInputElement> {
  children?: React.ReactNode;
  name: string;
  label: string;
  innerRef?: any;
  type?: string;
  disabled?: boolean;
  hint?: string;
  startIcon?: string;
  startText?: string;
  endText?: string;
  borderLight?: boolean;
  endIcon?: React.ReactNode;
  placeholder?: string;
  iconSize?: string;
  validation?: any;
  fullWidth?: boolean;
  readOnly?: boolean;
  defaultValue?: string;
  inputValue?: string;
  showStrength?: boolean;
  naked?: boolean;
  parentRef?: any;
}

const Input: React.FC<IInputProps> = ({
  children,
  name,
  disabled,
  hint,
  innerRef,
  endText,
  startIcon,
  borderLight,
  startText,
  iconSize,
  label = 'default label',
  placeholder,
  endIcon,
  fullWidth,
  validation,
  readOnly,
  showStrength,
  className,
  inputValue,
  type = 'text',
  naked = false,
  parentRef = null,
  ...otherProps
}: IInputProps) => {
  const [innerError, setInnerError] = React.useState('');
  const [inputType, setInputType] = React.useState(type);
  const [strength, setStrength] = React.useState<number>(0);
  const [passwordLength, setPasswordLength] = React.useState<number>(0);
  const { error } = validation;
  React.useEffect(() => {
    if (error) {
      setInnerError(error.message);
    } else {
      setTimeout(() => {
        setInnerError('');
      }, 300);
    }
  }, [error]);

  const inputErrorClasses = oneLine`
    border-danger-500
    hover:border-danger-700
    focus:ring-0
    focus:border-danger-700
  `;

  const inputClasses = oneLine`
    bg-transparent
    outline-none
    text-dark-default
    dark:text-white
    font-semibold
    w-full
    px-4 py-2
    border-0
    text-lg md:text-base
    border-t-0 border-l-0 border-r-0 border-b-2
    animated
    focus:ring-0
    dark:border-bordercolordark
    hover:border-primary-500
    dark:hover:border-primary-500
    focus:border-primary-500
    dark:focus:border-primary-500
    ${borderLight ? 'border-whiteish' : 'border-grey-100 dark:border-grey-default dark:border-opacity-40'}
    ${placeholder ? ' dark:placeholder-dark-300 font-normal' : 'placeholder-transparent'}
    ${innerError ? inputErrorClasses : ''}
    ${disabled ? 'bg-gray-200 pointer-events-none' : ''}
    ${className ? className : ''}
    ${startIcon ? 'pl-12' : ''}
    ${startText ? 'pl-12' : ''}
    ${endText ? 'pr-12' : ''}
    
    ${type === 'password' ? 'pr-12' : ''}
  `;

  const inputClassesNaked = oneLine`
    bg-transparent
    text-lg md:text-base
    w-full
    px-4 
    border-0
    animated
    outline-none
    text-black
    focus:ring-offset-0
    focus:ring-primary-500
    focus:ring-opacity-30
    focus:border-primary-500
    border  rounded 
  `;

  const labelClasses = oneLine`
    text-base md:text-sm
    text-body
    dark:text-dark-400
    absolute
    animated origin-0
    bottom-3.5 
    pointer-events-none
    ${startIcon ? 'left-12' : 'left-3.5'}
    ${startText ? 'left-12' : 'left-3.5'}
    ${endText ? 'right-12' : 'right-3.5'}
    
    ${innerError ? 'text-danger-500' : ''}
  `;

  const errorIconClasses = oneLine`
    inline icon-vertical-fix text-danger-500
    absolute right-4
    top-1/2 transform -translate-y-1/2
  `;

  const iconClasses = oneLine`
  inline icon-vertical-fix text-lightGrey dark:text-dark-400 pb-1
  absolute 
  top-1/2 transform -translate-y-1/2
`;

  const wrapperClasses = oneLine`
    relative
    ${fullWidth ? 'w-full' : ''}
    ${innerError ? 'text-danger-500' : ''}
    ${startIcon ? 'with-icon' : ''}
    ${startText ? 'with-icon' : ''}
    ${endText ? 'with-icon' : ''}
    
  `;

  return (
    <div className={`relative z-0 inline-block text-display w-full`}>
      <div className={label !== '' ? 'mb-7 mt-6' : ''}>
        <div ref={parentRef} className={wrapperClasses}>
          <input
            ref={innerRef}
            placeholder={placeholder || label}
            autoComplete="off"
            name={name}
            disabled={disabled}
            className={naked ? inputClassesNaked : inputClasses}
            type={inputType}
            value={inputValue}
            onChange={el => {
              setStrength(zxcvbn(el.target.value).score);
              setPasswordLength(el.target.value.length);
            }}
            {...otherProps}
          />
          <label className={labelClasses}>{label}</label>

          {/* {innerError && <Icon className={errorIconClasses} height="1.5rem" width="1.5rem" icon="bx-bx-error-circle" />} */}
          {startIcon && (
            <span className={`${iconClasses} pt-px box-content left-4 cursor-pointer`}>
              {' '}
              <Iconly name={startIcon} set="light" size="small" />
            </span>
          )}
          {startText && <span className={`${iconClasses} pt-px box-content left-4 cursor-pointer`}>{startText}</span>}
          {endText && <span className={`${iconClasses} pt-px box-content right-4 cursor-pointer`}>{endText}</span>}
          {type === 'password' && (
            <div
              onClick={() => {
                if (inputType === 'password') setInputType('text');
                else setInputType('password');
              }}
            >
              <span className={`${iconClasses} right-4 cursor-pointer`}>
                <Iconly
                  name={`${inputType === 'password' ? 'Hide' : 'Show'}`}
                  set="light"
                  primaryColor="#858D96"
                  size="medium"
                />
              </span>
            </div>
          )}
        </div>
        {showStrength && passwordLength > 0 && (
          <div className={'strength-meter mt-2'}>
            <div className="strength-meter-fill" data-strength={strength}></div>
          </div>
        )}
        {!naked && (
          <div
            className={`absolute text-danger-500 w-fit text-xs pt-2 pl-3.5 space-x-1 collapsible-error ${
              error ? 'expanded' : 'hidden'
            }`}
          >
            <p className="inline">{innerError}</p>
          </div>
        )}
        {hint && (
          <div className={`text-dark-default dark:text-dark-300 w-fit text-xs pt-2 pl-3.5 space-x-1`}>
            <p className="inline">{hint}</p>
          </div>
        )}
      </div>
    </div>
  );
};

Input.defaultProps = {
  disabled: false,
  validation: {},
  readOnly: false,
  fullWidth: false,
  showStrength: false,
  iconSize: '1rem',
};
export default Input;
