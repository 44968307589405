import React from 'react'
import { oneLine } from 'common-tags'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

interface IInputProps extends React.HTMLProps<HTMLInputElement> {
  children?: React.ReactNode
  name: string
  label: string
  innerRef?: any
  type?: string
  disabled?: boolean
  hint?: string
  startIcon?: string
  endIcon?: React.ReactNode
  placeholder?: string
  iconSize?: string
  validation?: any
  fullWidth?: boolean
  readOnly?: boolean
  defaultValue?: string
  showStrength?: boolean
  setValue: (val: string) => void
}

const InputPhone: React.FC<IInputProps> = ({
  children,
  name,
  disabled,
  hint,
  innerRef,
  startIcon,
  iconSize,
  label = 'default label',
  placeholder,
  endIcon,
  fullWidth,
  validation,
  readOnly,
  showStrength,
  className,
  type = 'text',
  setValue,
  ...otherProps
}: IInputProps) => {
  const [innerError, setInnerError] = React.useState('')
  const { error } = validation
  React.useEffect(() => {
    if (error) {
      setInnerError(error.message)
    } else {
      setTimeout(() => {
        setInnerError('')
      }, 300)
    }
  }, [error])

  const inputErrorClasses = oneLine`
    bg-danger-500 bg-opacity-10
    border-danger-500
    hover:border-danger-700
    focus:ring-danger-500
    focus:border-danger-500
    text-danger-500
  `

  const inputClasses = oneLine`
    
    ${innerError ? inputErrorClasses : ''}
    ${disabled ? 'bg-gray-200' : ''}
    ${className ? className : ''}
  `

  const labelClasses = oneLine`
    absolute
    animated origin-0
    top-2 left-4
    pointer-events-none
    ${innerError ? 'text-danger-500' : ''}
    ${startIcon ? 'right-10' : ''}
  `

  const errorIconClasses = oneLine`
    inline icon-vertical-fix text-danger-500
    absolute right-4
    top-1/2 transform -translate-y-1/2
  `
  const startIconClasses = oneLine`
    inline icon-vertical-fix text-current
    absolute right-4
    top-1/2 transform -translate-y-1/2
  `

  const wrapperClasses = oneLine`
    relative
    phone-input
    mb-7 mt-6
    ${fullWidth ? 'w-full' : ''}
    ${innerError ? 'text-danger-500' : ''}
    ${startIcon ? 'with-icon' : ''}
  `

  return (
    <div className={`relative z-50 text-display ${fullWidth ? 'w-full' : ''}`}>
      <PhoneInput
        onChange={(phone) => {
          setValue(phone)
        }}
        containerClass={wrapperClasses}
        inputClass={inputClasses}
        enableLongNumbers={true}
        country="us"
        preferredCountries={['us', 'ro', 'uk', 'ca', 'de']}
        disableSearchIcon={true}
      />
      <div className={`text-danger-500 w-fit text-xs pt-2 space-x-1 collapsible-error ${error ? 'expanded' : 'hidden'}`}>
        <p className="inline">{innerError}</p>
      </div>
    </div>
  )
}

InputPhone.defaultProps = {
  disabled: false,
  validation: {},
  readOnly: false,
  fullWidth: false,
  showStrength: false,
  iconSize: '1rem'
}
export default InputPhone
