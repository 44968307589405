import React from 'react'
import { DateTime } from 'luxon'
interface ITime {
  time: number,
  label?: boolean
}

const Time: React.FC<ITime> = ({ time, label=true }: ITime) => {
  const fromNow = time ? DateTime.fromMillis(time).toRelative() : DateTime.fromMillis(1).toRelative()
  return <p className="text-xs dark:text-dark-300 font-lightGrey font-medium ">{label ? 'Created' : ''} {fromNow}</p>
}
export default Time
