import React from 'react';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table';
import { StatusTag, CAMPAIGN_STATUS, SelectInput, Button } from 'components/common/atoms';
import { ChevronLeft, ChevronRight } from 'react-iconly';
import ClassicCard from '../atoms/ClassicCard';
import { oneLine } from 'common-tags';
import Popover from '../atoms/popover/popover';
import { TableMobile } from './TableMobile';

export type TableProps = {
  header: any;
  tableData: any;
  initialState?: any;
};

export type StatusPillType = {
  value: CAMPAIGN_STATUS;
};
export function StatusPill({ value }: StatusPillType) {
  return <StatusTag status={value} text={value} />;
}

export const Table = ({ header, tableData, initialState }: TableProps) => {
  const options = {
    initialState: initialState,
    columns: header,
    data: tableData,
  };

  const {
    getTableProps,
    // setPageSize,
    // pageOptions,
    rows,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // page
  } = useTable(options);

  return (
    <>
      <div className="hidden md:block rounded-3xl bg-white dark:bg-dark-200">
        <div className="flex flex-col w-full ">
          <div className=" overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div>
                <table {...getTableProps()} className="min-w-full divide-y divide-gray-200 dark:divide-bordercolordark dark:divide-opacity-20">
                  <thead className="dark:bg-dark-300 dark:bg-opacity-5">
                    {headerGroups.map((headerGroup: any, index: any) => (
                      <tr {...headerGroup.getHeaderGroupProps()} className="py-4" key={index}>
                        {headerGroup.headers.map((column: any, indexcol: any) => (
                          // Add the sorting props to control sorting. For this example
                          // we can add them into the header props
                          <th scope="col" className="px-6 py-5 text-left group" key={indexcol}>
                            <div className="flex items-center justify-between text-sm font-semibold text-lightGrey dark:text-white">
                              {column.render('Header')}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()} className="divide-y divide-gray-200 dark:divide-bordercolordark dark:divide-opacity-20">
                    {rows.map((row: any, i: any) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={i}>
                          {row.cells.map((cell: any, indexCell: any) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="px-6 py-3 whitespace-nowrap dark:text-dark-400"
                                
                                key={indexCell}
                              >
                                {cell.column.Cell.name === 'defaultRenderer' ? (
                                  <div className="text-sm font-medium text-dark-500 dark:text-dark-400">{cell.render('Cell')}</div>
                                ) : (
                                  cell.render('Cell')
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex items-center justify-between w-full px-5 py-2 bg-table rounded-b-3xl">
          <div className="flex items-center space-x-4 ">
            <div className="w-8">
              <Button color="secondary" tag="div" fill="outline" fullWidth className="w-8 h-8 px-0 py-0">
                <ChevronLeft set="light" size="small" />
              </Button>
            </div>
            <div className="flex items-center space-x-3">
              <span className="inline-flex items-center justify-center w-8 h-8 text-white rounded-full bg-dark-100 font-button">
                {1}
              </span>
              <span className="text-lightGrey">|</span>
              <span className="font-medium text-dark-500 font-button">{5}</span>
            </div>

            <div className="w-8">
              <Button color="secondary" tag="div" fill="outline" fullWidth className="w-8 h-8 px-0 py-0">
                <ChevronRight set="light" size="small" />
              </Button>
            </div>
          </div>
          <div className="flex items-center space-x-4 ">
            <div className="flex items-center space-x-3">
              <p className="text-sm font-medium text-body text-md">Rows per page</p>
              <div className="mt-2 ">
                <SelectInput
                  onChange={e => {
                    console.log(e);
                  }}
                  placeholderText="5"
                  options={[
                    { value: '5', label: '5' },
                    { value: '10', label: '10' },
                    { value: '20', label: '20' },
                  ]}
                  isMulti={false}
                  isClearable={false}
                  selName="input 1"
                />
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <TableMobile header={header} tableData={tableData} />
    </>
  );
};
