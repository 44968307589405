import React from 'react';
import { Icon as WidgetIcon } from 'interface/Icons';
// @ts-ignore
import { Iconly } from 'react-iconly';
import { Stack } from 'components/layout';
import numeral from 'numeral';

type IconWidget = {
  items: WidgetIcon[];
};

const IconWidgets: React.FC<IconWidget> = ({ items }: IconWidget) => {
  return (
    <Stack gravity="start" fitX={true} spacing="none" otherClass="flex">
      {items.map((el: WidgetIcon, index: number) => {
        return (
          (el.value && (
            <Stack
              gravity="start"
              spacing="xs"
              align="center"
              key={index}
              otherClass={`pt-3 ${index !== 0 ? 'pl-5' : ''}`}
            >
              <div dangerouslySetInnerHTML={{ __html: el.icon }}></div>
              <div className="ml-2 text-sm font-semibold tracking-tighter text-body dark:text-dark-300">
                {numeral(el.value).format('0,0')}
              </div>
            </Stack>
          )) || <></>
        );
      })}
    </Stack>
  );
};
export default IconWidgets;
