import React, { useContext, useReducer } from 'react'
import { reducer, initialState, ICampaignPublish } from './publish.reducer'

const PublishContext = React.createContext<{
  publishState: ICampaignPublish
  dispatch: React.Dispatch<any>
}>({
  publishState: initialState,
  dispatch: () => null
})

const PublishProvider = ({ children }: any) => {
  const [publishState, dispatch] = useReducer(reducer, initialState)
  return (
    <PublishContext.Provider
      value={{
        publishState: publishState,
        dispatch: dispatch
      }}
    >
      {children}
    </PublishContext.Provider>
  )
}

export { PublishContext, PublishProvider }
