import React, { useEffect } from 'react';
import { Color, Fill } from '../../../utils';
import { oneLine } from 'common-tags';
// @ts-ignore
import { Iconly } from 'react-iconly';

interface IButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset';
  tag?: any;
  disabled?: boolean;
  buttonSize?: 'sm' | 'lg' | 'md';
  children: React.ReactNode;
  fill?: Fill;
  color?: Color;
  fullWidth?: boolean;
  loading?: boolean;
  icon?: string;
  innerRef?: any;
  simulateBtn?: boolean;
  justIcon?: true;
}

type IStyle = {
  [key in Color]: {
    [sad in Fill]: string;
  };
};

const Size = {
  md: 'btn-base',
  lg: 'btn-base large',
  sm: 'btn-base small',
};

const Style: IStyle = {
  primary: {
    full: oneLine`bg-primary-500 dark:border-primary-500 hover:shadow-primaryFullHover focus:shadow-primaryFullHover`,
    outline: oneLine`text-primary-500 bg-white border-primary-500 hover:shadow-primaryOutlineHover focus:shadow-primaryOutlineHover`,
    outlinedark: oneLine`text-primary-500 dark:bg-dark-200 bg-white border-primary-500 hover:shadow-primaryOutlineHover focus:shadow-primaryOutlineHover`,
    none: oneLine`text-primary-500 hover:text-primary-600 focus:text-primary-600 border-0`,
  },
  secondary: {
    full: oneLine`bg-lightGrey dark:bg-dark-500 dark:border-none hover:shadow-secondaryFullHover focus:shadow-secondaryFullHover`,
    outline: oneLine`text-primarydark-default dark:text-primarydark-default bg-white dark:bg-dark-200 dark:border-bordercolorprimary border-bordercolorprimary hover:shadow-secondaryOutlineHover focus:shadow-secondaryOutlineHover`,
    outlinedark: '',
    none: oneLine`text-lightGrey hover:text-grey-300 focus:text-grey-300 border-0`,
  },
  tertiary: {
    full: oneLine`bg-dark-500 dark:bg-dark-400 dark:bg-opacity-30 dark:border-none hover:shadow-tertiaryFullHover focus:shadow-tertiaryFullHover`,
    outline: oneLine`text-dark-500 bg-white border-dark-500 hover:shadow-tertiaryOutlineHover focus:shadow-tertiaryOutlineHover`,
    outlinedark: '',
    none: oneLine`text-dark-500 dark:text-bordercolordark hover:text-dark-600 focus:text-dark-600 border-0`,
  },
  white: {
    full: 'bg-white dark:bg-dark-700 border-0',
    outline: '',
    outlinedark: '',
    none: oneLine`text-white hover:text-white focus:text-white border-0`,
  },
  accent: {
    full: oneLine`bg-accent-500 text-primary-400 hover:bg-accent-300 active:bg-accent-700`,
    outline: oneLine`text-accent-600 bg-transparent border-accent-500 hover:bg-primary-500 hover:bg-contrast-500 active:bg-contrast-400 border-2`,
    outlinedark: '',
    none: oneLine`text-accent-600 hover:bg-contrast-200 active:bg-contrast-300`,
  },
  danger: {
    full: oneLine`bg-danger-500 text-white hover:bg-danger-600 active:bg-danger-700`,
    outline: oneLine`text-danger-500 bg-transparent border-2 border-danger-500 hover:bg-contrast-500 active:bg-contrast-400`,
    outlinedark: '',
    none: oneLine`text-danger-600 hover:bg-contrast-200 active:bg-contrast-300`,
  },
};

const Button: React.FC<IButtonProps> = ({
  children,
  type = 'button',
  tag = 'button',
  buttonSize = 'md',
  fill = 'full',
  color = 'primary',
  fullWidth = false,
  className,
  disabled,
  loading,
  icon,
  innerRef,
  simulateBtn,
  justIcon,
  ...otherProps
}: IButtonProps) => {
  const Tag = tag;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  useEffect(() => {
    if (loading || loading === false) setIsLoading(loading);
  }, [loading]);
  return (
    <Tag
      type={type}
      ref={innerRef}
      disabled={disabled || isLoading}
      className={oneLine`
          flex items-center justify-center
          ${!simulateBtn ? 'font-button  font-bold' : ' font-medium'}
          ${fullWidth ? 'w-full' : 'w-fit'}
          ${Style[color][fill]}
          ${className ? className : ''}
          ${Size[buttonSize]} 
          ${justIcon ? 'just-icon' : ''}
          ${disabled ? 'opacity-30 cursor-default' : ''}
        `}
      {...otherProps}
    >
      {isLoading && (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {children}
      {icon && (
        <span className={buttonSize === 'sm' ? 'ml-1' : 'ml-2'}>
          <Iconly name={icon} set="light" size={buttonSize === 'sm' ? 'small' : 'medium'} />
        </span>
      )}
    </Tag>
  );
};

Button.defaultProps = {
  type: 'button',
  fill: 'full',
  buttonSize: 'md',
  loading: false,
};
export default Button;
