import React from 'react'
import { Icon } from '@iconify/react-with-api'
import { oneLine } from 'common-tags'

type Color = 'primary' | 'accent' | 'warn' | 'danger' | 'success' | 'neutral'
type LabelColor = 'white' | 'dark'

const getColor = (color: Color | undefined) => {
  switch (color) {
    case 'primary':
      return 'checked:bg-primary-500 hover:border-primary-500 text-primary-500 focus:ring-0 focus:ring-offset-0'
    case 'accent':
      return 'checked:bg-accent-500  hover:border-accent-500 text-accent-500  focus:ring-0 focus:ring-offset-0'
    case 'warn':
      return 'checked:bg-warn-500   hover:border-warn-500 text-warn-500 focus:ring-0 focus:ring-offset-0'
    case 'danger':
      return 'checked:bg-danger-500   hover:border-danger-500 text-danger-500 focus:ring-0 focus:ring-offset-0'
    case 'success':
      return 'checked:bg-success-500  hover:border-success-500 text-success-500  focus:ring-0 focus:ring-offset-0'
    case 'neutral':
      return 'checked:bg-neutral-500   hover:border-neutral-500 text-neutral-500  focus:ring-0 focus:ring-offset-0'
    default:
      return 'checked:bg-primary-500   hover:border-primary-500 text-primary-500  focus:ring-0 focus:ring-offset-0'
  }
}

export interface ICheckboxProps extends React.HTMLProps<HTMLInputElement> {
  innerRef?: any
  label?: string
  disabled?: boolean
  hint?: string
  color?: Color
  name: string
  labelColor: LabelColor
  bulletColor?: Color
  isLabelBold?: boolean
  validation?: any
  multiple?: boolean
  value?: any
  checked?: boolean
  justText?: boolean
}
const Checkbox: React.FC<ICheckboxProps> = ({
  innerRef,
  label,
  disabled,
  validation,
  hint,
  name,
  labelColor = 'white',
  bulletColor = undefined,
  isLabelBold,
  color,
  multiple,
  value,
  checked,
  justText,
  ...otherProps
}: ICheckboxProps) => {
  const [innerError, setInnerError] = React.useState('')
  const { error } = validation
  React.useEffect(() => {
    if (error) {
      setInnerError(error.message)
    } else {
      setTimeout(() => {
        setInnerError('')
      }, 300)
    }
  }, [error])

  const inputErrorClasses = oneLine`
  text-danger-500
`
  const inputClasses = oneLine`
  inline
  ${multiple ? 'pb-4' : ''}
  ${innerError ? inputErrorClasses : ''}
  ${disabled ? 'bg-gray-200' : ''}
`

  return (
      <div className="w-fit flex">
        <label className={`${inputClasses} inline-flex items-center`}>
          <input
            ref={innerRef}
            disabled={disabled}
            className={`
                ease-in-out transition duration-300
                animated rounded
                cursor-pointer
                border
                border-grey-200
                w-5
                h-5
                ${justText ? 'hidden checkbox just-text' : ''}
                ${getColor(color)}
                ${disabled ? 'bg-gray-300' : ''}
              `}
            type="checkbox"
            name={name}
            value={value || ''}
            defaultChecked={checked}
            {...otherProps}
          />
          {label && (
            <h6
              className={oneLine` text-sm font-medium ml-3 cursor-pointer ${labelColor === 'white' ? 'text-white' : 'text-dark dark:text-dark-400'} ${justText ? 'transition-all duration-300 ease-in-out border border-bordercolor rounded-2xl px-5 py-2' : ''
                }`}
              dangerouslySetInnerHTML={{ __html: label }}
            ></h6>
          )}
          {bulletColor && (
            <div className="border-bullet flex items-center justify-center border rounded-full ml-4 cursor-pointer" style={{ borderColor: bulletColor }}>
              <div className="center-bullet rounded-full" style={{ backgroundColor: bulletColor }}></div>
            </div>
          )}
          {hint && (
            <div className="text-dark-default dark:text-dark-300 flex mt-1">
              <div className="w-4 h-4" />
              <p className="ml-2 text-xs text-gray-500">{hint}</p>
            </div>
          )}
        </label>
        {error && (
          <div className={`text-danger-500 w-fit text-xs pt-2 space-x-1 collapsible-error ${error ? 'expanded' : ''}`}>
            <Icon className="inline icon-vertical-fix" height="1rem" width="1rem" icon="ant-design:exclamation-circle-outlined" />
            <p className="inline">{innerError}</p>
          </div>
        )}
      </div>
  )
}

Checkbox.defaultProps = {
  disabled: false,
  validation: {},
  readOnly: false
}
export default Checkbox
