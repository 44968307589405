import { oneLine } from 'common-tags';
import { Stack } from 'components/layout';
import { INetflix } from 'interface/ILocation';
import Link from 'next/link';
import React, { useRef } from 'react';
import Slider from 'react-slick';
import { Button } from '../atoms';
import { gtmSelectItem } from 'api/gtm/gtm';

export type Slider3dType = {
  sliderItems: INetflix[];
  otherClasses?: string;
  inOnlyLocation?: boolean;
  category?: string;
};

export const Slider3d = ({ otherClasses, sliderItems, category }: Slider3dType) => {
  const customeSlider = React.useRef<Slider>(null);

  const handleClick = (index: number) => {
    (customeSlider.current as Slider).slickGoTo(index);
  };

  const sliderContent = oneLine`
  absolute z-50
  top-0 left-0
  w-full h-full
  flex flex-col justify-between
  px-4 py-5 min-tablet:px-7 min-tablet:pt-8 min-tablet:pb-6
  ease-in-out transition-opacity duration-300
`;

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider ref={customeSlider} {...settings} className={`three-d pb-10 ${(otherClasses && otherClasses) || ''}`}>
      {sliderItems.map((el, index) => {
        return (
          <div
            key={el.uuid}
            className="img-container h-80 min-tablet:h-52 max-w-2xl w-100 "
            onClick={() => {
              handleClick(index);
            }}
          >
            <img alt="" src={el.photo} className="block object-cover h-full w-full rounded-2xl " />
            <div className={`content-container ${sliderContent}`}>
              <div className="">
                <div className="text-white text-2xl font-bold">{el.name}</div>
                <div className="text-white text-base font-bold">{el.address}</div>
              </div>
              <div className="flex">
                {/* <a href={`publish/basic?screen=${el.uuid}`}>
                  <Button fill="outline" buttonSize="sm">
                    Book <span className="hidden min-tablet:inline-block">&nbsp;this&nbsp;</span> screen
                  </Button>
                </a>
                <a href={`/location/${el.uuid}`}>
                  <Button fill="none" color="white" icon="ArrowRight" buttonSize="sm">
                    See the screen
                  </Button>
                </a> */}
                <Link href={`/location/${el.uuid}`} prefetch={false}>
                  <a>
                    <Button onClick={() => { gtmSelectItem(el, index, category) }} fill="outline" buttonSize="sm">
                      See the screen
                    </Button>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};
