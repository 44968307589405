import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import stripe from 'api/payment/stripe';

import InjectedCheckoutForm from './CheckoutForm';
import { IUserOrganization } from 'interface';
type IProps = {
  user: IUserOrganization;
  onCardSubmitSuccess: () => void;
};
const MyStoreCheckout = ({ user, onCardSubmitSuccess }: IProps) => {
  return (
    <Elements stripe={stripe.getStripe(user)}>
      <div className="w-full py-4">
        <InjectedCheckoutForm user={user} onCardSubmitSuccess={onCardSubmitSuccess} />
      </div>
    </Elements>
  );
};

export default MyStoreCheckout;
