import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import venues from './venue.json';
import { useEffect, useState } from 'react';

export interface Venue {
  title: string;
  id: number;
  description: string;
  children?: Venue[];
  value: string;
  hasStock?: boolean;
  parent?: Venue;
}
type ApiRequest = {
  [key: string]: number;
};

export const useVenues = () => {
  const getVenuById = (id: number) => {
    const treeVenue = getTreeVenues();
    const foundVenue: any[] = [];
    treeVenue.forEach(venue => {
      if (venue.id.toString() === id.toString()) {
        foundVenue.push({
          title: venue.title,
          id: venue.id,
          description: venue.description,
          value: venue.value,
        });
      }
      venue.children?.forEach(child => {
        if (child.id.toString() === id.toString()) {
          foundVenue.push({
            title: child.title,
            id: child.id,
            value: child.value,
            parent: {
              title: venue.title,
              id: venue.id,
              description: venue.description,
              value: venue.value,
            },
          });
        }
        child.children?.forEach(grandChild => {
          if (grandChild.id.toString() === id.toString()) {
            foundVenue.push({
              title: grandChild.title,
              id: grandChild.id,
              value: grandChild.value,
              parent: {
                title: child.title,
                id: child.id,
                value: child.value,
                parent: {
                  title: venue.title,
                  id: venue.id,
                  description: venue.description,
                  value: venue.value,
                },
              },
            });
          }
        });
      });
    });
    return foundVenue[0];
  };

  const getRawVenues = () => {
    return venues.openooh_venue_taxonomy.specification.categories;
  };

  const getTreeVenues = (): Venue[] => {
    return (
      venues.openooh_venue_taxonomy.specification.categories.map(venue => {
        return {
          title: venue.name,
          id: venue.enumeration_id,
          description: venue.description,
          value: venue.string_value,
          locationType: venue.locationType,
          children:
            venue.children.map(child => {
              return {
                title: child.name,
                id: child.enumeration_id,
                description: child.description || '',
                value: child.string_value,
                locationType: child.locationType,
                parent: {
                  title: venue.name,
                  id: venue.enumeration_id,
                  description: venue.description,
                  value: venue.string_value,
                },

                children:
                  child.children?.map(grandChild => {
                    return {
                      title: grandChild.name,
                      description: grandChild.description || '',
                      value: grandChild.string_value,
                      id: grandChild.enumeration_id,
                      locationType: grandChild.locationType,
                      parent: {
                        title: child.name,
                        id: child.enumeration_id,
                        description: child.description || '',
                        value: child.string_value,
                        parent: {
                          title: venue.name,
                          id: venue.enumeration_id,
                          description: venue.description,
                          value: venue.string_value,
                        },
                      },
                    };
                  }) || [],
              };
            }) || [],
        };
      }) || []
    );
  };

  const venuesApi = async () => {
    const response: AxiosResponse<ApiRequest> = await axios.get(
      'https://inventory-api.network.seeblindspot.com/locations/venueTypes'
    );
    const { data } = response;
    const treeVenues = getTreeVenues();
    treeVenues.forEach(venue => {
      if (data[venue.id]) {
        venue.hasStock = true;
      }
      venue.children?.forEach(child => {
        if (data[child.id]) {
          child.hasStock = true;
          venue.hasStock = true;
        }
        child.children?.forEach(grandChild => {
          if (data[grandChild.id]) {
            grandChild.hasStock = true;
            child.hasStock = true;
          }
        });
      });
    });
    const returnVenues = treeVenues
      .filter(venue => venue.hasStock)
      .map(venue => {
        const locationTypesSet = new Set<string>();

        venue.children
          ?.filter(child => child.hasStock)
          .forEach((child: any) => {
            if (child.locationType) {
              child.locationType.forEach((type: any) => {
                locationTypesSet.add(type);
              });
            }
          });

        const locationTypes = Array.from(locationTypesSet);

        return {
          ...venue,
          locationType: locationTypes,
          children: venue.children?.filter(child => child.hasStock),
        };
      });

    return returnVenues;
  };

  return { getRawVenues, getVenuById, getTreeVenues, venuesApi };
};
