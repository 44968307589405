import React from 'react'

import { IntlProvider } from 'react-intl'
import { isRTL, isLocale } from './language.utils'
// import { StyleSheetManager } from "styled-components";

const LanguageContext = React.createContext({} as any)

export const LanguageProvider = ({ children, messages }: any) => {
  const [locale, setLocale] = React.useState('en')
  const changeLanguage = (newLocale: any): void => {
    setLocale(newLocale)
    document.documentElement.lang = newLocale
    //Cookie.set("locale", newLocale);
  }
  React.useEffect(() => {
    const localSetting = 'en'
    if (localSetting && isLocale(localSetting)) {
      document.documentElement.lang = localSetting
      setLocale(localSetting)
    }
  }, [locale])
  let isRtl = isRTL(locale)

  return (
    <LanguageContext.Provider value={{ locale, changeLanguage, isRtl }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}

export const useLocale = () => React.useContext(LanguageContext)
