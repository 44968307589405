import { oneLine } from 'common-tags';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Button } from '../atoms/index';

type PrimaryButtonProps = {
  buttonText: string;
  className?: string;
  dataCy?: string;

  action: () => void;
};
type DialogProps = {
  title?: string;
  description: string;
  boldText?: string;
  loading?: boolean;
  primaryButton: PrimaryButtonProps;
  secondaryButton?: PrimaryButtonProps;
  thirdButton?: PrimaryButtonProps;
};

const Dialog = ({ title, description, boldText, primaryButton, loading = false, secondaryButton, thirdButton }: DialogProps) => {
  return (
    <div className={oneLine`fixed h-screen z-99999 inset-0 overflow-y-auto`}>
      <ReactTooltip id={'categoryTooltip'} place="right" className="custom-tooltip">
          <div className="preview-media-tooltip">
              <h2 className='mb-2'>Campaign Categories Details</h2>
              <div className='flex flex-col gap-2'>
                <div className='flex flex-col items-center md:items-start md:mr-4 border rounded-xl p-2'>
                  <h3 className="text-sm font-bold">Standard - <span className='underline'>with analytics</span></h3>
                  <p className="text-md font-semibold text-dark-500 dark:text-dark-300 text-center md:text-left">Normal campaign type for a non-admin user.</p>
                </div>
                <div className='flex flex-col items-center md:items-start md:mr-4 border rounded-xl p-2'>
                  <h3 className="text-sm font-bold">Internal - without analytics</h3>
                  <p className="text-md font-semibold text-dark-500 dark:text-dark-300 text-center md:text-left">Used for duplicates or extended campaigns.</p>
                </div>
                <div className='flex flex-col items-center md:items-start md:mr-4 border rounded-xl p-2'>
                  <h3 className="text-sm font-bold">Managed - <span className='underline'>with analytics</span></h3>
                  <p className="text-md font-semibold text-dark-500 dark:text-dark-300 text-center md:text-left">Campaigns created by admins for users.</p>
                </div>
                <div className='flex flex-col items-center md:items-start md:mr-4 border rounded-xl p-2'>
                  <h3 className="text-sm font-bold">Media-Plan - without analytics</h3>
                  <p className="text-md font-semibold text-dark-500 dark:text-dark-300 text-center md:text-left"> Campaigns created for budgeting purposes, likely never to run.</p>
                </div>
                <div className='flex flex-col items-center md:items-start md:mr-4 border rounded-xl p-2'>
                  <h3 className="text-sm font-bold">Test - without analytics</h3>
                  <p className="text-md font-semibold text-dark-500 dark:text-dark-300 text-center md:text-left">Campaigns created for platform testing purposes.</p>
                </div>
                <div>
                  Analytics: <em>Hubspot, Monday, Google Analytics</em>
                </div>
              </div>
          </div>
        </ReactTooltip>
      <div
        className={oneLine`flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0`}
      >
        <div className={oneLine`fixed inset-0 transition-opacity`} aria-hidden="true">
          <div className={oneLine`absolute inset-0 bg-gray-500 opacity-75`}></div>
        </div>

        <span className={oneLine`hidden sm:inline-block sm:align-middle sm:h-screen`} aria-hidden="true">
          &#8203;
        </span>
        <div
          className={oneLine`inline-block align-bottom bg-white dark:bg-dark-200 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className={oneLine` p-4 sm:px-6 sm:flex sm:justify-center`}>
            {title && (
              <h3
                className={oneLine`text-lg w-full text-center leading-6 font-medium text-dark-default dark:text-white`}
                id="modal-headline"
              >
                {title}
              </h3>
            )}
          </div>
          <div className={oneLine`bg-white dark:bg-dark-200 dark:text-dark-300 px-4 pt-0 `}>
            <div className={oneLine`sm:flex sm:items-start sm:justify-center`}>
              <div className={oneLine`mt-3 text-center sm:mt-0 sm:text-left`}>
                <div className="mt-2 w-full text-center">
                  <p className={oneLine`text-sm`} dangerouslySetInnerHTML={{ __html: description }} />

                  {boldText && (
                    <p
                      className={oneLine`text-sm font-bold pt-4 text-center`}
                      dangerouslySetInnerHTML={{ __html: boldText }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {loading !== true ? (
            <div className={oneLine`px-4 py-5 sm:px-6 flex justify-center`}>
              {secondaryButton && secondaryButton.buttonText && (
                <Button
                  color="secondary"
                  onClick={secondaryButton.action}
                  data-cy={secondaryButton.dataCy}
                  className={`${secondaryButton.className} sm:w-full sm:mx-3 mr-4`}
                >
                  {secondaryButton.buttonText}
                </Button>
              )}
              {thirdButton && thirdButton.buttonText && (
                <Button
                  color="secondary"
                  onClick={thirdButton.action}
                  data-cy={thirdButton.dataCy}
                  className={`${thirdButton.className} sm:w-full sm:mx-3 mr-4`}
                >
                  {thirdButton.buttonText}
                </Button>
              )}

              <Button
                color="primary"
                fill="full"
                data-cy={primaryButton.dataCy}
                onClick={primaryButton.action}
                className={`${primaryButton.className} sm:w-full sm:mx-3`}
              >
                {primaryButton.buttonText}
              </Button>
            </div>
          ) : (
            <div className={oneLine`px-4 py-5 sm:px-6 flex justify-center`}>
              {secondaryButton && secondaryButton.buttonText && (
                <Button
                  color="secondary"
                  disabled={true}
                  data-cy={secondaryButton.dataCy}
                  onClick={() => {}}
                  className={`${secondaryButton.className} sm:w-full sm:mx-3 mr-4`}
                >
                  {secondaryButton.buttonText}
                </Button>
              )}

              {thirdButton && thirdButton.buttonText && (
                <Button
                  color="primary"
                  fill="full"
                  disabled={true}
                  data-cy={thirdButton.dataCy}
                  onClick={() => {}}
                  className={`${thirdButton.className} sm:w-full sm:mx-3`}
                >
                  {thirdButton.buttonText}
                </Button>
              )}
              
              <Button
                color="primary"
                fill="full"
                disabled={true}
                data-cy={primaryButton.dataCy}
                onClick={() => {}}
                className={`${primaryButton.className} sm:w-full sm:mx-3`}
              >
                {primaryButton.buttonText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Dialog.defaultProps = {};

export default Dialog;
