import React from 'react';
import { oneLine } from 'common-tags';
import Link from 'next/link';
import { CAMPAIGN_STATUS, StatusTag } from '..';
import { CampaignCardPopOver } from 'components/common/molecules';
import { ICampaignStatus } from 'interface';
type IImageBox = {
  image: string;
  uuid: string;
  status: ICampaignStatus;
  isBulk?: boolean;
  step?: number;
};
const ImageBox: React.FC<IImageBox> = ({ image, step, uuid, status, isBulk = false }: IImageBox) => {
  const getLink = () => {
    if (uuid === '1b5a9446-1e0e-498c-8bed-ee7e31e55b17') {
      return '/';
    }
    if (isBulk && status === 'draft') {
      if (step?.toString() === '3') return `/bulk-buying/campaign/${uuid}`;
      return `/bulk-buying/brief/${uuid}`;
    }
    if (isBulk && status === 'saved') {
      return `/bulk-buying/campaign/${uuid}`;
    }
    if (status === 'draft') {
      return `/publish/basic/?cid=${uuid}`;
    }
    return `/campaign/${uuid}`;
  };

  return (
    <div className="relative w-full bg-whiteish rounded-3xl">
      <Link href={getLink()}>
        <a>
          <img
            src={
              status === 'processing' || status === 'draft' || status === 'Draft'
                ? '/images/draft.png'
                : `https://storage.googleapis.com/engage-transcoded-videos/${image}_thumb.jpg`
            }
            className={oneLine` rounded-3xl h-48 object-cover w-full mx-auto block dark:hidden`}
          />
          <img
            src={
              status === 'processing' || status === 'draft' || status === 'Draft' || status === 'saved'
                ? '/images/draft-dark.png'
                : `https://storage.googleapis.com/engage-transcoded-videos/${image}_thumb.jpg`
            }
            className={oneLine` rounded-3xl h-48 object-cover w-full mx-auto hidden dark:block`}
          />
        </a>
      </Link>
      <CampaignCardPopOver status={status} uuid={uuid} link={getLink()} otherClasses="absolute top-3 right-3" />
      <StatusTag status={status} text={status} otherClasses="absolute top-3 left-3" />
      {isBulk && (
        <div
          className="absolute bottom-3 left-3 text-yellow-500 bg-yellow-500 bg-opacity-30 text-container items-center justify-center font-button px-6 py-1
          font-medium  appearance-none rounded-3xl
          ease-linear tracking-wide
          w-max	capitalize
          text-xs
          leading-loose
          transition duration-150 backdrop-blur-3xl"
        >
          Bulk
        </div>
      )}
    </div>
  );
};
export default ImageBox;
