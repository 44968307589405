import { ILocation, ILocationSchedule } from '../interface/ILocation';
import { DateTime } from 'luxon';
import { IScheduler } from '../interface/IScheduler';

export const calculateSlots = (currentSchedule: IScheduler, globalSlots: number[]) => {
  const location = currentSchedule.locations[0];
  let returnSlots: number[] = [];
  let slots = globalSlots;
  if (currentSchedule.type === ILocationSchedule.Hourly) {
    const slotFormatToOriginalHourly = new Map(
      slots.map(slot => {
        const format = DateTime.fromMillis(slot * 1000, { zone: 'utc', locale: 'utc' })
          .toFormat('EEEE_H')
          .toUpperCase();
        return [slot, format]; // Map format to original slot
      })
    );
    if (location && location.restrictionsFormat.length) {
      // Convert restrictionsFormat to a Set for faster lookup
      const restrictionsSet = new Set(location.restrictionsFormat);

      // Filter based on the pre-computed formats
      slots = Array.from(slotFormatToOriginalHourly)
        .filter(([, format]) => {
          return !restrictionsSet.has(format);
        })
        .map(([slot]) => slot);
    }
    returnSlots = slots;
  } else {
    const hourlySlots = [];
    const splitDays = new Map<number, DateTime>();
    for (const slot of slots) {
      let day = DateTime.fromMillis(slot * 1000, { zone: 'utc', locale: 'utc' }).get('day');
      let formatSlot = DateTime.fromMillis(slot * 1000, { zone: 'utc', locale: 'utc' });
      splitDays.set(day, formatSlot);
    }

    for (const item of splitDays.values()) {
      for (let i = 0; i < 24; i++) {
        item.setZone('Europe/Bucharest');
        let j = item.startOf('day').plus({ hour: i });
        hourlySlots.push(j.toSeconds());
      }
    }

    const slotFormatToOriginalHourly = new Map(
      hourlySlots.map(slot => {
        const format = DateTime.fromMillis(slot * 1000, { zone: 'utc', locale: 'utc' })
          .toFormat('EEEE_H')
          .toUpperCase();
        return [slot, format]; // Map format to original slot
      })
    );

    const restrictionsSet = new Set(currentSchedule.locations[0].restrictionsFormat);

    returnSlots = Array.from(slotFormatToOriginalHourly)
      .filter(([, format]) => {
        return !restrictionsSet.has(format);
      })
      .map(([slot]) => slot);
    console.log('returnSlots', returnSlots);
  }
  return returnSlots;
};
