import { Icon } from '@iconify/react-with-api';
import { oneLine } from 'common-tags';
import { useApp } from 'components/app';
import { PublishContext } from 'context/publish/publish.provider';
import { IPublishType } from 'context/publish/publish.reducer';
import { IRulesApi } from 'interface';
import { ILocationContentType } from 'interface/ILocation';
import { IMediaUpload } from 'interface/IMedia';
import React, { useEffect } from 'react';
import { ChevronDown, ChevronRight, Download } from 'react-iconly';
import { Button, Dropzone } from '../atoms';
import ClassicCard from '../atoms/ClassicCard';
import { MediaCard } from './index';
type IProps = {
  index: number;
  resolution: [number, number];
  total: number;
  uploadedmedia: IMediaUpload[];
  rules: IRulesApi[];
  duration: number;
  durations: number[];
  contentType: ILocationContentType;
  uuid: string;
  activeMedia: string;
  locations: string[];
  multiScreenUrl?: string;
  changeMedia: (uuid: string) => void;
};
const MediaFormat: React.FC<IProps> = ({
  index,
  resolution,
  uploadedmedia,
  duration,
  contentType,
  uuid,
  activeMedia,
  changeMedia,
  multiScreenUrl,
  durations,
  locations
}: IProps) => {
  const { notify } = useApp();
  const { publishState } = React.useContext(PublishContext);
  const [hasNoDefault, setHasNoDefault] = React.useState<boolean>(false);
  const [formatMedia, setFormatMedia] = React.useState<IMediaUpload[]>([]);

  useEffect(() => {
    let hasContextual = 0;
    for (const item of formatMedia) {
      if (item.rules.length > 0) {
        hasContextual++;
      }
    }
    if (formatMedia.length !== 0) {
      if (hasContextual === formatMedia.length) {
        setHasNoDefault(true);
      } else {
        setHasNoDefault(false);
      }
    } else {
      setHasNoDefault(false);
    }
    return () => {};
  }, [publishState.toggleMedia]);
  useEffect(() => {
    setFormatMedia(uploadedmedia);
    return () => {};
  }, []);

  const lineClasses = oneLine`
  flex items-center flex-wrap
  font-semibold
  text-sm
  tracking-tighter
  text-lightGrey
  dark:text-white dark:text-opacity-80
  leading-loose
  `;
  return (
    <>
      <ClassicCard
        otherClasses={`w-full border-2 relative p-4 mb-4 md:pr-8 pb-8 md:pb-4  media-card
        ${activeMedia === uuid ? !uploadedmedia.length || hasNoDefault ? ' border-bordercolorPending' : ' border-bordercolorSuccess' : 'dark:border-dark-200'} 
        ${!uploadedmedia.length || hasNoDefault ? 'pending' : 'success'}`}
      >
        <div
          data-cy={`mediaItem${index}`}
          className={`flex items-center justify-between cursor-pointer`}
          onClick={() => {
            changeMedia(uuid);
          }}
        >
          <div className="flex flex-wrap items-center justify-center space-x-4 space-y-4 md:space-y-0">
            <div
              className={`flex items-center justify-center w-14 h-14 rounded-full  bg-opacity-10 ${
                !uploadedmedia.length || hasNoDefault ? 'bg-orange-default' : 'bg-success-default'
              }`}
            >
              {!uploadedmedia.length || hasNoDefault ? (
                <img src="/images/media-loading.svg" />
              ) : (
                <img src="/images/media-check.svg" />
              )}
            </div>
            <div className="">
              <div className={lineClasses}>
                Type:
                <span className="ml-2 text-dark-default dark:text-white">
                  {contentType === ILocationContentType.Video ? (
                    <p>full motion video / static images</p>
                  ) : (
                    <p>static images</p>
                  )}
                </span>
              </div>
              {durations.length > 1 && (
                <div className={lineClasses}>
                  <p className='whitespace-nowrap'>Accepted Durations:</p>
                  <span className="ml-2 text-dark-default  dark:text-white">
                    {durations.map((el, indexN) => {
                      return el + (indexN === durations.length - 1 ? 's' : 's, ');
                    })}
                  </span>
                </div>
              )}
              <div className={lineClasses}>
                {durations.length > 1 ? 'Selected' : ''} Duration:
                <span className="ml-2 text-dark-default  dark:text-white">{duration}s</span>
              </div>
              <div className={lineClasses}>
                Resolution:
                <span className="ml-2 text-dark-default  dark:text-white">
                  {resolution[0]} x {resolution[1]}
                </span>
              </div>
              <div className={lineClasses}>
                Locations:
                <span className="ml-2 text-dark-default  dark:text-white">
                  {locations?.length}
                </span>
              </div>
              { multiScreenUrl && 
                <div className="flex-row underline cursor-pointer text-primary-500 text-sm font-semibold">
                    <a href={multiScreenUrl} className="flex">Download Template 
                      <div className='ml-1'>
                        <Download set="light" primaryColor="#F53D3F" size="small" />
                      </div>
                    </a>
                </div>
              }
            </div>
          </div>
          {/* <div
            className={`hidden md:flex w-8 h-full rounded-full items-center justify-center text-body dark:text-dark-300 ${
              activeMedia === uuid ? 'bg-dark-default dark:bg-dark-300 dark:bg-opacity-20' : ''
            }`}
          >
            <ChevronRight set="light" primaryColor={activeMedia === uuid ? '#fff' : ''} size="small" />
          </div> */}
          <div className={`hidden absolute md:flex items-center top-0 right-0 h-full rounded-tr-20 rounded-br-20 bg-opacity-10  w-4 ${
              activeMedia === uuid ? !uploadedmedia.length || hasNoDefault ? 'bg-bordercolorPending' : 'bg-bordercolorSuccess' : ''
            }`}>
            <ChevronRight size="large" set="light" primaryColor={activeMedia === uuid ? !uploadedmedia.length || hasNoDefault ? '#ed8712' : '#05cd99' : 'hidden'}  />
          </div>

          <div className={`md:hidden absolute flex justify-center bottom-0 left-0 h-4 rounded-tr-20 rounded-br-20 bg-opacity-10 w-full ${
              activeMedia === uuid ? !uploadedmedia.length || hasNoDefault ? 'bg-bordercolorPending' : 'bg-bordercolorSuccess' : ''
            }`}>
            <ChevronDown size="small" set="light" primaryColor={activeMedia === uuid ? !uploadedmedia.length || hasNoDefault ? '#ed8712' : '#05cd99' : 'hidden'}  />
          </div>
        </div>
      </ClassicCard>
    </>
  );
};
export default MediaFormat;
