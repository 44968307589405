import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { Button } from '../atoms';

type Props = {
  title: string;
  variant: string;
  link?: string;
  isActive?: boolean;
  index: number;
  setSelectedTab: (index: number) => void;
};

const TabTitle: React.FC<Props> = ({ title, variant, link, isActive, setSelectedTab, index }) => {
  const router = useRouter();

  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  const onClickRedirect = () => {
    router.push(link || '');
  };

  return (
    <li>
      {variant === 'link' ? (
        <Button
          tag="div"
          simulateBtn={true}
          onClick={link ? onClickRedirect : onClick}
          color={isActive ? 'primary' : 'secondary'}
          fill="none"
          className={`link relative  whitespace-nowrap ${isActive ? 'active' : ''}`}
        >
          {title}
        </Button>
      ) : (
        <Button
          tag="div"
          onClick={link ? onClickRedirect : onClick}
          color="tertiary"
          fill={isActive ? 'full' : 'none'}
          className="whitespace-nowrap"
        >
          {title}
        </Button>
      )}
    </li>
  );
};

export default TabTitle;
