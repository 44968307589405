import React, { useState, useEffect, useContext, useRef } from 'react';

import MyStoreCheckout from './Stripe/MyStoreCheckout';
import { oneLine } from 'common-tags';
import { IUserOrganization } from 'interface';

type IProps = {
  closePopup: () => void;
  onCardSubmitSuccess: () => void;
  user: IUserOrganization;
  open: boolean;
};
const AddCardDialogStripe = ({ open, user, closePopup, onCardSubmitSuccess }: IProps) => {
  const formRef = useRef<any>(null);
  return (
    <>
      {open && (
        <div className={oneLine`fixed z-99999 inset-0 overflow-y-auto`}>
          <div
            className={oneLine`flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0`}
          >
            <div className={oneLine`fixed inset-0 transition-opacity`} aria-hidden="true">
              <div className={oneLine`absolute inset-0 bg-gray-500 opacity-75`}></div>
            </div>

            <span className={oneLine`hidden sm:inline-block sm:align-middle sm:h-screen`} aria-hidden="true">
              &#8203;
            </span>
            <div
              className={oneLine`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full`}
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div onClick={closePopup} className="stripe-close-modal">
                ×
              </div>
              <div className={oneLine`bg-white dark:bg-dark-200 px-4 pt-5 pb-4 sm:p-6 sm:pb-4`}>
                <div className={oneLine`sm:flex sm:items-start`}>
                  <div className={oneLine`mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left`}>
                    <div className="mt-2 text-center">
                      <MyStoreCheckout user={user} onCardSubmitSuccess={onCardSubmitSuccess} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddCardDialogStripe;
