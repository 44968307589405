import Axios, { AxiosRequestConfig } from 'axios';
import { NextPageContext } from 'next';
import nookies from 'nookies';

import { getUser } from './ssr/user';

const basePath = process.env.NEXT_PUBLIC_API_BASEPATH;
const API: any = async (request: AxiosRequestConfig, ctx?: NextPageContext, otherOption?: any, schema?: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    baseURL: basePath,
    timeout: 60000,
    ...otherOption,
  };
  if (request.cancelToken) options.cancelToken = request.cancelToken;

  const client = Axios.create(options);
  const jwt = ctx ? await getUser(ctx) : await getUser(null);
  if (jwt) client.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
  // TODO add custom interceptors
  return client.request(request);
};

export default API;
