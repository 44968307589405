import React, { createContext, useContext, useMemo } from 'react';

import { AppState } from './../types/onboarding';

const appState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
};

export const OnboardingContext = createContext({
  state: appState,
  setState: () => undefined,
});
OnboardingContext.displayName = 'OnboardingContext';

export const OnboardingContextProvider = (props: any) => {
  const [state, setState] = React.useState(appState);
  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state],
  );

  return <OnboardingContext.Provider value={value} {...props} />;
}

const useOnboardingContext = () : {
  setState: (patch: Partial<AppState> | ((previousState: AppState) => Partial<AppState>)) => void;
  state: AppState;
} => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider');
  }

  return context;
}

export default useOnboardingContext;
