import { ISugestion } from 'interface/ISugestion';
import React, { useEffect } from 'react';
import { AddressField } from '.';
import { Icon } from '@iconify/react-with-api';
import Popover from '../atoms/popover/popover';
import { Search } from 'react-iconly';
import { Input } from '../atoms';
import { useMapContext } from 'context/publish/map.provider';

type IProps = {
  onChange: (location: ISugestion) => void;
  onChangeLocName: (name: string) => void;
  popoverMobile: boolean;
  countryArea: string;
  defaultValueLocName: any;
  showList: boolean;
  fromCampaignPage?: boolean;
};
const MapSearch = ({ onChange, onChangeLocName, countryArea, showList, defaultValueLocName, popoverMobile, fromCampaignPage}: IProps) => {
  const [toggleBox, setToggleBox] = React.useState<boolean>(false);
  const [position, setPosition] = React.useState<boolean>(false);
  const {
    setReloadItems,
  } = useMapContext();
  useEffect(() => {
    setPosition(showList);
    return () => { };
  }, [showList]);
  return (
    <>
      <Popover
        className={`leftbottom1 ${popoverMobile ? 'search-popover-mobile' : 'hidden'}`}
        handler={
          <div className="flex p-2 text-body justify-center dark:text-dark-400 dark:border-dark-400 transition-all duration-300 ease-in-out border  border-color rounded-2xl align-items active-handler">
            <Search size={18} set="light" />
            <span className='font-semibold mx-2 search-label'>Search</span>
          </div>
        }
        content={
          <div className="w-80 h-80 py-6 mob-content space-y-6">
            <Input
              name="search"
              type="text"
              borderLight={true}
              startIcon="Search"
              onChange={(e: any) => {
                onChangeLocName(e.target.value);
                setReloadItems(Math.random());
                localStorage.setItem('searchText', e.target.value);
              }}
              defaultValue={defaultValueLocName}
              placeholder={'Search by billboard name'}
              label=""
              fullWidth={true}
            />
            <AddressField
              borderLight={true}
              placeholderText={'Search by location'}
              api={null}
              source="here"
              menuPlacement="bottom"
              selName="input"
              // resultType="city"
              countryArea={countryArea}
              onChange={e => {
                onChange(e);
              }}
              fullWidth={true}
            />
          </div>
        }
      />

      <div className={`flex-1 ${popoverMobile ? 'search-popover-web' : 'block'} min-w-[186px] ${fromCampaignPage ? 'flex flex-row gap-2' : ''}`}>
        <div className="w-full">
          <AddressField
            borderLight={true}
            placeholderText={'Search by location'}
            api={null}
            source="here"
            menuPlacement="bottom"
            selName="input"
            // resultType="city"
            dataCy="geoSearch"
            countryArea={countryArea}
            onChange={e => {
              onChange(e);
            }}
            fullWidth={true}
          />
        </div>
        {fromCampaignPage && (
          <Input
            name="search"
            type="text"
            borderLight={true}
            startIcon="Search"
            onChange={(e: any) => {
              onChangeLocName(e.target.value);
              setReloadItems(Math.random());
              localStorage.setItem('searchText', e.target.value);
            }}
            defaultValue={defaultValueLocName}
            placeholder={'Search by billboard name'}
            label=""
            fullWidth={true}
          />
        )}
      </div>
    </>
  );
};
export default MapSearch;
