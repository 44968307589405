import { oneLine } from 'common-tags';
import { Stack } from 'components/layout';
import { IMediaUpload } from 'interface/IMedia';
import { Time } from '../CampaignCard';

export const MediaLibraryCardInfo = ({ file, fileType }: { file: IMediaUpload; fileType: string }) => {
  return (
    <div className="p-6">
      <Time time={file.created || new Date().getTime() - 6000} />
      <h3 title={file.oldName} className={oneLine`block text-black dark:text-white font-semibold text-xl break-words pt-2 text-container`}>{file.oldName}</h3>

      <Stack gravity="start" fitX={true} spacing="none" otherClass="flex">
        <Stack gravity="start" spacing="xs" align="center" otherClass={`pt-3 flex flex-row items-center space-y-0`}>
          <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect
                x="10.834"
                y="5.8335"
                width="3.33333"
                height="3.33333"
                rx="1.66667"
                stroke="#F64747"
                strokeWidth="1.5"
              />
              <path
                d="M3.93174 14.3345L5.38082 12.8854C6.73499 11.5312 8.93054 11.5312 10.2847 12.8854L11.7338 14.3345M11.7338 14.3345L12.3207 13.7475C13.4021 12.6661 15.1786 12.7467 16.1576 13.9216L16.5017 14.3345M11.7338 14.3345L13.901 16.5017M2.79406 12.5413C2.40198 10.8698 2.40198 9.13021 2.79406 7.45869C3.33698 5.14417 5.14417 3.33698 7.45869 2.79406C9.13021 2.40198 10.8698 2.40198 12.5413 2.79406C14.8558 3.33698 16.663 5.14417 17.2059 7.4587C17.598 9.13021 17.598 10.8698 17.2059 12.5413C16.663 14.8558 14.8558 16.663 12.5413 17.2059C10.8698 17.598 9.13021 17.598 7.4587 17.2059C5.14418 16.663 3.33698 14.8558 2.79406 12.5413Z"
                stroke="#F64747"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="ml-2 text-sm font-semibold tracking-tighter text-body dark:text-dark-300">{fileType}</div>
        </Stack>
        <Stack gravity="start" spacing="xs" align="center" otherClass={`pt-3 pl-5 flex flex-row items-center space-y-0`}>
          <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.01648 7.26619C2.59212 5.05879 4.35091 3.37001 6.55947 2.90403L6.9815 2.81498C8.972 2.39501 11.0267 2.39501 13.0172 2.81498L13.4392 2.90403C15.6478 3.37001 17.4066 5.05879 17.9822 7.26619C18.4495 9.05806 18.4495 10.9419 17.9822 12.7338C17.4066 14.9412 15.6478 16.63 13.4392 17.096L13.0172 17.185C11.0267 17.605 8.972 17.605 6.9815 17.185L6.55948 17.096C4.35091 16.63 2.59212 14.9412 2.01648 12.7338C1.54919 10.9419 1.54919 9.05806 2.01648 7.26619Z"
                stroke="#F64747"
                strokeWidth="1.5"
              />
              <path
                d="M5 10H6.66667L8.33333 12.5L11.6667 7.5L13.3333 10H15"
                stroke="#F64747"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="ml-2 text-sm font-semibold tracking-tighter text-body dark:text-dark-300">{`${file.width}x${file.height}`}</div>
        </Stack>
        {fileType === 'video' && (
          <Stack gravity="start" spacing="xs" align="center" otherClass={`pt-3 pl-5 flex flex-row items-center space-y-0`}>
            <div>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.9375 9.00049C15.9375 12.8322 12.8318 15.938 9 15.938C5.16825 15.938 2.0625 12.8322 2.0625 9.00049C2.0625 5.16874 5.16825 2.06299 9 2.06299C12.8318 2.06299 15.9375 5.16874 15.9375 9.00049Z"
                  stroke="#F64747"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.5736 11.2068L8.74609 9.52002V5.88477"
                  stroke="#F64747"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="ml-2 text-sm font-semibold tracking-tighter text-body dark:text-dark-300">{`${file.duration}`}</div>
          </Stack>
        )}
      </Stack>
    </div>
  );
};
