import { PublishContext } from 'context/publish/publish.provider';
import { IPublishType } from 'context/publish/publish.reducer';
import { ICampaignPublishPph, IListLocation } from 'interface/ILocation';
import { IRadioBoxOptions } from 'interface/ISelect';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Checkbox, Input, RadioBox } from '../atoms';
import Popover from '../atoms/popover/popover';

type IProps = {
  pph: ICampaignPublishPph;
  index: number;
};

type IPropsItems = {
  pph: ICampaignPublishPph;
  canRemove: boolean;
  index: number;
};

const Items: React.FC<IPropsItems> = ({ pph, canRemove, index }: IPropsItems) => {
  const { publishState, dispatch } = React.useContext(PublishContext);
  const [itemsLocation, setItemLocations] = React.useState<IListLocation[]>([]);
  const [filterItemsLocation, setFilterItemLocations] = React.useState<IListLocation[]>([]);
  const [selectList, setSelectList] = React.useState<string[]>([]);

  useEffect(() => {
    if (publishState.locations) {
      const locations = publishState.locations?.filter(el => {
        return pph.locations.includes(el.location.uuid);
      });
      setItemLocations(locations);
      setFilterItemLocations(locations);
    }
  }, []);

  const filterLocation = (e: string) => {
    if (itemsLocation) {
      const searchLocation = itemsLocation.filter(el => {
        return el.location.n.toLocaleLowerCase().includes(e.toLocaleLowerCase());
      });

      setFilterItemLocations(searchLocation);
    }
  };
  const createPph = () => {
    dispatch({
      type: IPublishType.createPph,
      payload: {
        locationsIds: selectList,
        index,
      },
    });
  };

  const intl = useIntl();
  return (
    <div className="w-full py-4 space-y-4 text-left text-black md:w-80">
      <h2 className="text-xl font-bold is-relative">
        <FormattedMessage id="Text.YourSelection" />
      </h2>
      <div className="space-y-10">
        <Input
          name="searcg"
          type="text"
          startIcon="Search"
          onChange={(e: any) => {
            filterLocation(e.target.value);
          }}
          label={intl.formatMessage({
            defaultMessage: 'Search',
            id: 'Label.Search',
          })}
          fullWidth={true}
        />
      </div>
      <div
        id="scrollableDiv"
        style={{
          height: 300,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '20px',
        }}
      >
        {filterItemsLocation?.map((el, i: number) => (
          <div className="flex w-full pb-2 mb-5 space-x-6 border-b border-grey" key={i}>
            <div className="flex w-3/12 space-x-2">
              <span className="w-5 text-base font-medium">{i + 1}.</span>
              <img
                className="object-cover w-16 h-16 rounded "
                src={
                  el.location.locationPhotos
                    ? el.location.locationPhotos[0]
                    : el.location.locationPhoto
                    ? el.location.locationPhoto
                    : '/images/screenPlaceholder.jpg'
                }
              />
            </div>
            <div className="w-8/12">
              <h4 className="text-sm font-bold leading-tight">{el.location.n}</h4>
              <p className="text-xs">
                {' '}
                <FormattedMessage id="Label.Address" /> {el.location.address}
              </p>
              <p className="text-xs ">PPH: {3600 / el.pph}</p>
            </div>
            {canRemove && (
              <div className="w-1/12">
                <Checkbox
                  name="warn"
                  onChange={e => {
                    if (e.currentTarget.checked) {
                      let l = [...selectList];
                      l.push(el.location.uuid);

                      setSelectList(Array.from(new Set(l)));
                    } else {
                      let l = [...selectList].filter(deleteItem => deleteItem !== el.location.uuid);
                      setSelectList(Array.from(new Set(l)));
                    }
                  }}
                  color="primary"
                  labelColor="dark"
                  label=""
                />
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="w-full">
        {selectList.length > 0 && selectList.length !== itemsLocation.length && (
          <div className="flex justify-end w-full">
            <Button color="primary" fullWidth={true} onClick={createPph}>
              <FormattedMessage id="Text.CreateOverride" /> ({selectList.length})
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const PublishPphSelect = ({ pph, index }: IProps) => {
  const [pphOptions, setPphOptios] = React.useState<IRadioBoxOptions[] | null>(null);
  const { publishState, dispatch } = React.useContext(PublishContext);

  useEffect(() => {
    let options = [];

    for (const el of pph.otherPph) {
      options.push({
        value: `${el.uuid}`,
        label: `${3600 / el.seconds}`,
        checked: `${el.seconds}` === `${pph.pph}`,
      });
    }
    setPphOptios(options);
  }, []);

  const setPPh = (newPph: string, i: number) => {
    dispatch({
      type: IPublishType.setPph,
      payload: {
        pph: newPph,
        i,
      },
    });
  };

  return (
    <div className="pb-10 space-y-6">
      <div className="flex justify-between text-white">
        <p className="text-base">
          <FormattedMessage id="Label.DefaultPph" /> ({3600 / pph.pph})
        </p>

        <p className="text-base cursor-pointer">
          <Popover
            className="leftbottom"
            handler={
              <p>
                <FormattedMessage id="Label.ViewLocations" /> ({pph.locations.length})
              </p>
            }
            content={<Items index={index} pph={pph} canRemove={false} />}
          />
        </p>
      </div>
      {pphOptions && (
        <RadioBox
          boxSize="big"
          key={index}
          type="radio"
          name={`primary_${index}`}
          flexClass="flex-1 is-center-box"
          isDark={true}
          otherClasses={'is-dark'}
          onChange={(e: any) => {
            const l = e.target.id.split('_');
            //  setLocationPph(l[1])
            setPPh(l[1], index);
          }}
          options={pphOptions}
        />
      )}
      {pph.locations.length > 1 && (
        <Popover
          className="leftbottom"
          handler={
            <Button fullWidth={true} color="primary" loading={false}>
              <FormattedMessage id="Label.OVERRIDE" />
            </Button>
          }
          content={<Items pph={pph} index={index} canRemove={true} />}
        />
      )}
    </div>
  );
};
export default PublishPphSelect;
