import React, { useEffect } from 'react';
import { Input } from '../atoms';
import { oneLine } from 'common-tags';

type IProps = {
  onSearch(text: string): void;
  isOpenSearch?(isOpen: boolean): void;
  initialValue?: string;
};

const SearchBar: React.FC<IProps> = ({ onSearch, isOpenSearch, initialValue }) => {
  const [defaultValue, setDefaultValue] = React.useState<string>(initialValue || '');

  useEffect(() => {
    if (initialValue && initialValue.length > 0) {
      setDefaultValue(initialValue);
      onSearch(initialValue);
    }
  }, [initialValue]);

  return (
    <div className={oneLine`flex items-center relative flex-2 w-full`}>
      <div className="search-input-box w-full">
        <Input
          placeholder="Search"
          name="input1"
          onKeyUp={(event: any) => {
            onSearch(event.target.value);
          }}
          label=""
          startIcon="Search"
          defaultValue={defaultValue}
          fullWidth={true}
        />
      </div>
    </div>
  );
};

SearchBar.defaultProps = {
  isOpenSearch: () => {},
};

export default SearchBar;
