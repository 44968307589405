import axios, { AxiosResponse } from 'axios';
import { ISugestion } from '../../interface/ISugestion';
const apiKey = 'Oc8gIo6geJUEmJEGleEp0zz9jOYYRa0M9ovwfcifVbU';
const queryHere = async (
  query: string,
  resultType?: string,
  countryArea?: string,
  suggestionsType?: string
): Promise<ISugestion[]> => {
  const response: any = await axios.request<AxiosResponse>({
    method: 'get',
    url: `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=${apiKey}&language=en&query=${query}&resultType=${resultType}${
      countryArea ? `&country=${countryArea}` : ''
    }`,
  });
  if (resultType === 'country') {
    const data: ISugestion[] = response.data.suggestions
      .filter((el: any) => el.matchLevel === 'country' || el.matchLevel === 'state')
      .map((el: any) => {
        return {
          label: suggestionsType === 'city' ? el.address.city : el.label,
          value: suggestionsType === 'city' ? el.address.city : el.label,
          locationId: el.locationId,
          city: el.address.city,
          country: el.address.country,
          county: el.address.county,
        };
      });
    return data;
  } else {
    const data: ISugestion[] = response.data.suggestions.map((el: any) => {
      return {
        label: suggestionsType === 'city' ? el.address.city : el.label,
        value: suggestionsType === 'city' ? el.address.city : el.label,
        locationId: el.locationId,
        city: el.address.city,
        country: el.address.country,
        county: el.address.county,
      };
    });
    return data;
  }
};

async function getPoi(city: string, poiType: string): Promise<ISugestion> {
  const url = 'https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json';
  const response = await fetch(
    `${url}?query=${encodeURIComponent(poiType + ' in ' + city)}&apikey=${apiKey}&maxresults=10`
  );
  if (!response.ok) {
    throw new Error('Failed to fetch data: ' + response.statusText);
  }
  const data: ISugestion = await response.json();
  return data;
}

const queryHereAuth = async (query: string, sourceData?: any[]) => {
  const filteredData = sourceData?.filter((el: any) => {
    return el.name.toLowerCase().includes(query.toLowerCase());
  });

  const data: ISugestion[] | undefined = filteredData?.map((el: any) => {
    return {
      label: el.name,
      value: el.iso,
      locationId: el.uuid,
      city: '',
      country: el.iso3,
      county: '',
      iso3: el.iso3,
    };
  });
  return data;
};

const reverseGeoCode = async (locationId: string): Promise<any> => {
  const response: any = await axios.request<AxiosResponse>({
    method: 'get',
    url: `https://geocoder.ls.hereapi.com/6.2/geocode.json?locationid=${locationId}&jsonattributes=1&gen=9&apiKey=${apiKey}`,
  });
  const bottomRight = response.data.response.view[0]?.result[0].location.mapView.bottomRight;
  const topLeft = response.data.response.view[0]?.result[0].location.mapView.topLeft;
  const data = {
    latitude: response.data.response.view[0]
      ? response.data.response.view[0].result[0].location.displayPosition.latitude
      : '',
    longitude: response.data.response.view[0]
      ? response.data.response.view[0].result[0].location.displayPosition.longitude
      : '',
    topLeft: topLeft,
    bottomRight: bottomRight,
    iso3: response.data.response.view[0]?.result[0].location.address.country,
  };
  return data;
};

const Address = {
  queryHere,
  queryHereAuth,
  reverseGeoCode,
  getPoi,
};

export default Address;

// protected ConvertFromFirebase(_u: firebase.User): User {
//   let user: User = {
//   }
//   return user
// }
