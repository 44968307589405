/* eslint-disable import/no-anonymous-default-export */
import { getErrorMessage } from '@utils/errorHandling';
import API from 'api/base';
import { NextPageContext } from 'next';

import { Stripe, loadStripe } from '@stripe/stripe-js';
import { IUserOrganization } from 'interface';

// eslint-disable-next-line no-shadow
enum StripeApiKeys {
  ro = 'pk_live_51JcwYNLlTRsGXRbdqEbgVglo3aQZIXjGVOMd4eubUZdeSbFtewiMHFfh17eSBPAD55LHsc1LPWeJpJN4Ojnc5eRv00CM8lzNOD',
  en = 'pk_live_lIg96fe72jF1qveEluHkqGJ300sgoaQELw',
}
let stripePromise: Promise<Stripe | null>;
const getStripe = (user: IUserOrganization) => {
  console.log('user', user.tpsRegion.uuid === 'tps-eu' ? StripeApiKeys.ro : StripeApiKeys.en);
  if (!stripePromise) {
    stripePromise = loadStripe(user.tpsRegion.uuid === 'tps-eu' ? StripeApiKeys.ro : StripeApiKeys.en);
  }
  return stripePromise;
};

const getIntent = async (ctx?: NextPageContext): Promise<any> => {
  try {
    const result = await API(
      {
        method: 'post',
        url: `payment/get-intent`,
      },
      ctx
    );
    return result;
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const paymentAdd = async (payment: any, ctx?: NextPageContext): Promise<any> => {
  try {
    const result = await API(
      {
        method: 'post',
        url: `payment/addv5`,
        data: payment,
      },
      ctx
    );
    return result;
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

export default {
  getIntent,
  paymentAdd,
  getStripe,
};
