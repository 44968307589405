import { oneLine } from 'common-tags';
import { IMediaUpload } from 'interface/IMedia';
import React, { useRef } from 'react';
import { Button } from '../atoms/index';
import { MediaLibraryCardInfo } from '../atoms/MediaLibraryCardInfo';
import useOnClickOutside from '../atoms/popover/useOnClickOutSide';
import { Portal } from '../atoms/Portal';

type IPopupVideo = {
  preview: string;
  fileTypes?: string;
  file?: IMediaUpload;
  closePopup: () => void;
};

const PopupVideo = ({ preview, closePopup, fileTypes, file }: IPopupVideo) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => closePopup());

  return (
    <Portal>
      <div className={oneLine`preview-modal flex flex-col w-11/12 bg-white dark:bg-dark-200 items-center rounded-2xl`}>
        <div className="flex justify-between w-full p-7">
          <div className="flex flex-row flex-wrap align-items"></div>
          <button onClick={closePopup}>
            <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.98959 9.01041L21.0104 21.0312M21.0104 9.01041L8.98959 21.0312"
                stroke="#858D96"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="w-full h-full overflow-hidden carousel-container">
          {fileTypes === 'image' ? (
            <video poster={preview} className={oneLine` carousel-photo carousel-image rounded-2xl`}>
              <source src={preview} type="image/" />
            </video>
          ) : (
            <video width="80%" height="400" className="carousel-photo carousel-image rounded-2xl" controls>
              <source src={preview} type="video/mp4" />
            </video>
          )}
        </div>

        <div className="w-full">{file && fileTypes && <MediaLibraryCardInfo file={file} fileType={fileTypes} />}</div>
      </div>
    </Portal>
  );
};

PopupVideo.defaultProps = {};

export default PopupVideo;
