import { ISugestion } from 'interface/ISugestion';
import React, { useEffect } from 'react';
import { AddressField } from '.';
import { CAMPAIGNS, JWT } from 'api/';
import { ILocation, ILocationFull } from 'interface/ILocation';

import { PublishContext } from 'context/publish/publish.provider';
import { IPublishType } from 'context/publish/publish.reducer';
import { useMapContext } from 'context/publish/map.provider';
import Map from './Map';
import _ from 'lodash';
import { Popup } from 'react-mapbox-gl';
import dynamic from 'next/dynamic';
import { Button } from '../atoms';

const MapTest = dynamic(() => import('./MapTest'), {
  ssr: false
});

const PublishMapSection = () => {
  return (
      <>
       
      </>
  );
};
export default PublishMapSection;
