import Link from 'next/link'
import react from 'react'
import { useRouter } from 'next/router'
import Tabs, { TabsProps } from 'components/common/molecules/Tabs'
import Tab from 'components/common/molecules/Tab'

const MenuProfile = () => {
  const router = useRouter()
  let path = [
    '/profile/basic',
    '/profile/security',
    '/profile/organization',
    '/profile/payment',
    '/profile/credits',
    '/profile/invoice',
    '/profile/notifications'
  ]
  return (
    <div className="mx-auto text-center md:flex justify-center max-w-full">
      <Tabs variant="link" selectedTabIndex={path.indexOf(router.pathname)}>
        <Tab title="Basic Info" link="/profile/basic" />
        <Tab title="Change Password" link="/profile/security" />
        <Tab title="Billing Info" link="/profile/organization" />
        <Tab title="Payment Methods" link="/profile/payment" />
        <Tab title="Blindspot Credits" link="/profile/credits" />
        <Tab title="Invoice" link="/profile/invoice" />
        {/* <Tab title="Notifications" link="/profile/notifications" /> */}
      </Tabs>
    </div>
  )
}
export default MenuProfile
