import React from 'react'
import { Icon } from '@iconify/react-with-api'
import { oneLine } from 'common-tags'

interface IProgressBar {
  width: number
}

const ProgressBar: React.FC<IProgressBar> = ({ width }: IProgressBar) => {
  return (
    <div className="relative pt-1">
      <div className={oneLine`overflow-hidden h-5 mb-4 text-xs flex rounded bg-accent-500`}>
        <div
          style={{ width: `${width}%` }}
          className={oneLine`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-500`}
        >
          {width > 0 && <p className={oneLine`w-full text-right pr-2 text-xs text-display`}>{width}%</p>}
        </div>
      </div>
    </div>
  )
}

ProgressBar.defaultProps = {
  width: 10
}
export default ProgressBar
