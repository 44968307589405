import { viewOnboardingTour } from "@api/gtm/gtm";
import React, { useEffect } from "react";
import { TooltipRenderProps } from "react-joyride";
import Button from "./Button";

export type NotifyType = 'info' | 'success' | 'warning' | 'error';

export type OnboardingTooltipProps = {
  message: string;
  type: NotifyType;
  headline?: string;
};
export const OnboardingTooltip = ({ backProps,
  continuous,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps}: TooltipRenderProps) => {

    useEffect(() => {
      viewOnboardingTour(index + 1);
    }, []);

  return (
    <div
      {...tooltipProps}
      className="dark:bg-dark-200 bg-white rounded-2xl p-6 max-w-[500px] box-shadow-container-light dark:dark-shadow dark:text-dark-400"
    >
      <div className="">
        {step.title && (
          <div>
            {step.title}
          </div>
        )}
        {step.content && <div>{step.content}</div>}
      </div>
      <div>
        <div className="flex flex-row items-center justify-between w-full pt-6">
          {!isLastStep && (
           <div className="text-primary-default underline" {...skipProps}>skip the tour</div>
          )}
          <div className="flex flex-row gap-2">
            {index > 0 && (
              <Button buttonSize="sm" {...backProps} fill="outline">Back</Button>
            )}
            <Button buttonSize="sm" {...primaryProps}>
              {continuous ? 'Next' : 'Close'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
