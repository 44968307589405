import React, { useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { oneLine } from 'common-tags';
import { FormattedMessage } from 'react-intl';
import { IMediaUpload } from 'interface/IMedia';

type IProps = {
  photos: string[];
  locationName: string;
  mediaDetails?: IMediaUpload;
  closeDialog: () => void;
};

const baseUrl = 'https://storage.googleapis.com/engage-uploaded-videos/';

const LightBox = ({ photos, locationName, closeDialog, mediaDetails }: IProps) => {
  return (
    <div
      className={oneLine`fixed flex justify-center items-center z-110 inset-0 bg-gray-800 bg-opacity-70 overflow-y-auto w-full h-full light-box-container`}
    >
      <div className={oneLine`preview-modal flex flex-col w-11/12 bg-white dark:bg-dark-200 items-center rounded-2xl`}>
        <div className="flex justify-between w-full p-7">
          <div className="flex flex-row flex-wrap align-items">
            <h2 className="mr-2 font-bold dark:text-dark-400">
              {!mediaDetails && <FormattedMessage id="Text.AllImages" />}
            </h2>
            <h2 className="font-bold text-black dark:text-white">{locationName}</h2>
          </div>
          <button onClick={closeDialog}>
            <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.98959 9.01041L21.0104 21.0312M21.0104 9.01041L8.98959 21.0312"
                stroke="#858D96"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="w-full h-full overflow-hidden carousel-container">
       
          {photos.length === 1 ? (
            mediaDetails ? (
              mediaDetails.oldType.includes('video') ? (
                <video className="carousel-photo carousel-image rounded-2xl" controls autoPlay muted>
                  <source
                    src={
                      mediaDetails.oldFilename
                        ? `${baseUrl}${mediaDetails.oldFilename}`
                        : `${baseUrl}${mediaDetails.uid}.${mediaDetails.oldName.split('.').pop()}`
                    }
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img
                  className="carousel-photo carousel-image rounded-2xl"
                  src={`${baseUrl}${
                    mediaDetails.oldFilename
                      ? mediaDetails.oldFilename
                      : `${mediaDetails.uid}.${mediaDetails.oldName.split('.').pop()}`
                  }`}
                ></img>
              )
            ) : (
              <img src={photos[0]} className="carousel-photo carousel-image rounded-2xl" />
            )
          ) : (
            <Carousel
              infiniteLoop
              autoPlay
              showStatus={false}
              centerMode={true}
              centerSlidePercentage={101}
              dynamicHeight={true}
              showIndicators={false}
              showThumbs={true}
            >
              {photos.map((el, index) => (
                <img className="carousel-image rounded-2xl" key={index} src={el} />
              ))}
            </Carousel>
          )}
        </div>
      </div>
    </div>
  );
};

export default LightBox;
