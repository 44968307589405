export type NotifyType = 'info' | 'success' | 'warning' | 'error';

export type ToastProps = {
  message: string;
  type: NotifyType;
  headline?: string;
};
export const ToastComponent = ({ message, type, headline }: ToastProps) => {
  return (
    <div className="flex items-center space-x-4">
      {type === 'error' && (
        <div className="flex items-center justify-center w-10 h-10 bg-red-500 bg-opacity-25 rounded-full flex-shrink-0">
          <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.4829 8.91958L7.51792 5.95625L10.4812 2.99292C11.0512 2.42458 11.0512 1.49958 10.4812 0.931251C9.91125 0.357918 8.98958 0.359584 8.41958 0.929584L5.45458 3.89292L2.48958 0.926251C1.91958 0.356251 0.99625 0.359584 0.42625 0.926251C-0.142083 1.49625 -0.142083 2.42125 0.42625 2.98958L3.39292 5.95625L0.432917 8.91458C-0.137083 9.48458 -0.137083 10.4096 0.432917 10.9763C0.717917 11.2629 1.08958 11.4046 1.46292 11.4046C1.83792 11.4046 2.20958 11.2629 2.49458 10.9779L5.45458 8.01792L8.42125 10.9829C8.70625 11.2679 9.07792 11.4096 9.45125 11.4096C9.82458 11.4096 10.1979 11.2663 10.4829 10.9829C11.0529 10.4129 11.0529 9.48958 10.4829 8.91958Z"
              fill="#F53D3F"
            />
          </svg>
        </div>
      )}
      {type === 'info' && (
        <div className="flex items-center justify-center w-10 h-10 bg-blue-500 bg-opacity-25 rounded-full flex-shrink-0">
          <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.341829 1.62533C0.341829 2.42866 0.993496 3.08366 1.79183 3.08366C2.6135 3.08366 3.26683 2.42866 3.26683 1.62533C3.26683 0.821992 2.6135 0.166992 1.8085 0.166992C1.00016 0.166992 0.341829 0.821992 0.341829 1.62533ZM3.25016 6.91333C3.25016 6.10999 2.59516 5.45499 1.79183 5.45499C0.988496 5.45499 0.333496 6.10999 0.333496 6.91333V14.28C0.333496 15.0833 0.988496 15.7383 1.79183 15.7383C2.59516 15.7383 3.25016 15.0833 3.25016 14.28V6.91333Z"
              fill="#1F8AF4"
            />
          </svg>
        </div>
      )}
      {type === 'warning' && (
        <div className="flex items-center justify-center w-10 h-10 bg-yellow-500 bg-opacity-25 rounded-full flex-shrink-0">
          <svg width="4" height="17" viewBox="0 0 4 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.44987 9.55156C3.44987 10.3549 2.79487 11.0099 1.99154 11.0099C1.1882 11.0099 0.533203 10.3549 0.533203 9.55156V2.1849C0.533203 1.38156 1.1882 0.726562 1.99154 0.726562C2.79487 0.726562 3.44987 1.38156 3.44987 2.1849V9.55156ZM0.541536 14.8396C0.541536 14.0362 1.1932 13.3812 1.99154 13.3812C2.8132 13.3812 3.46654 14.0362 3.46654 14.8396C3.46654 15.6429 2.8132 16.2979 2.0082 16.2979C1.19987 16.2979 0.541536 15.6429 0.541536 14.8396Z"
              fill="#FDA53F"
            />
          </svg>
        </div>
      )}
      {type === 'success' && (
        <div className="flex items-center justify-center w-10 h-10 bg-green-500 bg-opacity-25 rounded-full flex-shrink-0">
          <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.20458 10.5C4.85277 10.5018 4.50096 10.3708 4.22977 10.1015L0.740987 6.63302C0.202278 6.0943 0.196781 5.21981 0.729993 4.6774C1.26321 4.13315 2.13357 4.12761 2.67411 4.66448L5.18442 7.15882L11.3136 0.911909C11.8487 0.367656 12.719 0.362121 13.2577 0.898994C13.7983 1.43771 13.8038 2.31405 13.2706 2.85461L6.17389 10.0886C5.90637 10.3616 5.55639 10.4981 5.20458 10.5Z"
              fill="#05CD99"
            />
          </svg>
        </div>
      )}
      <div>
        {headline && <h3 className="text-base font-bold text-dark-500 dark:text-dark-400">{headline}</h3>}
        {!headline && type === 'error' && (
          <h3 className="text-lg font-semibold text-dark-500 dark:text-dark-400">We have a problem here</h3>
        )}
        {!headline && type === 'success' && (
          <h3 className="text-lg font-semibold text-dark-500 dark:text-dark-400">Yay! Everything worked!</h3>
        )}
        {!headline && type === 'warning' && (
          <h3 className="text-lg font-semibold text-dark-500 dark:text-dark-400">We have a problem here</h3>
        )}
        {!headline && type === 'info' && <h3 className="text-lg font-semibold text-dark-500 dark:text-dark-400">Did you know?</h3>}
        <div className="pt-1 text-sm font-medium dark:text-dark-300"> {message}</div>
      </div>
    </div>
  );
};
