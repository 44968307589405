import { INotificationPopup, INotifications } from 'interface/INotifications';
import React, { useState, useMemo } from 'react';
import ReactDOM, { createPortal } from 'react-dom';
import { v4 as uuidv4 } from 'uuid';

import { NotificationContext } from './NotificationContext';
import { NotificationPopup } from './NotificationPopup';

export const NotificationProvider = (props: any) => {
  const [notificationPopups, setNotificationPopups] = useState<any[]>([]);

  const open = (notification: INotifications) => {
    setNotificationPopups((currentNotifications: INotificationPopup[]) => [
      ...currentNotifications,
      { id: uuidv4(), ...notification.content, createdAt: notification.createdAt, type: notification.type },
    ]);
  };

  const close = (id: string) => {
    setNotificationPopups((currentPopups: INotificationPopup[]) =>
      currentPopups.filter((toast: any) => toast.id !== id)
    );
  };

  const contextValue = useMemo(() => ({ open }), []);

  return (
    <NotificationContext.Provider value={contextValue}>
      {props.children}
      {createPortal(
        <div className="notification-wrapper">
          {notificationPopups.map((popup: INotificationPopup) => (
            <NotificationPopup key={popup.id} close={() => close(popup.id)}>
              {popup}
            </NotificationPopup>
          ))}
        </div>,
        document.getElementsByTagName('body')[0]
      )}
    </NotificationContext.Provider>
  );
};
