import { NotifyType } from "components/common/atoms/Toast"

export const getImageSize = (imageURL: string | ArrayBuffer, callback: any) => {
  // Create image object to ascertain dimensions.
  let image = new Image()

  image.onload =  () => {
    // needed for `this` context
    callback(image.naturalWidth, image.naturalHeight)
  }

  image.src = imageURL as string
}

export type RatioType = 'reject' | 'rejected_too_small' | 'rejected_too_big' | 'warn_too_small' | 'warn_too_big' | 'accepted'
export const checkRatio = (
  fileName: string,
  isRatioValid: RatioType,
  resolution: string
): {
  canUpload: number
  message: string
  type: NotifyType
} => {
  switch (isRatioValid) {
    case 'reject':
      return {
        canUpload: 0,
        message: `${fileName} has incompatible resolution. ${resolution} is required`,
        type: 'error'
      }
    case 'rejected_too_small':
      return {
        canUpload: 0,
        message: `${fileName} has a lower width than expected. Width must be ${resolution.split('x')[0]}`,
        type: 'error'
      }
    case 'rejected_too_big':
      return {
        canUpload: 0,
        message: `${fileName} has a higher width than expected. Width must be ${resolution.split('x')[0]}`,
        type: 'error'
      }
    case 'warn_too_small':
      return {
        canUpload: 0,
        message: `${fileName} has a slightly lower width than expected.`,
        type: 'warning'
      }
    case 'warn_too_big':
      return {
        canUpload: 0,
        message: `${fileName} has a slightly higher width than expected.`,
        type: 'warning'
      }
  }
  return {
    canUpload: 1,
    message: '',
    type: 'success'
  }
}

class VideoFile {
  aspectRatio

  width

  height

  totalPixels

  constructor(input: any) {
    this.width = input.width
    this.height = input.height
    this.aspectRatio = parseFloat((input.width / input.height).toFixed(2))
    this.totalPixels = input.width * input.height
  }
}

const scaleWithPadding = (input: any, target: any) => {
  const ratio = Math.min(target.width / input.width, target.height / input.height)
  const newWidth = input.width * ratio
  const newHeight = input.height * ratio
  return {
    width: parseInt(newWidth.toFixed(), 10),
    height: parseInt(newHeight.toFixed(), 10)
  }
}

export const doesItFit = (input: any, target: any, allowedErrorPercent: any): RatioType => {
  const targetVideo = new VideoFile(target)
  const inputVideo = new VideoFile(input)
  let isScaleDown = false
  // figure out how will the renderer scale the video and what the resulting resolution will be
  const inputScaled = scaleWithPadding(inputVideo, targetVideo)

  // find the difference in percent points so we can compare with the allowed error percent
  const percentWidth = (Math.abs(inputScaled.width - targetVideo.width) / targetVideo.width) * 100
  const percentHeight = (Math.abs(inputScaled.height - targetVideo.height) / targetVideo.height) * 100

  const percentPixelDensity = (inputVideo.totalPixels / targetVideo.totalPixels) * 100
  if (targetVideo.totalPixels < inputVideo.totalPixels) {
    isScaleDown = true
  }
  // console.log(`Result: ${inputScaled.width}x${inputScaled.height} | Width: ${percentWidth}% | Height: ${percentHeight}% | Density: ${percentPixelDensity}%`);
  if (targetVideo.aspectRatio === inputVideo.aspectRatio) {
    // Accept pixel density variation of 100% if aspect ratio is the same
    if (!isScaleDown && percentPixelDensity < 70) {
      console.log(
        `Result ScaleUp ${!isScaleDown}: ${inputVideo.width}x${
          inputVideo.height
        } | Width: ${percentWidth}% | Height: ${percentHeight}% | Density: ${percentPixelDensity}%, false`
      )
      return 'reject'
    }
    console.log(
      `Result ScaleUp ${!isScaleDown} - ${percentPixelDensity > 120}: ${inputVideo.width}x${
        inputVideo.height
      } | Width: ${percentWidth}% | Height: ${percentHeight}% | Density: ${percentPixelDensity}%, true`
    )
    return 'accepted'
  }
  if (percentHeight < allowedErrorPercent && percentWidth < allowedErrorPercent) {
    if (percentPixelDensity > 100 && percentPixelDensity < 120) {
      // console.log(`Result ScaleUp ${!isScaleDown}: ${inputVideo.width}x${inputVideo.height} | Width: ${percentWidth}% | Height: ${percentHeight}% | Density: ${percentPixelDensity}%, true`);
      return 'warn_too_big'
    }
  }
  if (percentHeight < allowedErrorPercent && percentWidth < allowedErrorPercent) {
    if (percentPixelDensity > 80 && percentPixelDensity < 100) {
      // console.log(`Result ScaleUp ${!isScaleDown}: ${inputVideo.width}x${inputVideo.height} | Width: ${percentWidth}% | Height: ${percentHeight}% | Density: ${percentPixelDensity}%, true`);
      return 'warn_too_small'
    }
  }

  // console.log(`Result ScaleUp ${!isScaleDown}: ${inputScaled.width}x${inputScaled.height} | Width: ${percentWidth}% | Height: ${percentHeight}% | Density: ${percentPixelDensity}%, false`);
  return 'reject'
}

export const isRatioInRange = (fileWidth: any, targetWidth: any, fileRatio: any, targetRatio: any) => {
  if (fileWidth / targetWidth === 1) return 'accepted'
  if (fileWidth / targetWidth < 1 && fileWidth / targetWidth >= 0.6) return 'warn_too_small'
  if (fileWidth / targetWidth > 1 && fileWidth / targetWidth <= 1.5) return 'warn_too_big'
  if (fileWidth / targetWidth < 0.6) return 'rejected_too_small'
  if (fileWidth / targetWidth > 1.5) return 'rejected_too_big'
}
