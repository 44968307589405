import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from '@iconify/react-with-api';
import { IRule, IRulesApi, IRulesCode, IRulesMediaCard } from 'interface';
import { Checkbox, RuleChip } from '.';

type IProps = {
  onClick: (ev: IRule, type: IRulesCode) => void;
  removeClick: (ev: IRule, type: IRulesCode) => void;
  className: string;
  rules: IRulesApi[];
  cardRules?: IRulesMediaCard[];
};
const AddRuleChip: React.FC<IProps> = ({ onClick, removeClick, cardRules, className, rules }: IProps) => {
  const [rulesWeather, setRulesWeather] = React.useState<IRule[]>([]);
  const [rulesDay, setRulesDay] = React.useState<IRule[]>([]);
  const [rulesTime, setRulesTime] = React.useState<IRule[]>([]);
  const [ruleCustom, setRuleCustom] = React.useState<IRule[]>([]);
  useEffect(() => {
    let timeofday: IRule[] = [];
    let custom: IRule[] = [];
    let dayofweek: IRule[] = [];
    let weather: IRule[] = [];
    for (const item of rules) {
      if (item.code === 'weather') {
        weather = [...item.items];
      }
      if (item.code === 'timeofday') {
        timeofday = [...item.items];
      }
      if (item.code === 'custom') {
        custom = [...item.items];
      }
      if (item.code === 'dayofweek') {
        dayofweek = [...item.items];
      }
    }
    setRulesWeather(weather);
    setRulesDay(dayofweek);
    setRulesTime(timeofday);
    setRuleCustom(custom);
  }, []);

  const checkIfRuleIsAdded = (el: IRule) => {
    if (cardRules) {
      let found = cardRules.filter(e => {
        return e.code === el.code;
      });
      return !!found.length;
    }
    return false;
  };

  return (
    <div className="w-80 p-5 text-left dark:bg-dark-200">
      <div className="space-y-5">
        <div className="space-y-3">
          <p className="text-xs font-medium text-body dark:text-dark-300">
            <FormattedMessage id="Text.RulesTimesOfDay" />
          </p>
          <ul className="flex flex-col space-y-4">
            {rulesTime.map((el, index: number) => {
              return (
                <li key={`time_${index}`} className="">
                  <Checkbox
                    name="warn"
                    onChange={e => {
                      if (e.currentTarget.checked) {
                        onClick(el, 'timeofday');
                      } else {
                        removeClick(el, 'timeofday');
                      }
                    }}
                    value={el.code}
                    color="primary"
                    checked={checkIfRuleIsAdded(el)}
                    labelColor="dark"
                    label={el.label}
                  />
                  {/* <RuleChip
                    label={el.label}
                    startIcon="ant-design:clock-circle-outlined"
                    type="timeofday"
                    onClick={(ev: any) => {
                      onClick(el, 'timeofday')
                    }}
                  /> */}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="space-y-3">
          <p className="text-xs font-medium text-body dark:text-dark-300">
            <FormattedMessage id="Text.RulesWeather" />
          </p>

          <ul className="flex flex-col space-y-4">
            {rulesWeather.map((el, index: number) => {
              return (
                <li key={`weather_${index}`} className="">
                  {/* <RuleChip
                    label={el.label}
                    startIcon="fluent:time-and-weather-24-regular"
                    type="weather"
                    onClick={(ev: any) => {
                      onClick(el, 'weather')
                    }}
                  /> */}
                  <Checkbox
                    name="warn"
                    onChange={e => {
                      if (e.currentTarget.checked) {
                        onClick(el, 'weather');
                      } else {
                        removeClick(el, 'weather');
                      }
                    }}
                    value={el.code}
                    checked={checkIfRuleIsAdded(el)}
                    color="primary"
                    labelColor="dark"
                    label={el.label}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <div className="space-y-3 ">
          <p className="text-xs font-medium text-body dark:text-dark-300">
            <FormattedMessage id="Text.RulesWeather" />
          </p>
          <ul className="flex flex-col space-y-4">
            {rulesDay.map((el, index: number) => {
              return (
                <li key={`day_${index}`} className="">
                  {/* <RuleChip
                    label={el.label}
                    startIcon="cil:calendar"
                    type="dayofweek"
                    onClick={(ev: any) => {
                      onClick(el, 'dayofweek')
                    }}
                  /> */}
                  <Checkbox
                    name="warn"
                    onChange={e => {
                      if (e.currentTarget.checked) {
                        onClick(el, 'dayofweek');
                      } else {
                        removeClick(el, 'dayofweek');
                      }
                    }}
                    value={el.code}
                    checked={checkIfRuleIsAdded(el)}
                    color="primary"
                    labelColor="dark"
                    label={el.label}
                  />
                </li>
              );
            })}
          </ul>
          <p className="text-xs font-medium text-body dark:text-dark-300">
            <FormattedMessage id="Text.RulesCustom" />
          </p>
          <ul className="flex flex-col  space-y-4">
            {ruleCustom.map((el, index: number) => {
              return (
                <li key={`custom_${index}`} className="">
                  {/* <RuleChip
                    label={el.label}
                    startIcon="cil:calendar"
                    type="custom"
                    onClick={(ev: any) => {
                      onClick(el, 'custom')
                    }}
                  /> */}
                  <Checkbox
                    name="warn"
                    onChange={e => {
                      if (e.currentTarget.checked) {
                        onClick(el, 'custom');
                      } else {
                        removeClick(el, 'custom');
                      }
                    }}
                    value={el.code}
                    checked={checkIfRuleIsAdded(el)}
                    color="primary"
                    labelColor="dark"
                    label={el.label}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default AddRuleChip;
