import React from 'react';
import { Icon } from '@iconify/react-with-api';
// @ts-ignore
import { Iconly } from 'react-iconly';
import { oneLine } from 'common-tags';
import { IRadioBoxOptions } from 'interface/ISelect';

type Color = 'primary' | 'accent' | 'warn' | 'danger' | 'success' | 'neutral';
type LabelColor = 'white' | 'dark';

const getColor = (color: Color | undefined) => {
  switch (color) {
    case 'primary':
      return 'checked:bg-primary-500 focus:bg-primary-500 text-primary-500 focus:ring-primary-500';
    case 'accent':
      return 'checked:bg-accent-500 focus:bg-accent-500 text-accent-500 focus:ring-accent-500';
    case 'warn':
      return 'checked:bg-warn-500 focus:bg-warn-500 text-warn-500 focus:ring-warn-500';
    case 'danger':
      return 'checked:bg-danger-500 focus:bg-danger-500 text-danger-500 focus:ring-danger-500';
    case 'success':
      return 'checked:bg-success-500 focus:bg-success-500 text-success-500 focus:ring-success-500';
    case 'neutral':
      return 'checked:bg-neutral-500 focus:bg-neutral-500 text-neutral-500 focus:ring-neutral-500';
    default:
      return 'checked:bg-primary-500 focus:bg-primary-500 text-primary-500 focus:ring-primary-500';
  }
};

type ISize = 'small' | 'big';
interface IRadioBoxProps extends React.HTMLProps<HTMLInputElement> {
  innerRef?: any;
  label?: string;
  labelSmall?: string;
  disabled?: boolean;
  name: string;
  boxSize: ISize;
  validation?: any;
  isDark?: boolean;
  type?: string;
  flexClass?: string;
  otherClasses?: string;
  justIcons?: boolean;
  options: IRadioBoxOptions[];
  simpleCheckbox?: boolean;
  source?: string;
}

const RadioBox: React.FC<IRadioBoxProps> = ({
  innerRef,
  disabled,
  validation,
  name,
  boxSize = 'big',
  options,
  justIcons,
  isDark,
  type = 'radio',
  flexClass,
  labelSmall,
  label,
  otherClasses,
  simpleCheckbox,
  source,
  ...otherProps
}: IRadioBoxProps) => {
  const [innerError, setInnerError] = React.useState('');
  const { error } = validation;
  React.useEffect(() => {
    if (error) {
      setInnerError(error.message);
    } else {
      setTimeout(() => {
        setInnerError('');
      }, 300);
    }
  }, [error]);

  const inputErrorClasses = oneLine`
  text-danger-500
`;
  const inputClasses = oneLine`
  flex rounded-xl  overflow-hidden flex-nowrap 
  items-stretch
  ${
    options.length === 1
      ? 'bg-white dark:bg-dark-200 dark:border-4 dark:border-dark-100 shadow-card'
      : 'bg-whiteish dark:bg-dark-default px-2 py-2'
  }
`;

  return (
    <div className="space-y-4 w-full">
      {label && <label className={oneLine`select-box-label`}>{label}</label>}
      {labelSmall && <label className={oneLine`select-box-label absolute`}>{labelSmall}</label>}
      <div
        className={oneLine`radioButtonList ${inputClasses}  ${
          justIcons ? 'just-icons bg-whiteish dark:bg-dark-default md:rounded-xl rounded-3xl' : ''
        }`}
      >
        {options.map((el: IRadioBoxOptions, key: number) => {
          return (
            <div key={`${key}_${el.value}`} className={`${flexClass}`}>
              <input
                ref={innerRef}
                defaultChecked={el.checked}
                type={type}
                name={name}
                value={el.value}
                disabled={el.disabled}
                className="hidden"
                data-cy={source === 'mediaDuration' ? `mediaDuration${key}` : `schedPPH${key}`}
                {...otherProps}
                id={`${key}_${el.value}_${name}`}
              />
              <label
                className={`${boxSize} ${el.disabled ? 'is-disabled' : ''} ${el.icon ? 'flex' : ''} ${
                  otherClasses ? otherClasses : ''
                } dark:text-dark-400`}
                htmlFor={`${key}_${el.value}_${name}`}
              >
                {el.icon && (
                  <span className={'box-content dark:text-dark-400 px-4 py-3 md:py-2'}>
                    <Iconly name={el.icon} size="medium" />
                  </span>
                )}
                {el.label}
              </label>
            </div>
          );
        })}
      </div>
      {error && (
        <div
          className={oneLine`text-danger-500 w-fit text-xs pt-2 space-x-1 collapsible-error ${error ? 'expanded' : ''}`}
        >
          <Icon
            className="inline icon-vertical-fix"
            height="1rem"
            width="1rem"
            icon="ant-design:exclamation-circle-outlined"
          />
          <p className="inline">{innerError}</p>
        </div>
      )}
    </div>
  );
};

RadioBox.defaultProps = {
  disabled: false,
  validation: {},
  readOnly: false,
};
export default RadioBox;
