import React, { useLayoutEffect } from 'react'
let am4core: any = null
let am4charts: any = null
let am4themesAnimated = null
if (process.browser) {
  am4core = require('@amcharts/amcharts4/core')
  am4charts = require('@amcharts/amcharts4/charts')
  am4themesAnimated = require('@amcharts/amcharts4/themes/animated')
  am4core.useTheme(am4themesAnimated.default)
}

const lookUpGrade = (lookupScore: any, grades: any) => {
  // Only change code below this line
  for (let i = 0; i < grades.length; i++) {
    if (grades[i].lowScore < lookupScore && grades[i].highScore >= lookupScore) {
      return grades[i]
    }
  }
  return null
}
let data = {
  score: 52.7,
  gradingData: [
    {
      title: 'Unstable',
      color: '#ee1f25',
      lowScore: 0,
      highScore: 33
    },
    {
      title: 'Stable',
      color: '#f3eb0c',
      lowScore: 33,
      highScore: 66
    },

    {
      title: 'Developing',
      color: '#54b947',
      lowScore: 66,
      highScore: 100
    }
  ]
}
const GaugeChart = () => {
  const id = `am_chart_2_${parseInt(`${Math.random() * 1000}`, 10)}`
  let chartMin = 0
  let chartMax = 100
  useLayoutEffect(() => {
    let chart = am4core.create(id, am4charts.GaugeChart)
    chart.hiddenState.properties.opacity = 0
    chart.fontSize = 11
    chart.innerRadius = am4core.percent(80)
    chart.resizable = true

    chart.hiddenState.properties.opacity = 0
    chart.fontSize = 11
    chart.innerRadius = am4core.percent(80)
    chart.resizable = true

    /**
     * Normal axis
     */

    let axis = chart.xAxes.push(new am4charts.ValueAxis())
    axis.min = chartMin
    axis.max = chartMax
    axis.strictMinMax = true
    axis.renderer.radius = am4core.percent(80)
    axis.renderer.inside = true
    axis.renderer.line.strokeOpacity = 0.1
    axis.renderer.ticks.template.disabled = false
    axis.renderer.ticks.template.strokeOpacity = 1
    axis.renderer.ticks.template.strokeWidth = 0.5
    axis.renderer.ticks.template.length = 5
    axis.renderer.grid.template.disabled = true
    axis.renderer.labels.template.radius = am4core.percent(15)
    axis.renderer.labels.template.fontSize = '0.9em'

    /**
     * Axis for ranges
     */

    let axis2 = chart.xAxes.push(new am4charts.ValueAxis())
    axis2.min = chartMin
    axis2.max = chartMax
    axis2.strictMinMax = true
    axis2.renderer.labels.template.disabled = true
    axis2.renderer.ticks.template.disabled = true
    axis2.renderer.grid.template.disabled = false
    axis2.renderer.grid.template.opacity = 0.5
    axis2.renderer.labels.template.bent = true
    axis2.renderer.labels.template.fill = am4core.color('#000')
    axis2.renderer.labels.template.fontWeight = 'bold'
    axis2.renderer.labels.template.fillOpacity = 0.3

    for (let grading of data.gradingData) {
      let range = axis2.axisRanges.create()
      range.axisFill.fill = am4core.color(grading.color)
      range.axisFill.fillOpacity = 0.8
      range.axisFill.zIndex = -1
      range.value = grading.lowScore > chartMin ? grading.lowScore : chartMin
      range.endValue = grading.highScore < chartMax ? grading.highScore : chartMax
      range.grid.strokeOpacity = 0
      range.stroke = am4core.color(grading.color).lighten(-0.1)
      range.label.inside = true
      range.label.text = grading.title.toUpperCase()
      range.label.inside = true
      range.label.location = 0.5
      range.label.inside = true
      range.label.radius = am4core.percent(10)
      range.label.paddingBottom = -5 // ~half font size
      range.label.fontSize = '0.9em'
    }

    let matchingGrade = lookUpGrade(data.score, data.gradingData)
    let hand = chart.hands.push(new am4charts.ClockHand())
    hand.axis = axis2
    hand.innerRadius = am4core.percent(55)
    hand.startWidth = 8
    hand.pin.disabled = true
    hand.value = data.score
    hand.fill = am4core.color('#444')
    hand.stroke = am4core.color('#000')

    return () => {
      if (chart) {
        chart.dispose()
      }
    }
  }, [data])

  return <div id={id} style={{ width: '100%', height: '100px' }}></div>
}

export default GaugeChart
