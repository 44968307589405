import JWT from '@api/users/jwt';
import Dinero from 'dinero.js';

export const gtmSelectItem = async (location: any, index: number, category: string | undefined) => {
  const dataLayer = window.dataLayer || [];
  const product_object = {
    item_name: location.contentType, //noArray
    item_id: location.name,
    item_brand: location.address,
    coupon: '',
    currency: '',
    discount: '',
    index: index + 1,
    item_category: location.network,
    item_category2: '',
    item_category3: '',
    item_category4: '',
    item_category5: '',
    price: '',
    item_list_name: category,
    item_variant: location.resolution,
    quantity: 1,
  };
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'select_item',
    ecommerce: {
      items: [
        {
          ...product_object,
        },
      ],
    },
    userId: JWT.getJwtUser().id,
  });
};

export const gtmViewDetailsItem = (location: any) => {
  const dataLayer = window.dataLayer || [];
  const product_object = {
    item_name: location.contentType[0],
    item_id: location.n,
    item_brand: location.address + '_' + location.country,
    coupon: '',
    currency: '',
    discount: '',
    index: '',
    item_category: location.acceptedSchedules,
    item_category2: location.type,
    item_category3: location.acceptedDurations.join(',').toString(),
    item_category4: location.circuit,
    item_category5: location.minBookingHours,
    price: parseFloat(location.avg.substring(1).trim().replace(',', '')),
    item_list_name: '',
    item_variant: location.horRez + 'X' + location.vertRez,
    quantity: 1,
  };
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          ...product_object,
        },
      ],
    },
    userId: JWT.getJwtUser().id,
  });
};

export const gtmAddToCart = (location: any) => {
  const dataLayer = window.dataLayer || [];
  const product_object = {
    item_name: location.contentType[0],
    item_id: location.n,
    item_brand: location.address + '_' + location.country,
    coupon: '',
    currency: '',
    discount: '',
    index: '',
    item_category: location.acceptedSchedules,
    item_category2: location.type,
    item_category3: location.acceptedDurations.join(',').toString(),
    item_category4: location.circuit,
    item_category5: location.minBookingHours,
    price: location.avg,
    item_list_name: '',
    item_variant: location.horRez + 'X' + location.vertRez,
    quantity: 1,
  };
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          ...product_object,
        },
      ],
    },
    userId: JWT.getJwtUser().id,
  });
};

export const gtmRemoveFromCart = (location: any) => {
  const dataLayer = window.dataLayer || [];
  const product_object = {
    item_name: location.contentType[0],
    item_id: location.n,
    item_brand: location.address + '_' + location.country,
    coupon: '',
    currency: '',
    discount: '',
    index: '',
    item_category: location.acceptedSchedules,
    item_category2: location.type,
    item_category3: location.acceptedDurations.join(',').toString(),
    item_category4: location.circuit,
    item_category5: location.minBookingHours,
    price: location.avg,
    item_list_name: '',
    item_variant: location.horRez + 'X' + location.vertRez,
    quantity: 1,
  };
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      items: [
        {
          ...product_object,
        },
      ],
    },
    userId: JWT.getJwtUser().id,
  });
};

export const gtmCheckout1 = () => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'checkout1',
    userId: JWT.getJwtUser().id,
  });
};

export const gtmCheckout2 = () => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'checkout2',
    userId: JWT.getJwtUser().id,
  });
};

export const gtmCheckout3 = () => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'checkout3',
    userId: JWT.getJwtUser().id,
  });
};

export const gtmCheckout4 = () => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'checkout4',
    userId: JWT.getJwtUser().id,
  });
};

export const gtmCheckout5 = () => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'checkout5',
    userId: JWT.getJwtUser().id,
  });
};

export const gtmViewItemList = (slider: any[], listName: string) => {
  const items: any[] = [];
  slider.forEach((item, index) => {
    const product_object = {
      item_name: item.contentType, //noArray
      item_id: item.name,
      item_brand: item.address,
      coupon: '',
      currency: '',
      discount: '',
      index: index + 1,
      item_category: item.network,
      item_category2: '',
      item_category3: '',
      item_category4: item.circuit,
      item_category5: '',
      price: '',
      item_list_name: listName,
      item_variant: item.resolution,
      quantity: 1,
    };
    items.push(product_object);
  });
  pushDataLayer(items);
};

const pushDataLayer = (items: any[]) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      items: items,
    },
    userId: JWT.getJwtUser().id,
  });
};

export const gtmBeginCheckout = (state: any, priceInfo: any, currency: string) => {
  const items: any[] = [];
  state.locations.forEach((item: any, index: number) => {
    let foundPriceInfo = priceInfo?.pricePerScreen?.find((info: any) => info.uuid === item.location.uuid);
    const product_object = {
      item_name: item.location.contentType[0],
      item_id: item.location.n,
      item_brand: item.location.address + '_' + item.location.country,
      coupon: '',
      currency: '',
      discount: '',
      index: index + 1,
      item_category: item.location.acceptedSchedules,
      item_category2: item.location.type,
      item_category3: item.location.acceptedDurations.join(',').toString(),
      item_category4: item.location.circuit,
      item_category5: item.location.minBookingHours,
      price: parseFloat(foundPriceInfo?.price?.substring(1).trim().replace(',', '') || '0'),
      item_list_name: '',
      item_variant: item.location.horRez + 'X' + item.location.vertRez,
      quantity: 1,
    };
    items.push(product_object);
  });
  const price = state.price;
  const cleanedString = price.substring(1).trim();
  const numberValue = parseFloat(cleanedString.replace(',', ''));
  pushDataLayerBeginCheckout(items, numberValue, currency);
};

export const gtmAddShippingInfo = (state: any, currency: string) => {
  const items: any[] = [];
  state.locations.forEach((item: any, index: number) => {
    let foundSchedule = state.schedule.find((schedule: any) => schedule.locations[0]?.uuid === item.location.uuid);
    const product_object = {
      item_name: item.location.contentType[0],
      item_id: item.location.n,
      item_brand: item.location.address + '_' + item.location.country,
      coupon: '',
      currency: '',
      discount: '',
      index: index + 1,
      item_category: item.location.acceptedSchedules,
      item_category2: item.location.type,
      item_category3: item.location.acceptedDurations.join(',').toString(),
      item_category4: item.location.circuit,
      item_category5: item.location.minBookingHours,
      price: item.location.avg,
      item_list_name: '',
      item_variant: item.location.horRez + 'X' + item.location.vertRez,
      quantity: 1,
    };
    items.push(product_object);
  });
  const price = state.price;
  const cleanedString = price.substring(1).trim();
  const numberValue = parseFloat(cleanedString.replace(',', ''));
  pushDataLayerAddShippingInfo(items, numberValue, currency);
};

export const gtmAddPaymentInfo = (locations: any[] | undefined, priceInfo: any, currency: string) => {
  const items: any[] = [];
  locations?.forEach((item: any, index: number) => {
    let foundPriceInfo = priceInfo?.pricePerScreen.find((info: any) => info.uuid === item.location.uuid);
    const product_object = {
      item_name: item.location.contentType[0],
      item_id: item.location.n,
      item_brand: item.location.address + '_' + item.location.country,
      coupon: '',
      currency: currency,
      discount: '',
      index: index + 1,
      item_category: item.location.acceptedSchedules,
      item_category2: item.location.type,
      item_category3: item.location.acceptedDurations.join(',').toString(),
      item_category4: item.location.circuit,
      item_category5: item.location.minBookingHours,
      price: parseFloat(foundPriceInfo.price.substring(1).trim().replace(',', '')),
      item_list_name: '',
      item_variant: item.location.horRez + 'X' + item.location.vertRez,
      quantity: 1,
    };
    items.push(product_object);
  });

  const subTotal = parseFloat((Dinero({
    amount: priceInfo.subTotalValue,
    precision: 4,
  }).toUnit()).toFixed(2));

  pushDataLayerAddPaymentInfo(items, subTotal, currency);
};

export const gtmPurchase = (
  state: any | undefined,
  priceInfo: any,
  currency: any,
  totalBudget: any,
  tax: any,
  percentDiscount: any,
  discountValue: any
) => {
  const items: any[] = [];
  state.locations?.forEach((item: any, index: number) => {
    let foundPriceInfo = priceInfo.find((info: any) => info.uuid === item.location.uuid);
    const product_object = {
      item_name: item.location.contentType[0],
      item_id: item.location.n,
      item_brand: item.location.address + '_' + item.location.country,
      coupon: '',
      currency: currency,
      discount: '',
      index: index + 1,
      item_category: item.location.acceptedSchedules,
      item_category2: item.location.type,
      item_category3: item.location.acceptedDurations.join(',').toString(),
      item_category4: item.location.circuit,
      item_category5: item.location.minBookingHours,
      price: parseFloat(foundPriceInfo.price.substring(1).trim().replace(',', '')),
      item_list_name: '',
      item_variant: item.location.horRez + 'X' + item.location.vertRez,
      quantity: 1,
    };
    items.push(product_object);
  });
  pushDataLayerPurchase(
    state.name,
    state.uuid,
    items,
    currency,
    state.coupon,
    totalBudget,
    tax,
    state.usedCredits,
    percentDiscount,
    discountValue
  );
};

const pushDataLayerPurchase = (
  campaignName: string,
  id: string,
  items: any[],
  currency: any,
  coupon: string,
  totalBudget: any,
  tax: any,
  usedCredits: any,
  percentDiscount: any,
  discountValue: any
) => {
  const dataLayer = window.dataLayer || [];
  const totalBudgetFormated = Dinero({
    amount: totalBudget
      ? Math.ceil(totalBudget - usedCredits - (percentDiscount / 100) * (totalBudget - usedCredits)) < 0
        ? 0
        : Math.ceil(totalBudget - usedCredits - (percentDiscount / 100) * (totalBudget - usedCredits))
      : 0,
    precision: 4,
  }).toUnit();
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'purchase',
    ecommerce: {
      campaignName: campaignName ? campaignName : 'Unavailable',
      transaction_id: id,
      affiliation: '',
      tax: parseFloat(tax.substring(1).trim().replace(',', '')),
      shipping: '',
      coupon: coupon ? coupon : '',
      discount: percentDiscount,
      discountValue: parseFloat((Dinero({
        amount: Math.ceil(discountValue),
        precision: 4,
      }).toUnit()).toFixed(2)),
      usedCredits: parseFloat((Dinero({
        amount: usedCredits,
        precision: 4,
      }).toUnit()).toFixed(2)),
      currency: currency,
      subTotalValue: parseFloat(Dinero({
        amount: totalBudget,
        precision: 4,
      }).toUnit().toFixed(2)),
      totalValue: parseFloat(totalBudgetFormated.toFixed(2)),
      items: items,
    },
    userId: JWT.getJwtUser().id,
  });
};

const pushDataLayerAddPaymentInfo = (items: any[], price: number, currency: string) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'add_payment_info',
    ecommerce: {
      currency: currency,
      value: price,
      items: items,
    },
    userId: JWT.getJwtUser().id,
  });
};

const pushDataLayerBeginCheckout = (items: any[], price: number, currency: string) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      currency: currency,
      value: price,
      items: items,
    },
    userId: JWT.getJwtUser().id,
  });
};

const pushDataLayerAddShippingInfo = (items: any[], price: number, currency: string) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'add_shipping_info',
    ecommerce: {
      currency: currency,
      value: price,
      items: items,
    },
    userId: JWT.getJwtUser().id,
  });
};

export const gtmRegisterUser = () => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ event: 'create_account' });
};

export const gtmGoogleCreateAccount = () => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ event: 'google_create_account' });
};

export const viewSurveyForm = () => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ event: 'survey_form' });
};

export const viewOnboardingTour = (step: any) => {
  const dataLayer = window.dataLayer || [];
    dataLayer.push({ 
      event: 'view_onboarding_tour', 
      step: String(step), 
      userId: JWT.getJwtUser().id, 
    });
}

export const skipOnboardingTour = (step: any) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ 
    event: 'skip_onboarding_tour', 
    step: String(step), 
    userId: JWT.getJwtUser().id, 
  });
}

export const completedSurveyForm = (param: string) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'survey_formcompleted',
    answer: param,
 });
};